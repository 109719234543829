import { ImageDialogActionCallback, ImageDialogInputData } from "plato-map-lib";
import { FramePhotosService } from "./frame-photos.service";
import { Injectable } from "@angular/core";
import { MessageBoxService } from "./message-box.service";
import { Photo } from "../models/domain/Frame";

@Injectable({
  providedIn: "root",
})
export class ImagePreviewService {
  constructor(private framePhotosService: FramePhotosService, private messageBoxService: MessageBoxService) {}

  getImageDialogInputData(frameDetails, onPhotosChange): ImageDialogInputData {
    const getBasicFormData = () => {
      const formData = new FormData();
      formData.append("mediaOwnerName", frameDetails.mediaOwnerName);
      formData.append("frameId", frameDetails.frameId);
      return formData;
    };
    return {
      frameDetails: { properties: frameDetails },
      showFrameControls: true,
      showMediaOwner: true,
      actionCallback: {
        setAsCoverPhoto: (imageName: string) => {
          const formData = getBasicFormData();
          formData.append("imageName", imageName);
          this.framePhotosService.markPhotoAsPrimary(formData).subscribe(
            (success) => onPhotosChange(formData),
            (error) => console.error("failed to update photos ", error)
          );
        },
        deletePhoto: (imageName: string) => {
          const formData = getBasicFormData();
          formData.append("imageName", imageName);
          this.framePhotosService.deletePhotos(formData).subscribe(
            (photo) => onPhotosChange(formData),
            (error) => console.error("failed to mark image as delete", error)
          );
        },
        uploadPhoto: (files: FileList) => {
          const formData = getBasicFormData();
          new Array(files.length).fill(0).map((_, index) => formData.append("files", files.item(index)));
          this.framePhotosService.uploadPhotos(formData).subscribe(
            (fileUploadData) => onPhotosChange(formData),
            (error) => console.error("failed to upload photos ", error)
          );
        },
        updateUnitRationale: (unitRationale: string) => {
          const formData = getBasicFormData();
          this.framePhotosService
            .updateUnitRationale(frameDetails.frameId, frameDetails.sourceSystem, unitRationale)
            .subscribe(
              () => {
                formData.append("unitRationale", unitRationale);
                onPhotosChange(formData);
              },
              (error) => {
                this.messageBoxService.showError(
                  "Unit Rationale Update Error",
                  "Unable to update unit rationale. Please try again later."
                );
                console.error("failed to update unit rationale ", error);
              }
            );
        },
      } as ImageDialogActionCallback,
    } as ImageDialogInputData;
  }

  castToPhotos(photos: any[]): Photo[] {
    return photos.map((photo) => {
      return {
        photoUrl: photo.photoUrl,
        isPrimaryPhoto: photo.primary,
        source: photo.source,
      } as Photo;
    });
  }
}
