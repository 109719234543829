import { GeoBoundaries } from "../model/GeoBoundaries";
import { RadiusConfig } from "../model/RadiusConfig";
import { Polygon, MultiPolygon, Feature, FeatureCollection, Units } from "@turf/helpers";
import * as turf from "@turf/turf";
import { Injectable } from "@angular/core";
import { POIGeoBoundaryInfo } from "../model/POIGeoBoundaryInfo";
import { ProhibitionPOIData } from "../model/ProhibitionPOIData";
import { ProhibitionPOIIdentity } from "../model/ProhibitionPOIIdentity";

@Injectable({
  providedIn: "root",
})
export class BoundaryTransformer {
  public transform(
    poiGeoboundaries: POIGeoBoundaryInfo[],
    poiDetails: ProhibitionPOIData[],
    radius: RadiusConfig
  ): GeoBoundaries {
    const poiBoundaries = poiGeoboundaries.map((poi) => poi.geoBoundaryInfo);
    return {
      boundaries: this.toFeatureCollection(poiGeoboundaries, poiDetails),
      level1Boundaries: this.toLevel1Boundaries(poiBoundaries, radius),
      level2Boundaries: this.toLevel2Boundaries(poiBoundaries, radius),
    } as GeoBoundaries;
  }

  private toFeatureCollection(
    poiGeoBoundaries: POIGeoBoundaryInfo[],
    poiDetails: ProhibitionPOIData[]
  ): FeatureCollection {
    const features = poiGeoBoundaries.map((poiGeoBoundary) =>
      turf.feature(poiGeoBoundary.geoBoundaryInfo, {
        desc: this.getPOIProperties(poiGeoBoundary.poiIdentity, poiDetails),
        poiIdentity: poiGeoBoundary.poiIdentity,
      })
    );
    return turf.featureCollection(features);
  }

  private getPOIProperties(poiId: ProhibitionPOIIdentity, poiDetails: ProhibitionPOIData[]): string {
    return poiDetails.find((poi) => {
      return poi.identity.referenceNumber === poiId.referenceNumber && poi.identity.sourceSystem === poiId.sourceSystem;
    }).name;
  }

  private toLevel1Boundaries(boundaries, radius: RadiusConfig): Feature {
    return this.expandAndMerge(boundaries, radius.level1Radius, radius.unit);
  }

  private toLevel2Boundaries(boundaries, radius: RadiusConfig): Feature {
    return this.expandAndMerge(boundaries, radius.level2Radius, radius.unit);
  }

  private expandAndMerge(boundaries: any, radius: number, unit: Units): Feature {
    const expandedBoundaries = boundaries.map((boundary) => this.bufferWrapper(boundary, radius, unit));
    const merged = turf.union(...expandedBoundaries);
    return merged;
  }

  private bufferWrapper(boundary, radius: number, unit: Units): Feature<Polygon | MultiPolygon> {
    return turf.buffer(boundary, radius, { units: unit });
  }
}
