import { ProhibitionRequest } from "./prohibition/Prohibition";
import { GeoLocationSearchCriterion } from "./GeoLocationSearchCriterion";
import { FrameFormatSearchCriterion } from "./FrameFormatSearchCriterion";
import { MediaOwnerSearchCriterion } from "./MediaOwnerSearchCriterion";
import { POISearchCriterion } from "./poi/POISearchCriterion";
import { AvailabilitySearchCriterion } from "./AvailabilitySearchCriterion";
import { CustomPOIInclusionExclusionCriteria } from "./poi/custompoi/CustomPOIInclusionExclusionCriteria";
import { DayPartInput } from "./DigitalAvailabilityCriterionInput";

export class Predicate {
  environmentNames: string[];
  environment: string[];
  audience: string;
  geoLocationCriterion: GeoLocationSearchCriterion;
  frameId: string[];
  spotId?: string[];
  sourceSystemFrameIds?: string[];
  mediaOwnerReferenceId: string[];
  sourceSystems?: string[];
  frameFormatCriterion: FrameFormatSearchCriterion;
  mediaOwnerCriterion: MediaOwnerSearchCriterion;
  poiSearchCriterionV2: POISearchCriterion;
  customPOISearchCriteria: CustomPOIInclusionExclusionCriteria;
  availabilitySearchCriterion: AvailabilitySearchCriterion;
  countOption: string;
  excludeHFSS: boolean;
  excludeBlacklistedFrames: boolean;
  directions?: string[];
  prohibition: ProhibitionRequest;
  analyticalSystem: string;
  excludeFramesNearSchoolsDistance: number;
  dayPart?: DayPartInput[];
  frameStatus?: string[];
}
