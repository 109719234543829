import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { ResourceService } from "./resource-service.service";
import { Campaign, CampaignHeaders } from "../../models/domain/Campaign";
import { CampaignSearchPredicate, toQueryParams } from "../../models/domain/CampaignSearchPredicate";
import { Observable, Subject, of, throwError } from "rxjs";
import { EditPrice } from "../../models/domain/EditPricePresenter";
import {
  CampaignFrameSchedule,
  NonMediaLineCost,
  NonMediaLineCostDeltaPresenter,
} from "../../models/domain/CampaignFrameSchedule";
import { catchError, map, switchMap } from "rxjs/operators";
import { AddPackToCampaignDTO } from "../../models/domain/AddPackToCampaignDTO";
import { EditFrameQuantity } from "../../models/domain/EditFramesQuantity";
import { AddContainerToCampaignDTO } from "../../models/domain/AddContainerToCampaignDTO";
import { EditContainer } from "../../models/domain/EditContainer";
import { Note } from "../../models/domain/Note";
import { Tag } from "../../models/domain/Tag";
import { CampaignNonMediaCost, CampaignNonMediaLineCostDeltaPresenter } from "../../models/domain/CampaignNonMediaCost";
import { ProhibitionDetails } from "../../models/domain/prohibition/ProhibitionDetails";
import { CampaignScheduleAvailabilityResponse } from "../../models/response/CampaignScheduleAvailabilityResponse";
import { LineLvlCostEditPrice } from "../../models/domain/LineLvlEditPricePresenter";
import { GeographyAuditData } from "../../models/domain/GeographyAuditData";
import { UpdatedReach } from "../../../home/campaigns/campaign-grid-v3/actions/reach/reach.component";
import { CampaignLinePresenter, TradingStatus } from "../../models/domain/CampaignLinePresenter";
import { EditPriceForLine } from "../../models/domain/price";
import { EditPercentage } from "../../models/domain/EditPercentagePresenter";
import { UpdateMultilineOpsRequest } from "src/app/home/campaigns/campaign-grid-v3/multiline-operation/multiline-operation";
import { HttpStatusCode } from "@angular/common/http";

export const CAMPAIGNS_BASE_URL = "/inventory/campaigns";
@Injectable({
  providedIn: "root",
})
export class CampaignsService extends ResourceService<Campaign> {
  campaignStore = new Subject<Campaign>();
  tags: Tag[];

  constructor(apiCall: ApiService) {
    super(apiCall, CAMPAIGNS_BASE_URL);
  }

  searchCampaigns(predicate: CampaignSearchPredicate): Observable<Array<Campaign>> {
    return this.apiCall
      .get(this.endpoint + toQueryParams(predicate))
      .pipe(map((data: Campaign[]) => data.filter((campaign) => campaign.campaignStatus !== "DELETED")));
  }

  saveAsDraft(body: object) {
    return this.apiCall.post(CAMPAIGNS_BASE_URL + "/draft", body, {
      "Content-type": "application/json",
    });
  }

  getCampaignByIdSync(id: string): Promise<Campaign> {
    if (!id) {
      return Promise.resolve(null);
    }
    return this.apiCall.get(`${CAMPAIGNS_BASE_URL}/${id}`).toPromise();
  }

  getNextTransitions(id: string) {
    return this.apiCall.get(`${CAMPAIGNS_BASE_URL}/${id}/nextStates`);
  }

  isSimilarScheduleInPostPlannedState(body: object, campaignId: string) {
    return this.apiCall.post(`${CAMPAIGNS_BASE_URL}/${campaignId}/isSimilarScheduleInPostPlannedState`, body, {
      "Content-type": "application/json",
    });
  }

  addFramesToCampaign(body: object, camapaignId: string) {
    return this.apiCall.post(`${CAMPAIGNS_BASE_URL}/${camapaignId}/addFrames`, body, {
      "Content-type": "application/json",
    });
  }

  addPacksToCampaign(campaignId: number, addPackagesCriteria: AddPackToCampaignDTO[]): Observable<Campaign> {
    return this.apiCall.post(this.endpoint + "/" + campaignId.toString() + "/addProducts", addPackagesCriteria);
  }

  addContainerToCampaign(campaignId: number, addContainerCriteria: AddContainerToCampaignDTO): Observable<Campaign> {
    return this.apiCall.post(this.endpoint + "/" + campaignId.toString() + "/addContainer", addContainerCriteria);
  }

  editFramesQuantity(campaignId: number, editQuantity: EditFrameQuantity): Observable<CampaignFrameSchedule> {
    return this.apiCall.makeRequest(
      "PATCH",
      this.endpoint + "/" + campaignId.toString() + "/editProductFramesQuantity",
      editQuantity
    );
  }

  editContainerValue(
    campaignId: number,
    editContainerValue: EditContainer[]
  ): Observable<Array<CampaignFrameSchedule>> {
    return this.apiCall.makeRequest(
      "POST",
      this.endpoint + "/" + campaignId.toString() + "/editContainer",
      editContainerValue
    );
  }

  edit(body: object) {
    return this.apiCall.put(this.endpoint, body);
  }

  getSchedulesPlanner(campaignId: number) {
    return this.apiCall.get(`${this.endpoint}/${campaignId}/scheduleSummary`);
  }

  getHFSSExclusionCriterion(campaignId: string) {
    return this.apiCall.get(this.endpoint + "/" + campaignId + "/hfss");
  }

  getProhibitionDetails(campaignId: string): Observable<ProhibitionDetails> {
    return this.apiCall.get(this.endpoint + "/" + campaignId + "/prohibition");
  }

  deleteFrameWithSchedules(campaignId: number, scheduleIds: Array<string>) {
    return this.apiCall.makeRequest("DELETE", `${CAMPAIGNS_BASE_URL}/${campaignId}/deleteFrames`, scheduleIds);
  }

  deleteFrameWithLines(campaignId: number, campaignLineIds: Array<string>) {
    return this.apiCall.makeRequest("DELETE", `${CAMPAIGNS_BASE_URL}/${campaignId}/deleteLines`, campaignLineIds);
  }

  fetchCampaignFrameSchedules(campaignId: number, scheduleIds: Array<string>) {
    return this.apiCall.makeRequest("POST", `${CAMPAIGNS_BASE_URL}/${campaignId}/fetchSchedules`, scheduleIds);
  }

  getInventoryKPI(camapaignId: string) {
    return this.apiCall.get(`${CAMPAIGNS_BASE_URL}/${camapaignId}/inventoryKPI`);
  }

  getInventoryKPIStatus(camapaignId: string) {
    return this.apiCall.get(`${CAMPAIGNS_BASE_URL}/${camapaignId}/inventoryKPI/status`);
  }

  requestAvailabilityUpdate(campaignId: number) {
    return this.apiCall.makeRequest("POST", `${CAMPAIGNS_BASE_URL}/${campaignId}/availability/requests`, undefined);
  }

  fetchAvailability(campaignId: number) {
    return this.apiCall.makeRequest<CampaignScheduleAvailabilityResponse[]>(
      "GET",
      `${CAMPAIGNS_BASE_URL}/${campaignId}/availability`
    );
  }

  editPriceForCampaignLine(
    lineId: string,
    type: string,
    newPrice: EditPriceForLine
  ): Observable<CampaignFrameSchedule[]> {
    return this.apiCall.makeRequest("PATCH", `${CAMPAIGNS_BASE_URL}/line/${lineId}/${type}`, newPrice);
  }

  editPricingForFrameSchedules(
    campaignId: string,
    type: string,
    newPrices: EditPrice[]
  ): Observable<CampaignFrameSchedule[]> {
    return this.apiCall.makeRequest("PATCH", `${CAMPAIGNS_BASE_URL}/${campaignId}/${type}`, newPrices);
  }

  editDiscountPercentage(campaignId: string, newPrices: EditPercentage[]): Observable<CampaignFrameSchedule[]> {
    return this.apiCall.makeRequest("PATCH", `${CAMPAIGNS_BASE_URL}/${campaignId}/discountPercentage`, newPrices);
  }

  editLineLevelNonMediaPricingForFrameSchedules(
    campaignId: string,
    type: string,
    newLineLvlPrices: LineLvlCostEditPrice[]
  ): Observable<CampaignFrameSchedule[]> {
    return this.apiCall.makeRequest(
      "PATCH",
      `${CAMPAIGNS_BASE_URL}/${campaignId}/lineLevelNonMediaCosts/${type}`,
      newLineLvlPrices
    );
  }

  exportCampaignPlan(campaignId: string): Promise<any> {
    return this.export(`${CAMPAIGNS_BASE_URL}/${campaignId}/exportPlan`).toPromise();
  }

  exportCampaignInventory(campaignId: string, region: string): Promise<any> {
    return this.export(`${CAMPAIGNS_BASE_URL}/${campaignId}/${region}/exportInventory`).toPromise();
  }

  exportCampaignBookingsForPBU(campaignId: string): Promise<any> {
    return this.export(`${CAMPAIGNS_BASE_URL}/${campaignId}/exportBookings`).toPromise();
  }

  exportPopTrackerPlans(campaignId: string): Promise<any> {
    return this.export(`${CAMPAIGNS_BASE_URL}/${campaignId}/pop-tracker-export`).toPromise();
  }

  exportPrismaPlans(campaignId: string): Promise<any> {
    return this.export(`${CAMPAIGNS_BASE_URL}/${campaignId}/prisma-export`).toPromise();
  }

  addNote(campaignId: number, lineId: string, note: Note) {
    return this.apiCall.makeRequest("POST", `${CAMPAIGNS_BASE_URL}/${campaignId}/line/${lineId}/note`, note);
  }

  requestUpdatedTradingMode(campaignId: number) {
    return this.apiCall.makeRequest("POST", `${CAMPAIGNS_BASE_URL}/${campaignId}/tradingMode`, null);
  }

  requestCampaignKPIVersions() {
    return this.apiCall.makeRequest("GET", "/inventory/kpi/versions", null);
  }

  private export(endpoint: string) {
    return this.apiCall.makeRequestWithOptions("GET", endpoint, null, {
      observe: "body",
      responseType: "blob",
      headers: {
        "Content-type": "application/json",
      },
    });
  }

  amendFFCManual(campaignLinePresenter: CampaignLinePresenter) {
    const campaignId = campaignLinePresenter.campaignId.toString();
    const campaignLineId = campaignLinePresenter.campaignLineId;
    const endpoint = `${this.endpoint}/${campaignId}/line/${campaignLineId}/amendFFCManual`;
    return this.apiCall.post(endpoint, campaignLinePresenter);
  }

  upsertLineLevelNonMediaCostDelta(
    campaignId: number,
    lineId: string,
    nonMediaLineCostDelta: NonMediaLineCostDeltaPresenter
  ): Observable<CampaignLinePresenter> {
    const endpoint =
      this.endpoint + "/" + campaignId.toString() + "/lines/" + lineId + "/lineLevelNonMediaCostsForLine";
    return this.apiCall.post(endpoint, [nonMediaLineCostDelta]);
  }

  upsertLineLevelNonMediaCost(
    campaignId: number,
    lineId: string,
    nonMediaLineCostDelta: NonMediaLineCostDeltaPresenter[]
  ): Observable<CampaignLinePresenter> {
    const endpoint =
      this.endpoint + "/" + campaignId.toString() + "/lines/" + lineId + "/lineLevelNonMediaCostsForLine";
    return this.apiCall.post(endpoint, nonMediaLineCostDelta);
  }

  deleteLineLevelNonMediaCost(campaignId: number, lineId: string, nonMediaLineCost: NonMediaLineCost) {
    const endpoint =
      this.endpoint + "/" + campaignId.toString() + "/lines/" + lineId + "/lineLevelNonMediaCostsForLine";

    return this.apiCall.makeRequest("DELETE", endpoint, nonMediaLineCost);
  }

  amendLXLPANEL(campaignLinePresenter: CampaignLinePresenter) {
    const endpoint =
      this.endpoint +
      "/" +
      campaignLinePresenter.campaignId.toString() +
      "/line/" +
      campaignLinePresenter.campaignLineId +
      "/amendLXLPANEL";
    return this.apiCall.post(endpoint, campaignLinePresenter);
  }

  amendLXLCPT(campaignLinePresenter: CampaignLinePresenter) {
    const endpoint =
      this.endpoint +
      "/" +
      campaignLinePresenter.campaignId.toString() +
      "/line/" +
      campaignLinePresenter.campaignLineId +
      "/amendLXLCPT";
    return this.apiCall.post(endpoint, campaignLinePresenter);
  }

  updateLXLPANEL(campaignLinePresenter: CampaignLinePresenter) {
    const endpoint =
      this.endpoint +
      "/" +
      campaignLinePresenter.campaignId.toString() +
      "/line/" +
      campaignLinePresenter.campaignLineId +
      "/updateLXLPANEL";
    return this.apiCall.post(endpoint, campaignLinePresenter);
  }

  updateLXLCPT(campaignLinePresenter: CampaignLinePresenter) {
    const endpoint =
      this.endpoint +
      "/" +
      campaignLinePresenter.campaignId.toString() +
      "/line/" +
      campaignLinePresenter.campaignLineId +
      "/updateLXLCPT";
    return this.apiCall.post(endpoint, campaignLinePresenter);
  }

  getTags(): Observable<Tag[]> {
    if (this.tags) {
      return of(this.tags);
    }
    return this.apiCall.get(`/inventory/tags`)?.pipe(
      switchMap((data) => {
        this.tags = data;
        return of(this.tags);
      })
    );
  }

  updateTags(campaignId: number, lineId: string, tags: number[]): Observable<Tag[]> {
    return this.apiCall.makeRequest("POST", `${CAMPAIGNS_BASE_URL}/${campaignId}/line/${lineId}/tags`, tags);
  }

  updateGeoAuditData(campaignId: number, lineId: string, geoAuditData: GeographyAuditData): Observable<Tag[]> {
    return this.apiCall.makeRequest(
      "POST",
      `${CAMPAIGNS_BASE_URL}/${campaignId}/line/${lineId}/audit-geography`,
      geoAuditData
    );
  }

  getReach(country: string = ""): Observable<string[]> {
    const geographicalReachUrl = country ? `/inventory/reach?country=${country}` : `/inventory/reach`;
    return this.apiCall.get(geographicalReachUrl);
  }

  updateReach(campaignId: number, updatedReach: UpdatedReach): Observable<string[]> {
    return this.apiCall.makeRequest("PUT", `${CAMPAIGNS_BASE_URL}/${campaignId}/line/${updatedReach.lineId}/reach`, {
      reach: updatedReach.reach,
    });
  }

  updateBuyType(campaignId: number, lineId: string, buyType: string): Observable<string[]> {
    return this.apiCall.makeRequest("PUT", `${CAMPAIGNS_BASE_URL}/${campaignId}/line/${lineId}/buyType`, { buyType });
  }

  updateConditions(campaignId: number, lineId: string, condition: string) {
    return this.apiCall.makeRequest("PUT", `${CAMPAIGNS_BASE_URL}/${campaignId}/line/${lineId}/condition`, {
      condition,
    });
  }

  updateBuyCurrency(campaignLineId: string, buyCurrency: string) {
    return this.apiCall.makeRequest("PATCH", `${CAMPAIGNS_BASE_URL}/line/${campaignLineId}/buyCurrency`, {
      buyCurrency: buyCurrency,
    });
  }

  updateExchangeMultiplier(campaignLineId: number, multiplier: number) {
    return this.apiCall.makeRequest("PATCH", `${CAMPAIGNS_BASE_URL}/line/${campaignLineId}/exchangeMultiplier`, {
      exchangeMultiplier: multiplier,
    });
  }

  updateBuyPrice(campaignLineId: number, buyPrice: number) {
    return this.apiCall.makeRequest("PATCH", `${CAMPAIGNS_BASE_URL}/line/${campaignLineId}/buyPrice`, {
      updatedPrice: buyPrice,
    });
  }

  updateSellPrice(campaignLineId: number, sellPrice: number) {
    return this.apiCall.makeRequest("PATCH", `${CAMPAIGNS_BASE_URL}/line/${campaignLineId}/sellPrice`, {
      updatedPrice: sellPrice,
    });
  }

  updateCampaignLineSAC(campaignLineId: number, sacValue: number) {
    return this.apiCall.makeRequest("PATCH", `${CAMPAIGNS_BASE_URL}/line/${campaignLineId}/sac`, { sac: sacValue });
  }

  updateCampaignLineBuyAgencyCommission(campaignLineId: number, agencyCommission: number) {
    return this.apiCall.makeRequest("PATCH", `${CAMPAIGNS_BASE_URL}/line/${campaignLineId}/buyAgencyCommission`, {
      commission: agencyCommission,
    });
  }

  fetchAllCosts(campaignId: string, region: string, campaignType: string): Observable<any> {
    return this.apiCall.get(
      `${CAMPAIGNS_BASE_URL}/${campaignId}/nonMediaCostHeaders?region=${region}&campaignType=${campaignType}`
    );
  }

  submitCost_old(campaignId: number, campaignNonMediaCost: CampaignNonMediaCost): Observable<any> {
    return this.apiCall.post(this.endpoint + "/" + campaignId.toString() + "/nonmediacosts", campaignNonMediaCost);
  }

  submitCost(
    campaignId: number,
    campaignNonMediaLineCostDeltaPresenter: CampaignNonMediaLineCostDeltaPresenter[]
  ): Observable<any> {
    return this.apiCall
      .post(this.endpoint + "/" + campaignId.toString() + "/nonmediacosts", campaignNonMediaLineCostDeltaPresenter)
      .pipe(
        map((campaign) => {
          this.campaignStore.next(campaign);
          return campaign;
        })
      );
  }

  deleteCost(campaignId: number, campaignNonMediaCost: CampaignNonMediaCost): Observable<any> {
    return this.apiCall.makeRequest(
      "DELETE",
      this.endpoint + "/" + campaignId.toString() + "/nonmediacosts",
      campaignNonMediaCost
    );
  }

  uploadSitelist(campaignId: number, campaignLineId: string, sitelistType: string, file: File) {
    const form: FormData = new FormData();
    const dict = { file: file };
    for (const key in dict) {
      form.append(key, dict[key]);
    }
    return this.apiCall.post(
      `${CAMPAIGNS_BASE_URL}/${campaignId}/campaignLine/${campaignLineId}/siteList?type=${sitelistType.toUpperCase()}`,
      form
    );
  }

  downloadSitelist(campaignId: number, campaignLineId: string, sitelistType: string) {
    return this.apiCall.makeRequestWithOptions(
      "GET",
      `${CAMPAIGNS_BASE_URL}/${campaignId}/campaignLine/${campaignLineId}/siteList?type=${sitelistType.toUpperCase()}`,
      null,
      {
        observe: "body",
        responseType: "blob",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
  }

  addFramesForUpsites(campaignId: number, campaignLineId: string, data) {
    return this.apiCall.post(`${CAMPAIGNS_BASE_URL}/${campaignId}/line/${campaignLineId}/upsites`, data);
  }

  amendFramesForUpsites(campaignId: number, campaignLineId: string, data) {
    return this.apiCall.post(`${CAMPAIGNS_BASE_URL}/${campaignId}/line/${campaignLineId}/upsites/amend`, data);
  }

  removeFramesForUpsites(campaignId: number, campaignLineId: string, data) {
    return this.apiCall.delete(`${CAMPAIGNS_BASE_URL}/${campaignId}/line/${campaignLineId}/upsites/remove`, data);
  }

  addFramesForDigitalUpsites(campaignId: number, campaignLineId: string, data) {
    return this.apiCall.post(`${CAMPAIGNS_BASE_URL}/${campaignId}/line/${campaignLineId}/sitelist/digital/add`, data);
  }

  amendFramesForDigitalUpsites(campaignId: number, campaignLineId: string, data) {
    return this.apiCall.post(`${CAMPAIGNS_BASE_URL}/${campaignId}/line/${campaignLineId}/sitelist/digital/amend`, data);
  }

  removeFramesForDigitalUpsites(campaignId: number, campaignLineId: string, data) {
    return this.apiCall.delete(
      `${CAMPAIGNS_BASE_URL}/${campaignId}/line/${campaignLineId}/sitelist/digital/remove`,
      data
    );
  }

  deleteUpsites(campaignLineId: string) {
    return this.apiCall.makeRequest("DELETE", `${CAMPAIGNS_BASE_URL}/line/${campaignLineId}/siteList`);
  }

  updateStatusForUpsites(campaignId: number, campaignLineId: string, data) {
    return this.apiCall.post(`${CAMPAIGNS_BASE_URL}/${campaignId}/line/${campaignLineId}/upsites/status`, data);
  }

  getConditionallyEditableFields(campaignId: number): Observable<[string]> {
    return this.apiCall.get(`${CAMPAIGNS_BASE_URL}/${campaignId}/conditionallyEditableFields`);
  }

  updateCancellationPolicy(cancellationPolicy: string, campaignId: number, lineId: string): Observable<[string]> {
    return this.apiCall.put(`${CAMPAIGNS_BASE_URL}/${campaignId}/line/${lineId}/cancellation-policy`, {
      cancellationPolicy,
    });
  }

  updateCustomerReference(mediaCustomerReference: string, campaignId: number, lineId: string): Observable<any> {
    return this.apiCall.put(`${CAMPAIGNS_BASE_URL}/${campaignId}/line/${lineId}/customer-reference`, {
      customerReference: mediaCustomerReference,
    });
  }

  updatePlacementReference(placementReference: string, campaignId: number, lineId: string): Observable<any> {
    return this.apiCall.put(`${CAMPAIGNS_BASE_URL}/${campaignId}/line/${lineId}/placement-reference`, {
      placementReference,
    });
  }

  updateContractReference(supplierContractReference: string, campaignId: number, lineId: string): Observable<any> {
    return this.apiCall.put(`${CAMPAIGNS_BASE_URL}/${campaignId}/line/${lineId}/supplier-contract-reference`, {
      supplierContractReference,
    });
  }

  updateMultiLineCustomerReference(
    campaignId: string,
    lineIds: string[],
    mediaCustomerReference?: string,
    nonMediaCustomerReference?: string
  ): Promise<any> {
    return this.apiCall
      .put(`${CAMPAIGNS_BASE_URL}/${campaignId}/bulk-update/customer-reference`, {
        mediaCustomerReference,
        nonMediaCustomerReference,
        lineIds,
      })
      .toPromise();
  }

  unlockCampaignLines(campaignId: number, lineIds: string[]): Promise<any> {
    return this.apiCall
      .post(`${CAMPAIGNS_BASE_URL}/${campaignId}/campaign-lines/bulk-update/unlock`, {
        lineIds,
      })
      .toPromise();
  }

  lockCampaignLines(campaignId: number, lineIds: string[]): Promise<any> {
    return this.apiCall
      .post(`${CAMPAIGNS_BASE_URL}/${campaignId}/campaign-lines/bulk-update/lock`, {
        lineIds,
      })
      .toPromise();
  }

  updateMultiLineContractReference(
    supplierContractReference: string,
    campaignId: number,
    lineIds: string[]
  ): Observable<any> {
    return this.apiCall.put(`${CAMPAIGNS_BASE_URL}/${campaignId}/bulk-update/supplier-contract-reference`, {
      supplierContractReference,
      lineIds,
    });
  }

  toggleClientApproval(campaignId: number, lineId: string, modifiedBy: string, modifiedOnTimeZone: string) {
    return this.apiCall.put(`${CAMPAIGNS_BASE_URL}/${campaignId}/line/${lineId}/client-approval`, {
      modifiedBy,
      modifiedOnTimeZone,
    });
  }

  updateClientApprovalForMultipleLines(
    campaignId: number,
    lineIds: string[],
    approvalState: boolean,
    modifiedBy: string,
    modifiedOnTimeZone: string
  ) {
    return this.apiCall.put(`${CAMPAIGNS_BASE_URL}/${campaignId}/bulk-update/client-approval`, {
      modifiedBy,
      modifiedOnTimeZone,
      lineIds,
      approvalState,
    });
  }

  getByIdWithContractStatus(id: string): Observable<Campaign> {
    return this.apiCall.get<Campaign>(this.endpoint + "/" + id + "?include=contractStatus");
  }

  getOptionedAndBookedLines(campaignId: string): Promise<CampaignLinePresenter[]> {
    return this.getByIdWithContractStatus(campaignId)
      .toPromise()
      .then((campaign) => {
        return campaign.campaignLinePresenters.filter((line) => {
          return (
            getLastTradingStatus(line) === TradingStatus.OPTIONED || getLastTradingStatus(line) === TradingStatus.BOOKED
          );
        });
      });
  }

  syncCampaignToFinance(campaignId: string): Observable<void> {
    return this.apiCall.post(`${this.endpoint}/${campaignId}/finance-sync`, null);
  }

  toggleCampaignVisibilityToClients(campaignId: string | number) {
    return this.apiCall.put(`${this.endpoint}/${campaignId}/toggle-visibility`, null).toPromise();
  }

  updateMultilineOperation(campaignId: string, updateMultilineOpsRequest: UpdateMultilineOpsRequest): Promise<any> {
    return this.apiCall.put(`${CAMPAIGNS_BASE_URL}/${campaignId}/bulk-update`, updateMultilineOpsRequest).toPromise();
  }

  unlockCampaign(campaignId: number) {
    return this.apiCall.post(`${this.endpoint}/${campaignId}/unlock`, null).pipe(
      catchError((err) => {
        if (err.status === HttpStatusCode.Forbidden) {
          return throwError({ status: HttpStatusCode.Forbidden, message: "Forbidden" });
        }
        return throwError(err);
      })
    );
  }

  lockCampaign(campaignId: number) {
    return this.apiCall.post(`${this.endpoint}/${campaignId}/lock`, null).pipe(
      catchError((err) => {
        if (err.status === HttpStatusCode.Forbidden) {
          return throwError({ status: HttpStatusCode.Forbidden, message: "Forbidden" });
        }
        return throwError(err);
      })
    );
  }

  getCampaignHeadersById(id: string): Promise<CampaignHeaders> {
    return this.apiCall.get(`${CAMPAIGNS_BASE_URL}/${id}/headers`).toPromise();
  }

  notifyForReview(campaignId: number) {
    // TODO: Send optional data if users add
    return this.apiCall.post(`${this.endpoint}/${campaignId}/notify`, null);
  }
}

export function getLastTradingStatus(campaignLine: CampaignLinePresenter): TradingStatus {
  const tradingActionsSize = campaignLine.tradingActionDOS?.tradingActions.length;
  const lastTradingAction = campaignLine.tradingActionDOS?.tradingActions[tradingActionsSize - 1];
  return lastTradingAction.tradingStatus;
}
