import { compareLists } from "src/app/core/common/utils";
import { PointOfInterest } from "../../../models/domain/poi/PointOfInterest";
import { Frame } from "../../../models/domain/Frame";
import { COLOR_CODES } from "../map-view-constants";
import { Injectable } from "@angular/core";

import { MapFilter } from "./mapFilter";
import { FrameService } from "src/app/core/svc/resource-svc/frame/frame.service";
import { FormatColorCodes } from "src/app/core/models/domain/FormatColorCode";
import frameByMediaOwnerPinColors from "../../../../../assets/json/frame-pin-colors.json";
import { FrameLegendBy } from "plato-map-lib";
import { ConfigurationService } from "src/app/core/svc/configuration-service";

const environment = new ConfigurationService().getConfig();
const filterCaptions = environment.gridConfig.filterCaptions;
@Injectable({
  providedIn: "root",
})
export class MapBoxLegendManager {
  private showAllFormatTypeColorCodeMappingForLegend: {} = {};
  private showAllMediaOwnerColorCodeMappingForLegend: {} = {};
  private showAllFramesSupportedFilter: MapFilter[] = [];
  private formatColorCodes: FormatColorCodes;

  constructor(private frameService: FrameService) {
    frameService.getFormatColorCodes().subscribe((formatColorCodes) => {
      this.formatColorCodes = formatColorCodes;
    });
  }

  public getColorCodesAndFiltersOnNewData(
    frames: Frame[],
    maxMarkers: number,
    frameLegendBy: string
  ): [{}, MapFilter[]] {
    if (frames.length === 0) {
      return [{}, []];
    }
    const [formatColorCodes, moColorCodes, filterArr] = this.getAllColorCodesAndFilters(frames, maxMarkers);
    this.showAllMediaOwnerColorCodeMappingForLegend = moColorCodes;
    this.showAllFormatTypeColorCodeMappingForLegend = formatColorCodes;
    this.showAllFramesSupportedFilter = filterArr;
    const colourCode = frameLegendBy == FrameLegendBy.Format ? formatColorCodes : moColorCodes;
    return [colourCode, filterArr];
  }

  public getAllColorCodesAndFilters(frames: Frame[], maxMarkers: number): [{}, {}, MapFilter[]] {
    if (!this.formatColorCodes) {
      return [{}, {}, []];
    }

    const formatNamesSet: Set<string> = new Set();
    const environmentSet: Set<string> = new Set();
    const mediaOwnerSet: Set<string> = new Set();
    const moColorsList: string[] = frameByMediaOwnerPinColors;
    const frameLegendFormatColorCodeMapping = {};
    const frameLegendMediaOwnerColorCodeMapping = {};
    const prohibitionStatusSet: Set<string> = new Set();
    (frames || []).slice(0, maxMarkers).forEach((frame) => {
      if (frameLegendFormatColorCodeMapping[frame.format.formatName] == undefined) {
        frameLegendFormatColorCodeMapping[frame.format.formatName] = !this.formatColorCodes[frame.format.formatName]
          ? "#000000"
          : this.formatColorCodes[frame.format.formatName];
      }

      formatNamesSet.add(frame.format.formatName);
      environmentSet.add(frame.environment.name);
      mediaOwnerSet.add(frame.mediaOwner.name);
      if (this.isFrameHasProhibition(frame)) {
        prohibitionStatusSet.add(frame.prohibition.status);
      }
    });
    Array.from(mediaOwnerSet.values()).forEach((element, index) => {
      frameLegendMediaOwnerColorCodeMapping[element] = moColorsList[index] ? moColorsList[index] : "#000000";
    });
    return [
      frameLegendFormatColorCodeMapping,
      frameLegendMediaOwnerColorCodeMapping,
      this.getSupportedFilterFor(
        Array.from(formatNamesSet),
        Array.from(environmentSet),
        Array.from(mediaOwnerSet),
        Array.from(prohibitionStatusSet)
      ),
    ];
  }

  public getFormatColorCodeMappingForPOIs(poiList: PointOfInterest[]) {
    const availableColorCodesForPOIs = [...COLOR_CODES].reverse();
    const poiColorCodeMapping = {};
    const poiLegendColors = {};
    if (poiList.length !== 0) {
      poiList.forEach((poi) => {
        if (poiColorCodeMapping[poi.name] == undefined && poi.name != null) {
          poiColorCodeMapping[poi.name] = availableColorCodesForPOIs.pop();

          if (poi.brand != null) {
            poiLegendColors[poi.brand] = poiColorCodeMapping[poi.name];
          }
        } else if (poiColorCodeMapping[poi.postalCode] == undefined && poi.postalCode !== null) {
          poiColorCodeMapping[poi.postalCode] = availableColorCodesForPOIs.pop();
          poiLegendColors[poi.postalCode] = poiColorCodeMapping[poi.postalCode];
        }
      });
    }
    return poiColorCodeMapping;
  }

  public getColorCodeMappingForSelectedFrames(
    frames: Frame[],
    maxMarkers: number,
    frameLegendBy: string
  ): [{}, MapFilter[]] {
    const formatNamesSet: Set<string> = new Set<string>();
    const environmentSet: Set<string> = new Set();
    const mediaOwnerSet: Set<string> = new Set();
    const prohibitionStatusSet: Set<string> = new Set();
    (frames || []).slice(0, maxMarkers).forEach((frame) => {
      formatNamesSet.add(frame.format.formatName);
      environmentSet.add(frame.environment.name);
      mediaOwnerSet.add(frame.mediaOwner.name);
      if (this.isFrameHasProhibition(frame)) {
        prohibitionStatusSet.add(frame.prohibition.status);
      }
    });

    let formatColorCodeMapping: {} = {};
    let moColorCodeMapping: {} = {};
    if (Object.keys(this.showAllMediaOwnerColorCodeMappingForLegend).length > 0) {
      mediaOwnerSet.forEach(
        (mediaOwner) => (moColorCodeMapping[mediaOwner] = this.showAllMediaOwnerColorCodeMappingForLegend[mediaOwner])
      );
    }
    if (Object.keys(this.showAllFormatTypeColorCodeMappingForLegend).length > 0) {
      formatNamesSet.forEach(
        (formatName) =>
          (formatColorCodeMapping[formatName] = this.showAllFormatTypeColorCodeMappingForLegend[formatName])
      );
    }
    if (
      Object.keys(this.showAllFormatTypeColorCodeMappingForLegend).length == 0 &&
      Object.keys(this.showAllMediaOwnerColorCodeMappingForLegend).length == 0
    ) {
      const [colorCodeMappingForFrames, filters] = this.getColorCodesAndFiltersOnNewData(
        frames,
        maxMarkers,
        frameLegendBy
      );
      frameLegendBy == FrameLegendBy.Format
        ? (formatColorCodeMapping = colorCodeMappingForFrames)
        : (moColorCodeMapping = colorCodeMappingForFrames);
    }
    const colorCodeMapping = frameLegendBy == FrameLegendBy.Format ? formatColorCodeMapping : moColorCodeMapping;
    return [
      colorCodeMapping,
      this.getSupportedFilterFor(
        Array.from(formatNamesSet),
        Array.from(environmentSet),
        Array.from(mediaOwnerSet),
        Array.from(prohibitionStatusSet)
      ),
    ];
  }

  public setShowAllFormatTypeColorCodeMappingForLegend(formatmapping: {}) {
    this.showAllFormatTypeColorCodeMappingForLegend = formatmapping;
  }

  public getShowAllFormatTypeColorCodeMappingForLegend() {
    return this.showAllFormatTypeColorCodeMappingForLegend;
  }

  public getShowAllMediaOwnerColorCodeMappingForLegend() {
    return this.showAllMediaOwnerColorCodeMappingForLegend;
  }

  public setShowAllFramesSupportedFilter(supportedFilters: MapFilter[]) {
    this.showAllFramesSupportedFilter = supportedFilters;
  }

  public getShowAllFramesSupportedFilter() {
    return this.showAllFramesSupportedFilter;
  }

  private isFrameHasProhibition(frame): boolean {
    return frame.prohibition && frame.prohibition.status;
  }

  private getSupportedFilterFor(formatNames, environmentNames, mediaOwnerNames, prohibitionStatuses): MapFilter[] {
    const supportedFilter = [
      {
        displayName: filterCaptions.format,
        type: "formatName",
        values: formatNames.sort(compareLists),
      },
      {
        displayName: filterCaptions.environment,
        type: "environment",
        values: environmentNames.sort(compareLists),
      },
      {
        displayName: filterCaptions.media_owner,
        type: "mediaOwnerName",
        values: mediaOwnerNames.sort(compareLists),
      },
    ];
    if (prohibitionStatuses.length > 0) {
      supportedFilter.push({
        displayName: filterCaptions.prohibition_status,
        type: "prohibitionStatus",
        values: prohibitionStatuses.sort(compareLists),
      });
    }
    return supportedFilter;
  }
}
