import { Injectable } from "@angular/core";
import moment from "moment-timezone";
import { earliestDateTZRegion, latestDateTZRegion } from "src/app/core/common/constants/const";
import { PlatoTimeZoneTypes } from "../../../models/domain/PlatoTimeZoneTypes";
import { ConfigurationService } from "src/app/core/svc/configuration-service";

const environment = new ConfigurationService().getConfig();
const dateFormat = environment.Formats.dateFormat;
@Injectable({
  providedIn: "root",
})
export class DateService {
  constructor() {}

  getLocalDateInUTC(date: Date): Date {
    return moment(date.getTime()).utc().toDate();
  }

  getDateCorrespondingToTimezoneInUTC(date: Date, timezoneType: PlatoTimeZoneTypes): Date {
    if (date === null || timezoneType === null) {
      throw new Error("Arguments are null");
    }
    const timezoneRegion =
      timezoneType === PlatoTimeZoneTypes.EARLIEST_TIME_ZONE ? earliestDateTZRegion : latestDateTZRegion;
    const timeString = timezoneType === PlatoTimeZoneTypes.EARLIEST_TIME_ZONE ? "T00:00:00" : "T23:59:59";
    const dateString =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2) +
      timeString;
    const dateInSpecifiedTZ = moment.tz(dateString, timezoneRegion);
    return new Date(dateInSpecifiedTZ.utc().format());
  }

  getDateTimeStringCorrespondingToTimezoneInUTC(date: Date, timezoneType: PlatoTimeZoneTypes): string {
    if (date === null || timezoneType === null) {
      throw new Error("Arguments are null");
    }
    const timezoneRegion =
      timezoneType === PlatoTimeZoneTypes.EARLIEST_TIME_ZONE ? earliestDateTZRegion : latestDateTZRegion;
    const timeString =
      "T" +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2) +
      ":" +
      ("0" + date.getSeconds()).slice(-2);
    const dateString =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2) +
      timeString;
    const dateInSpecifiedTZ = moment.tz(dateString, timezoneRegion);
    return new Date(dateInSpecifiedTZ.utc().format()).toUTCString();
  }

  getDateConvertedBacktoLocalFromUTC(datString: string, timezoneType: PlatoTimeZoneTypes): Date {
    const timezoneRegion =
      timezoneType === PlatoTimeZoneTypes.EARLIEST_TIME_ZONE ? earliestDateTZRegion : latestDateTZRegion;
    const utcDate = moment(datString).tz("UTC");
    const regionalDate = utcDate.clone().tz(timezoneRegion);
    return new Date(regionalDate.year(), regionalDate.month(), regionalDate.date());
  }

  getDateInProvidedTZFromBrowserLocal(date, timeZone: PlatoTimeZoneTypes) {
    const timezoneRegion =
      timeZone === PlatoTimeZoneTypes.EARLIEST_TIME_ZONE ? earliestDateTZRegion : latestDateTZRegion;
    return moment(date).tz(timezoneRegion).format(dateFormat);
  }

  getUTCDate(dateString: string): Date {
    const date = moment.utc(dateString);
    return new Date(Date.UTC(date.year(), date.month(), date.date()));
  }

  getDateInISOFormat(date: Date, timezoneType: PlatoTimeZoneTypes, timezoneRegion: string) {
    const timeString = timezoneType === PlatoTimeZoneTypes.EARLIEST_TIME_ZONE ? "T00:00:00" : "T23:59:59";
    const dateString =
      date.getUTCFullYear() +
      "-" +
      ("0" + (date.getUTCMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getUTCDate()).slice(-2) +
      timeString;
    const fromTimeInMoment = moment.tz(dateString, timezoneRegion);
    return fromTimeInMoment.toISOString();
  }

  getDateFromStringAndTimezone(dateString: string, timezoneRegion = "UTC"): Date {
    return moment(dateString).tz(timezoneRegion).toDate();
  }

  getDateTimeFromEpoch(epoch: string): string {
    const epochInNumber = parseInt(epoch, 10);
    if (isNaN(epochInNumber)) {
      return "Invalid DateTime";
    }
    const date = new Date(epochInNumber);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
}
