<div
  class="campaign-root-page"
  style="height: 200px; overflow: auto"
  scrollTop
  (scrollPosition)="scrollChanged($event)"
>
  <form [formGroup]="notesStatusForm">
    <div class="col-12 row campaign-header-bar camp-name-draft">
      <app-campaign-breadcrumb [campaign]="storedCampaign" showCampaignId="true"></app-campaign-breadcrumb>

      <div class="action-icons" *ngIf="id">
        <div class="campaign-visibility" *ngIf="showDashboardControl()">
          <div>
            Show to External <br />
            Dashboard Users
          </div>
          <mat-slide-toggle
            color="primary"
            [checked]="storedCampaign?.isReadyForClients ?? false"
            (change)="onToggleCampaignVisibility($event)"
          >
          </mat-slide-toggle>
        </div>
        <div>
          <img
            src="../../../../assets/images/notify.svg"
            class="img-20 cursor-pointer"
            (click)="onNotifyClick()"
            matTooltip="Notify Investment"
            matTooltipPosition="below"
          />
        </div>
        <div class="documents">
          <img
            src="../../../../assets/images/documents.svg"
            class="img-20 cursor-pointer"
            (click)="stepper.selectedIndex = stepper.steps.length - 1"
            matTooltip="Documents"
            matTooltipPosition="below"
          />
        </div>
        <div class="camp-notes no-draft-sec">
          <ng-template #campaignNotesTemplate class="campaign-notes-pop">
            <div class="p-0 col-12 text-editor">
              <label>Campaign Notes</label>
              <quill-editor formControlName="campaignNotes" sanitize="true"></quill-editor>
            </div>
          </ng-template>
          <img
            src="../../../../assets/images/icon-notes.svg"
            class="img-20 cursor-pointer"
            [popover]="campaignNotesTemplate"
            clickOutsideEnabled
            placement="bottom"
            matTooltip="Campaign Notes"
            matTooltipPosition="below"
            matTooltipClass="tool-tip"
          />
        </div>
        <app-campaign-lock [campaignId]="id" [campaignLockStatus]="campaignLockStatus"></app-campaign-lock>
        <div class="finance-sync" *ngIf="allowFinanceSync()">
          <img
            src="../../../../assets/images/finance-netsuite-sync-icon.svg"
            class="img-20 cursor-pointer"
            (click)="onFinanceSyncClicked()"
            matTooltip="Send to NetSuite"
            matTooltipPosition="below"
          />
        </div>
        <a matTooltip="View Campaign on Map" matTooltipPosition="below" [href]="'/campaigns/' + id + '/map-view'">
          <mat-icon class="material-icons img-20 cursor-pointer map-view" color="primary">pin_drop</mat-icon>
        </a>

        <div
          class="p-0 campaign-status text-right draft_sec"
          *ngIf="
            notesStatusForm.controls.campaignStatus.value !== undefined &&
            notesStatusForm.controls.campaignStatus.value !== null &&
            notesStatusForm.controls.campaignStatus.value !== ''
          "
        >
          <select data-cy="campaign-status" formControlName="campaignStatus" class="select-arrow" id="campaign-status">
            <option [ngValue]="state?.state" *ngFor="let state of nextStates">
              {{ state?.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <mat-horizontal-stepper
      #stepper
      (selectionChange)="onCampaignTabsClicked($event)"
      [class]="id ? '' : 'new-campaign'"
    >
      <mat-step>
        <ng-template matStepLabel>Details</ng-template>
        <campaign-details
          #campaignDetails
          [id]="id"
          [token]="token"
          [roles]="roles"
          [campaignHeaders]="campaignHeaders"
          (updatedStoredCampaignInParent)="updateStoredCampaign($event)"
          [isPlexusCampaign]="isPlexusCampaign"
          [currencyList]="currencyList"
          [notesStatusForm]="notesStatusForm"
        >
        </campaign-details>
      </mat-step>
      <mat-step *ngIf="id && planningTabState.isVisible">
        <ng-template matStepLabel>
          <span data-cy="planning-tab"> PLANNING </span>
        </ng-template>
        <app-campaign-planning [campaign]="storedCampaign"> </app-campaign-planning>
      </mat-step>
      <mat-step *ngIf="id && isRfpEnabled && showRFPTab">
        <ng-template matStepLabel>MEDIA RFP</ng-template>
        <app-rfp></app-rfp>
      </mat-step>
      <mat-step *ngIf="id">
        <ng-template matStepLabel>
          <span data-cy="campaign-media-and-cost-button"> Campaign Media & Costs </span>
        </ng-template>
        <app-media-and-costs-v3
          #mediaAndCostsV3Component
          *ngIf="!isPlexusCampaign"
          [token]="token"
          [roles]="roles"
          [campaign]="storedCampaign"
          [availability]="availabilityStatuses"
          [refreshCampaignBookingInfo]="refreshCampaignBookingInfo"
          [highLightSchedule]="highLightSchedule"
          [scrollTopVal]="scrollTopVal"
          [schedulesWithTradingMode]="schedulesWithTradingMode"
          (onNonMediaCostUpdateEvent)="updateCampaign($event)"
          [routeVersion]="campaignDetailsComponent.campaign.get('campaignDetails.routeVersion')?.value"
          (triggerRefreshContracts)="refreshContractsList()"
          (triggerRefreshDocs)="campaignDocuments.showCampaignData($event)"
        >
        </app-media-and-costs-v3>

        <app-media-and-costs-plexus
          #mediaAndCostsPlexusComponent
          *ngIf="isPlexusCampaign"
          [token]="token"
          [roles]="roles"
          [campaign]="storedCampaign"
          [refreshCampaignBookingInfo]="refreshCampaignBookingInfo"
          [highLightSchedule]="highLightSchedule"
          [scrollTopVal]="scrollTopVal"
          [availability]="availabilityStatuses"
          [schedulesWithTradingMode]="schedulesWithTradingMode"
          (onNonMediaCostUpdateEvent)="updateCampaign($event)"
          [routeVersion]="campaignDetailsComponent.campaign.get('campaignDetails.routeVersion')?.value"
        >
        </app-media-and-costs-plexus>
      </mat-step>
      <mat-step *ngIf="id && enableContracts">
        <ng-template matStepLabel>CONTRACTS</ng-template>
        <app-contracts #contractsList></app-contracts>
      </mat-step>
      <mat-step *ngIf="id">
        <ng-template matStepLabel>Delivery</ng-template>
        <app-campaign-delivery
          #campaignDeliveryComponent
          [roles]="roles"
          [campaign]="storedCampaign"
          (refreshCampaign)="refreshCampaign()"
        >
        </app-campaign-delivery>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Documents</ng-template>
        <app-campaign-documents #campaignDocuments [id]="id"></app-campaign-documents>
      </mat-step>
    </mat-horizontal-stepper>
  </form>
</div>

<ng-template #notificationConfirmationTemplate>
  <app-confirmation-dialog
    message="Are you sure you want to send a notification to Investment?"
    (confirm)="notifyForReview()"
  >
  </app-confirmation-dialog>
</ng-template>
