import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GenericPrice, GenericPriceCriteria } from "src/app/core/models/domain/GenericPriceCriteria";
import { ApiService } from "../../api/api.service";

@Injectable({
  providedIn: "root",
})
export class FramePricingService {
  constructor(private apiCall: ApiService) {}

  getPriceForGenericCriteria(genericCriteria: GenericPriceCriteria[]): Observable<GenericPrice[]> {
    return this.apiCall.post(`/frame-pricing/generic-prices`, genericCriteria, {
      "Content-type": "application/json",
    });
  }

  getPriceForFrame(frameIds: string[]): Observable<any> {
    return this.apiCall.post(`/frame-pricing/frame-prices`, frameIds, {
      "Content-type": "application/json",
    });
  }
}
