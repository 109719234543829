import { Injectable } from "@angular/core";
import { ApiService } from "../../api/api.service";
import { ResourceService } from "../resource-service.service";
import { Frame } from "../../../models/domain/Frame";
import { Predicate } from "src/app/core/models/domain/Predicate";
import { Observable } from "rxjs";
import { Page } from "../../../models/domain/Page";
import { GroupByCriterion } from "src/app/core/models/domain/group-by-criterion";
import { FrameGroup } from "src/app/core/models/domain/FrameGroup";
import { map } from "rxjs/operators";
import { PanelFrameMapping } from "src/app/core/models/domain/PanelFrameMapping";
import { FrameAttributeFilterRequest } from "src/app/core/models/domain/FrameAttributeFilterRequest";
import { AssociatedPOIDetails } from "src/app/core/models/domain/poi/AssociatedPOIDetails";
import { ProhibitionType } from "src/app/core/models/domain/prohibition/ProhibitionType";
import { ShareOfTime } from "src/app/core/models/domain/ShareOfTime";
import { FeatureToggleService } from "../featureToggle/featureToggle.service";

export enum DisplaySearchMode {
  countSearch,
  gridSearch,
  mapSearch,
}

@Injectable({
  providedIn: "root",
})
export class FrameService extends ResourceService<Frame> {
  constructor(apiCall: ApiService, private featureToggleService: FeatureToggleService) {
    super(apiCall, "/inventory/frames");
  }

  //TODO: Disable export button when filters are reset and vice versa - discuss with Manish
  //TODO: Add animation for export functionality
  export(fields: string[], criteria: Predicate): Observable<any> {
    const searchCriteria: any = { fields: fields };

    for (const key of Object.keys(criteria)) {
      searchCriteria[key] = criteria[key];
    }

    return this.apiCall.makeRequestWithOptions("POST", "/inventory/frames/export.xlsx", searchCriteria, {
      body: criteria,
      responseType: "arraybuffer",
      headers: {
        "Content-type": "application/json",
      },
    });
  }

  search(predicate: Predicate, page?: number, size?: number, sortBy?: string): Observable<Page<any>> {
    return this.apiCall.post(this.endpoint + "/search?" + this.getPaginationParams(page, size, sortBy), predicate);
  }

  searchByFrameIds(frameIds: string[], page?: number, size?: number): Promise<Page<Frame>> {
    const criteria = new Predicate();
    criteria.frameId = frameIds;
    return this.search(criteria, page, size).toPromise();
  }

  displaySearch(
    predicate: Predicate,
    page?: number,
    size?: number,
    sortBy?: string,
    searchMode?: DisplaySearchMode
  ): Observable<Page<any>> {
    return this.apiCall.post(
      this.endpoint + "/search?" + this.getPaginationParams(page, size, sortBy) + this.getAdditionalParams(searchMode),
      predicate
    );
  }

  private getAdditionalParams(searchMode?: DisplaySearchMode) {
    if (
      DisplaySearchMode.gridSearch === searchMode &&
      this.featureToggleService.isFeatureEnabled("ENABLE_PRICING_IN_SEARCH")
    ) {
      return "&include=price";
    }

    return "";
  }

  searchSyn(predicate: Predicate, page?: number, size?: number, sortBy?: string): Promise<Page<any>> {
    return this.apiCall
      .post(this.endpoint + "/search?" + this.getPaginationParams(page, size, sortBy), predicate)
      .toPromise();
  }

  getPanelFrameMapping(): Observable<Page<PanelFrameMapping>> {
    const formatGroup = "Large Format Digital";
    const frameStatus = "OPEN";
    return this.apiCall.get(this.endpoint + "/attributes/panelNames?page=0&size=4062");
  }

  getAssociatedPOIDetails(frameId: string, prohibitionType: ProhibitionType): Observable<AssociatedPOIDetails> {
    return this.apiCall.get(
      this.endpoint + "/" + frameId + "/prohibitedPOIDetails?prohibition_type=" + prohibitionType
    );
  }

  searchGrouped(
    predicate: Predicate,
    groupByCriterion: GroupByCriterion,
    page?: number,
    size?: number,
    sortBy?: string
  ): Observable<Page<FrameGroup>> {
    return this.apiCall
      .post(this.endpoint + "/search?" + this.getPaginationParams(page, size, sortBy), {
        ...predicate,
        groupByCriterion,
      })
      .pipe(
        map(({ content, ...rest }) => ({
          ...rest,
          content: content.map(({ groupByDetails }) => ({
            ...groupByDetails,
            groupByField: groupByCriterion.fieldNameToGroupBy,
          })),
        }))
      );
  }

  getSuggestionsForField(fieldName: string, page_size = "2000"): Observable<Page<any>> {
    return this.apiCall.get(
      "/inventory/frames/attributes/values/distinct?page=0&size=" + page_size + "&name=" + fieldName
    );
  }

  getSuggestionsForFieldWithFilter(
    fieldName: string,
    filter: FrameAttributeFilterRequest,
    page_size = "2000"
  ): Observable<Page<any>> {
    return this.apiCall.post(
      "/inventory/frames/attributes/values/distinct?page=0&size=" + page_size + "&name=" + fieldName,
      filter
    );
  }

  getBlacklistedFramesInPlannedState(body: Array<string>) {
    return this.apiCall.post("/inventory/frames/identifyBlacklistFrames", body, {
      "Content-type": "application/json",
    });
  }

  getFormatColorCodes() {
    return this.apiCall.get("/inventory/formats/color-codes");
  }

  getMediaShareOfTimeDefaultValues(
    formatName: string,
    environment: string,
    mediaOwner: string
  ): Observable<ShareOfTime> {
    const formatNameURL = formatName ? "formatName=" + formatName + "&" : "";
    const environmentURL = environment ? "environment=" + environment + "&" : "";
    const mediaOwnerURL = mediaOwner ? "mediaOwner=" + mediaOwner : "";
    return this.apiCall.get(
      this.endpoint + "/mediaShareOfTime/defaultValues?" + formatNameURL + environmentURL + mediaOwnerURL
    );
  }
}
