import { Frame } from "src/app/core/models/domain/Frame";
import * as mapboxgl from "mapbox-gl";
import { Injectable } from "@angular/core";
import { MapEventObserver } from "src/app/core/svc/resource-svc/map/map.event.observer";
import { FrameLegendBy } from "plato-map-lib";
import { ConfigurationService } from "src/app/core/svc/configuration-service";
import { toggleLayer } from "./map-box-layers-utils";
import _ from "underscore";
import { applyFilter } from "./mapbox-utils";

const environment = new ConfigurationService().getConfig();
export const LOCATION_SOURCE_ID = "locationDataSource";

@Injectable({
  providedIn: "root",
})
export class LocationLayersManager {
  regionBasedFrameColorCoading = environment.frameColorCoding;
  private mapEventObserver: MapEventObserver;
  private readonly LOCATION_UNCLUSTERED_POINT_LAYER_ID = "locationUnclusteredPoint";
  private readonly LOCATION_LABEL_LAYER_ID = "locationLabels";
  private readonly LOCATION_UNCLUSTERED_LAYER_MIN_ZOOM = 0;
  private readonly LOCATION_UNCLUSTERED_LAYER_MAX_ZOOM = 14;

  private readonly DEFAULT_ORIENTATION_DEGREE = 0;

  private popUp: mapboxgl.Popup;
  private map: mapboxgl.Map;
  private functionToUpdateFrameDetailsPopUp;
  private layerPaintColorExpression: any[];
  private latMin: number;
  longMin: number;
  latMax: number;
  longMax: number;
  locationsData: Frame[] = [];

  private locationLayers: string[] = [];

  constructor(mapEventObserver: MapEventObserver) {
    this.mapEventObserver = mapEventObserver;
    this.locationLayers.push(this.LOCATION_UNCLUSTERED_POINT_LAYER_ID, this.LOCATION_LABEL_LAYER_ID);
  }

  public getLayerIds(): string[] {
    return this.locationLayers;
  }

  private locationLayerHighlightPaintColorExpression(value, defaultValue, selectedFrameIds) {
    return ["match", ["get", "frameId"], ["literal", ...selectedFrameIds], value, defaultValue];
  }

  public setUpData(map: mapboxgl.Map, popUp: mapboxgl.Popup, functionToUpdateFrameDetailsPopUp) {
    this.map = map;
    this.functionToUpdateFrameDetailsPopUp = functionToUpdateFrameDetailsPopUp;
    this.popUp = popUp;
    this.displayLocationDetailsForLayers([this.LOCATION_UNCLUSTERED_POINT_LAYER_ID]);
    this.setupLocationSource();
    this.loadImagesforMap();
  }

  public updateLocationLayers(locationsData: Frame[]) {
    if (!this.map) {
      return;
    }
    const features = [];
    this.latMin = 90;
    this.longMin = 180;
    this.latMax = -90;
    this.longMax = -180;
    locationsData.forEach((frame) => {
      features.push(this.convertToGeoJson(frame));
      if (frame.geography.location.coordinates[1] !== 0 && frame.geography.location.coordinates[1] !== 0) {
        this.latMin = Math.min(this.latMin, frame.geography.location.coordinates[1]);
        this.longMin = Math.min(this.longMin, frame.geography.location.coordinates[0]);
        this.latMax = Math.max(this.latMax, frame.geography.location.coordinates[1]);
        this.longMax = Math.max(this.longMax, frame.geography.location.coordinates[0]);
      }
    });
    this.locationsData = locationsData;
    this.updateLocationSource(features);
  }

  public getMapBoundsForAllFrames() {
    return [
      [this.longMin, this.latMin],
      [this.longMax, this.latMax],
    ];
  }

  public applyLegendColorToLocationLayers(colorCodeMapping: any, selectedFrameIds: string[], frameLegendBy: string) {
    const colorExpression = [];
    if (!this.map) {
      return;
    }

    if (Object.keys(colorCodeMapping).length !== 0) {
      Object.entries(colorCodeMapping).forEach(([key, value]) => {
        colorExpression.push(key);
        colorExpression.push(value);
      });
      this.layerPaintColorExpression = [
        "match",
        ["get", frameLegendBy == FrameLegendBy.MediaOwner ? "mediaOwnerName" : "formatName"],
        ...colorExpression,
        "#11b4da",
      ];

      this.map.setPaintProperty(
        this.LOCATION_UNCLUSTERED_POINT_LAYER_ID,
        "icon-color",
        this.locationLayerHighlightPaintColorExpression(
          "#000",
          this.layerPaintColorExpression || "#11b4da",
          selectedFrameIds
        )
      );
    }
  }

  public addLocationsLayer(map: mapboxgl.Map) {
    map.addLayer({
      id: this.LOCATION_UNCLUSTERED_POINT_LAYER_ID,
      type: "symbol",
      source: LOCATION_SOURCE_ID,
      layout: {
        "icon-image": "ffc-location-marker",
        "icon-allow-overlap": true,
        "icon-size": 0.24,
      },
      paint: {
        "icon-color": "#11b4da",
        "icon-halo-blur": 0,
        "icon-halo-width": 1,
      },
      minzoom: this.LOCATION_UNCLUSTERED_LAYER_MIN_ZOOM,
      maxzoom: this.LOCATION_UNCLUSTERED_LAYER_MAX_ZOOM,
    });
  }

  public addLabelsLayer(map: mapboxgl.Map) {
    map.addLayer({
      id: this.LOCATION_LABEL_LAYER_ID,
      type: "symbol",
      source: LOCATION_SOURCE_ID,
      layout: {
        "icon-image": "Text_holder",
        "icon-anchor": "bottom",
        "icon-allow-overlap": true,
        "text-allow-overlap": true,
        "text-field": this.getTextField("mediaOwnerReferenceIdForFrame"),
        "icon-offset": [0, -2],
        "icon-text-fit": "both",
        "icon-text-fit-padding": [8, 8, 18, 8],
        "text-size": 12,
        "text-offset": [0, -2],
        "text-justify": "center",
        visibility: "none",
      },
      paint: {
        "text-color": "#1777A7",
      },
    });
  }

  private getTextField(field: string) {
    const rawValue = ["get", field];
    const lengthMoreThan10 = [">", ["length", rawValue], 10];
    const truncateTo10Chars = ["concat", ["slice", rawValue, 0, 10], "..."];
    return ["case", lengthMoreThan10, truncateTo10Chars, rawValue];
  }

  public applyFilterOnAllLocations(allFilters) {
    const mapBoxExpressionsForSelectedFilters = this.getFilterExpressionForSelectedFilters(allFilters?.filters || {});
    this.applyFilters([...mapBoxExpressionsForSelectedFilters]);
  }
  private applyFilters(mapBoxExpressionsForSelectedFilters: any[]) {
    this.popUp.remove();
    this.locationLayers.forEach((layer) => {
      this.map.setFilter(layer, ["all", ...mapBoxExpressionsForSelectedFilters]);
    });
  }

  getFilterExpressionForSelectedFilters(selectedFilters: object): any[] {
    return Object.keys(selectedFilters).map((filterType) => [
      "in",
      ["get", filterType],
      ["literal", selectedFilters[filterType]],
    ]);
  }

  public resetSelectedFilters() {
    if (!this.map) {
      return;
    }
    applyFilter(this.map, [], this.locationLayers);
  }

  public applyHighlightedBorderToFrameLayer(selectedFrameIds: string[]) {
    if (this.regionBasedFrameColorCoading) {
      this.map.setPaintProperty(
        this.LOCATION_UNCLUSTERED_POINT_LAYER_ID,
        "icon-halo-width",
        this.locationLayerHighlightPaintColorExpression(3, 1, selectedFrameIds)
      );
      this.map.setPaintProperty(
        this.LOCATION_UNCLUSTERED_POINT_LAYER_ID,
        "icon-color",
        this.locationLayerHighlightPaintColorExpression(
          "#000",
          this.layerPaintColorExpression ? this.layerPaintColorExpression : "#11b4da",
          selectedFrameIds
        )
      );
    } else {
      this.map.setPaintProperty(
        this.LOCATION_UNCLUSTERED_POINT_LAYER_ID,
        "circle-stroke-width",
        this.locationLayerHighlightPaintColorExpression(3, 1, selectedFrameIds)
      );
      this.map.setPaintProperty(
        this.LOCATION_UNCLUSTERED_POINT_LAYER_ID,
        "circle-stroke-color",
        this.locationLayerHighlightPaintColorExpression("#1777A7", "#fff", selectedFrameIds)
      );
    }
  }

  public resetLocationSource() {
    this.popUp?.remove();
    this.resetMapSource(LOCATION_SOURCE_ID);
  }

  private setupLocationSource() {
    this.map.addSource(LOCATION_SOURCE_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
  }

  private updateLocationSource(featureCollection: any[]) {
    this.map.getSource(LOCATION_SOURCE_ID)?.setData({
      type: "FeatureCollection",
      features: featureCollection.filter((feature) => feature.properties.lineDetails?.containerName),
    });
  }

  private loadImagesforMap() {
    this.map.loadImage("../../../../assets/images/ffc_non_directional.png", (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage("ffc-location-marker", image, { sdf: true });
    });
  }

  private displayLocationDetailsForLayers(layerIds: string[]) {
    layerIds.map((layerId) => {
      this.map.on("mouseenter", layerId, () => this.changeCursorToPointer());
      this.map.on("mouseleave", layerId, () => this.resetCursor());
      this.map.on("click", layerId, (e) => this.displayPopUp(e.lngLat, e.features[0]));
    });
  }

  private setPopupHTMLAndWireEvents() {
    this.popUp.setHTML(document.getElementById("frameDetailsRef").innerHTML);
    this.functionToUpdateFrameDetailsPopUp();
  }

  private displayPopUp(clickedLngLat, frameDetails) {
    const coordinates = frameDetails.geometry.coordinates.slice();
    while (Math.abs(clickedLngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += clickedLngLat.lng > coordinates[0] ? 360 : -360;
    }

    this.functionToUpdateFrameDetailsPopUp(frameDetails).subscribe(() => {
      this.setPopUpCoordinates(coordinates);
    });
  }

  private setPopUpCoordinates(coordinates: number[]) {
    setTimeout(() => {
      this.popUp.setLngLat(coordinates).addTo(this.map);
      this.setPopupHTMLAndWireEvents();
    });
  }

  private changeCursorToPointer() {
    this.map.getCanvas().style.cursor = "pointer";
  }

  private resetCursor() {
    this.map.getCanvas().style.cursor = "";
  }

  private convertToGeoJson(site: Frame) {
    const frameSpecifications = site.specifications;
    const feature = {
      type: "Feature",
      properties: {
        formatName: site.format.formatName,
        faceType: site.format.faceType.toLowerCase(),
        frameId: site.frameId,
        mediaOwnerReferenceIdForFrame: site.mediaOwnerReferenceIdForFrame,
        mediaOwnerName: site.mediaOwner.name,
        address: site.geography?.address,
        impacts: site.formattedImpacts || site.frameStats?.averageImpacts,
        panelName: site.panelName == null ? site.geography?.address : site.panelName,
        postcode: site.geography?.postcode,
        environment: site.environment?.name,
        direction: site.geography?.frameDirection?.code || "",
        height: site.format.height,
        heightUnit: site.format?.heightUnits,
        width: site.format?.width,
        widthUnit: site.format?.widthUnits,
        frameSize: site.format?.size,
        sourceSystem: site.sourceSystem,
        noNetworkAssociatedFrames: site.network?.associatedFrameIds?.length,
        defaultImages: frameSpecifications?.imageUrls?.length ? JSON.stringify(frameSpecifications.imageUrls) : "[]",
        uploadedImages: JSON.stringify(site.photos) ? site.photos : {},
        latitude: `${site.geography?.location?.coordinates[1]}`,
        longitude: `${site.geography?.location?.coordinates[0]}`,
        blacklisted: site.isBlacklistedFrame,
        unitRationale: site.unitRationale,
        audienceIndex: site.audienceIndex,
        audienceOnTargetPercentage: site.audienceOnTargetPercentage,
        illumination: site.format?.illuminated ? "Yes" : "No",
        lineDetails: site.lineDetails,
      },
      geometry: {
        type: "Point",
        coordinates: site.geography.location.coordinates,
      },
    };
    return feature;
  }

  private resetMapSource(sourceId: string) {
    this.map?.getSource(sourceId).setData({
      type: "FeatureCollection",
      features: [],
    });
  }

  toggleFrameLabels() {
    toggleLayer(this.LOCATION_LABEL_LAYER_ID, this.map);
  }
}
