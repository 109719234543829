import { PointOfInterest } from "./../../../core/models/domain/poi/PointOfInterest";
import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewEncapsulation } from "@angular/core";
import {
  Share,
  POIIdentity,
  POIIdentities,
  CustomPOISetIdentity,
  CampaignLineInfo,
} from "../../../core/models/domain/Share";
import { ShareService } from "../../../core/svc/resource-svc/share/share.service";
import { Observable, BehaviorSubject, combineLatest, Subject, of } from "rxjs";
import { FeatureToggleService } from "src/app/core/svc/resource-svc/featureToggle/featureToggle.service";
import { UntypedFormGroup, Validators, UntypedFormControl, FormBuilder } from "@angular/forms";
import { Agency } from "src/app/core/models/domain/Agency";
import { Client } from "src/app/core/models/domain/Client";
import { switchMap, map, takeUntil } from "rxjs/operators";
import { AgencyService } from "src/app/core/svc/resource-svc/agency/agency.service";
import { ClientService } from "src/app/core/svc/resource-svc/client/client.service";
import { GeoLocationSearchCriterion } from "src/app/core/models/domain/GeoLocationSearchCriterion";
import { PoiInclusionExclusion } from "src/app/core/models/domain/poi/PoiInclusionExclusion";
import { ConfigurationService } from "src/app/core/svc/configuration-service";
import { Campaign } from "../../models/domain/Campaign";
import { UserService } from "../../svc/resource-svc/user/user.service";
import { CampaignLinePresenter } from "../../models/domain/CampaignLinePresenter";

const environment = new ConfigurationService().getConfig();

@Component({
  selector: "app-map-share",
  templateUrl: "./map-share.component.html",
  styleUrls: ["./map-share.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MapShareComponent implements OnInit, OnChanges {
  @Input() geoLocationSearchCriterion: GeoLocationSearchCriterion;
  @Input() frameIds;
  @Input() inclusionExclusionData: PoiInclusionExclusion;
  @Input() customPoiInclusionExclusionData: PoiInclusionExclusion;
  @Input() campaignDetails: Campaign;

  shareForm: UntypedFormGroup;
  createFormVisible: boolean = false;
  submitted = false;
  successFullySubmitted = false;
  errorWhileSharing = false;
  showPOICircle = true;

  public agencyFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public clientFilterCtrl: UntypedFormControl = new UntypedFormControl();
  private _onDestroy = new Subject<void>();

  sharePopUpOpened = false;
  shareLink: string;
  mapPortalUrl = "/mapportal/";
  disableMapShare = false;

  private shareResponse: Share;

  private loadingShares: boolean;
  shares: Share[] = [];
  public disableShareButton = true;
  private timer: Observable<any>;

  agencies: Array<Agency> = [];
  private _selectedAgencyCode: string;
  private _agencies$ = new BehaviorSubject<Agency[]>([]);
  private _agency$ = new BehaviorSubject<Agency>(undefined);
  get agency() {
    return this._agency$.value;
  }
  agencies$ = this._agencies$.asObservable();
  set selectedAgencyCode(code: string) {
    this._selectedAgencyCode = code;
    this._agency$.next(this._agencies$.value.find((agency) => agency.code === code));
    this.selectedClientCode = undefined;
  }
  get selectedAgencyCode() {
    const {
      _agency$: { value: agency },
    } = this;
    return agency == undefined ? this._selectedAgencyCode : agency.code;
  }

  clients: Array<Client> = [];
  private _selectedClientCode: string;
  set selectedClientCode(code: string) {
    this._selectedClientCode = code;
    this._client$.next(this._clients$.value.find((client) => client.code === code));
  }
  get selectedClientCode() {
    const {
      _client$: { value: client },
    } = this;
    return client == undefined ? this._selectedClientCode : client.code;
  }
  private _clients$ = new BehaviorSubject<Client[]>([]);
  private _client$ = new BehaviorSubject<Client>(undefined);
  get client() {
    return this._client$.value;
  }
  clients$ = this._agency$
    .asObservable()
    .pipe(
      switchMap((agency) =>
        agency == undefined
          ? of<Client[]>([])
          : this._clients$
              .asObservable()
              .pipe(map((clients) => clients.filter((client) => agency.clients.includes(client.code))))
      )
    );

  captions;
  currentRegion;
  private userEmail: string;

  constructor(
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private agencyService: AgencyService,
    private shareService: ShareService,
    private userService: UserService,
    private featureToggleService: FeatureToggleService
  ) {
    this.currentRegion = environment.region;
    this.shareForm = this.formBuilder.group({
      description: ["", Validators.required],
      campaignName: ["", Validators.required],
      client: ["", Validators.required],
      mediaAgency: ["", Validators.required],
      showPOICircles: [false],
    });
  }

  fetchDataFromServices() {
    const controls = this.shareForm.controls;
    const sortByName = <T>(items: T[]) =>
      items.sort((a, b) => (a["name"] < b["name"] ? -1 : a["name"] > b["name"] ? 1 : 0));
    const agencies$ = this.agencyService.getAll().pipe(map(sortByName));
    const clients$ = this.clientService.getAll().pipe(map(sortByName));
    combineLatest(agencies$, clients$).subscribe(([agencies, clients]) => {
      this.agencies = agencies;
      this.clients = clients;
      this._agencies$.next(agencies);
      this._clients$.next(clients);
      {
        // These sets load the necessary observables for using in the next block
        const { selectedAgencyCode, selectedClientCode } = this;
        this.selectedAgencyCode = selectedAgencyCode;
        this.selectedClientCode = selectedClientCode;
      }
      const { agency, client } = this;
      controls.mediaAgency.setValue(agency, { emitEvent: false });
      controls.client.setValue(client, { emitEvent: false });
    });
  }

  private setFilterControls() {
    this.agencyFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => this.filterAgencies());
    this.clientFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => this.filterClients());
  }

  private filterAgencies() {
    const searchString: string = this.agencyFilterCtrl.value;
    if (searchString)
      this._agencies$.next(
        this.agencies.filter((agency) => agency.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1)
      );
    else this._agencies$.next(this.agencies);
  }

  private filterClients() {
    const searchString: string = this.clientFilterCtrl.value;
    if (searchString)
      this._clients$.next(
        this.clients.filter((client) => client.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1)
      );
    else this._clients$.next(this.clients);
  }

  ngOnInit() {
    this.captions = environment.ShareListingPageCaptions;
    this.userService.getUserEmail().then((email) => (this.userEmail = email));
    this.subscibeToMapShareFeatureToggle();
    this.fetchDataFromServices();
    this.setFilterControls();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.frameIds.length > 0) {
      this.disableShareButton = false;
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  openPopUp() {
    if (this.frameIds.length > 0) this.togglePopUpVisibility();
  }

  closeErrorPopUp() {
    this.errorWhileSharing = false;
    this.togglePopUpVisibility();
  }

  onAgencyChange(event) {
    if (event.source.selected) {
      this.shareForm.controls.client.reset();
      this.selectedAgencyCode = event.source.value.code;
    }
  }

  setShowPOICircle(value) {
    this.showPOICircle = value;
  }

  share() {
    this.submitted = true;
    if ((this.campaignDetails || this.searchModified()) && this.shareForm.valid) {
      this.createShare();
    }
  }

  private searchModified(): boolean {
    let searchModified = false;
    if (!this.shareLink) {
      searchModified = true;
    }
    return searchModified;
  }

  private prepareShareAPIRequest(): Share {
    const shareRequest: Share = new Share();
    shareRequest.description = this.shareForm.value.description;
    shareRequest.campaignId = this.campaignDetails?.id;
    shareRequest.mediaAgency = this.shareForm.value.mediaAgency;
    shareRequest.client = this.shareForm.value.client;
    shareRequest.campaignName = this.shareForm.value.campaignName;
    shareRequest.frameIds = this.frameIds;
    shareRequest.poiData = this.populatePOIIdentities();
    shareRequest.showPOICircle = this.showPOICircle;

    if (this.geoLocationSearchCriterion) {
      shareRequest.boundaryUnitCodes = [
        ...(this.geoLocationSearchCriterion.postalCodes || []),
        ...(this.geoLocationSearchCriterion.postalSectors || []),
      ];
    }
    shareRequest.region = this.currentRegion;
    shareRequest.campaignLineInfos = this.getCampaignLineInfos(this.campaignDetails?.campaignLinePresenters);
    return shareRequest;
  }

  private getCampaignLineInfos(campaignLinePresenters: any[]): CampaignLineInfo[] {
    return campaignLinePresenters?.map((campaignLinePresenter) =>
      this.transformToCampaignLineInfo(campaignLinePresenter)
    );
  }

  private transformToCampaignLineInfo(campaignLinePresenter: any): CampaignLineInfo {
    const campaignLineInfo: CampaignLineInfo = new CampaignLineInfo();
    campaignLineInfo.campaignLineId = campaignLinePresenter.campaignLineId;
    campaignLineInfo.bookingStatus = campaignLinePresenter.bookingStatus;
    campaignLineInfo.type = campaignLinePresenter.scheduleType;
    campaignLineInfo.containerName = campaignLinePresenter.containerName || "";
    campaignLineInfo.schedule = {
      startDate: campaignLinePresenter.startDate,
      endDate: campaignLinePresenter.endDate,
    };
    return campaignLineInfo;
  }

  private createShare() {
    const shareRequest: Share = this.prepareShareAPIRequest();
    this.shareService.share(shareRequest).subscribe(
      (share) => {
        this.shareResponse = share;
        this.createShareLink();
        this.copyToClipBoard();
        this.successFullySubmitted = true;
      },
      (error) => {
        this.errorWhileSharing = true;
      }
    );
  }

  showCreateShareForm() {
    this.createFormVisible = true;
  }

  private createShareLink(sharingId?: string) {
    const baseUrl = window.location.origin;
    const shareId = sharingId ? sharingId : this.shareResponse?.sharingId;
    if (shareId) {
      this.shareLink = baseUrl + this.mapPortalUrl + shareId;
      return this.shareLink;
    }
  }

  deleteLink(sharingId?: string) {
    this.shareService.deleteShare(sharingId).then(() => {
      this.loadSharesForCampaign(this.campaignDetails.id);
    });
  }

  private populatePOIIdentities(): POIIdentities {
    const poiIdentities: POIIdentities = new POIIdentities();

    if (this.inclusionExclusionData?.inclusivePOIs) {
      poiIdentities.inclusions = this.inclusionExclusionData.inclusivePOIs.map((f) => this.convertToPOIIdentity(f));
    }

    if (this.inclusionExclusionData?.exclusivePOIs) {
      poiIdentities.exclusions = this.inclusionExclusionData.exclusivePOIs.map((f) => this.convertToPOIIdentity(f));
    }

    if (this.customPoiInclusionExclusionData?.inclusivePOIs) {
      poiIdentities.customInclusions = this.getUniqueCustomPOISetIdenties(
        this.customPoiInclusionExclusionData?.inclusivePOIs.map((f) => this.convertToCustomPOISetIdentity(f))
      );
    }

    if (this.customPoiInclusionExclusionData?.exclusivePOIs) {
      poiIdentities.customExclusions = this.getUniqueCustomPOISetIdenties(
        this.customPoiInclusionExclusionData?.exclusivePOIs.map((f) => this.convertToCustomPOISetIdentity(f))
      );
    }

    return poiIdentities;
  }

  private getUniqueCustomPOISetIdenties(pois: Array<CustomPOISetIdentity>): Array<CustomPOISetIdentity> {
    const uniqueCustomPOISet = [];
    const uniqueCustomPOISetReferences = [];
    pois.forEach((poi) => {
      if (!uniqueCustomPOISetReferences.includes(poi.referenceNumber)) {
        uniqueCustomPOISetReferences.push(poi.referenceNumber);
        uniqueCustomPOISet.push(poi);
      }
    });
    return uniqueCustomPOISet;
  }

  private convertToPOIIdentity(poi: PointOfInterest) {
    const poiIdentity: POIIdentity = new POIIdentity();
    poiIdentity.poiType = poi.poiType;
    poiIdentity.referenceNumber = poi.referenceNumber;
    poiIdentity.distance = poi.distance;
    poiIdentity.iconUrl = poi.iconUrl;
    poiIdentity.brand = poi.brand;
    poiIdentity.name = poi.name;
    return poiIdentity;
  }

  private convertToCustomPOISetIdentity(poi: PointOfInterest): CustomPOISetIdentity {
    const poiIdentity: CustomPOISetIdentity = new CustomPOISetIdentity();
    poiIdentity.poiType = poi.poiType;
    poiIdentity.referenceNumber = poi.referenceNumber;
    poiIdentity.distance = poi.distance;
    return poiIdentity;
  }

  copyToClipBoard() {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = this.shareLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  removeShare() {
    this.shareLink = "";
    this.submitted = false;
    this.successFullySubmitted = false;
    this.errorWhileSharing = false;
  }

  reset() {
    this.removeShare();
    this.frameIds = [];
    this.disableShareButton = true;
    this.sharePopUpOpened = false;
  }

  togglePopUpVisibility() {
    this.sharePopUpOpened = !this.sharePopUpOpened;
    if (this.sharePopUpOpened) {
      this.populateCampaignData();
    }
  }

  populateCampaignData() {
    if (this.campaignDetails) {
      this.loadSharesForCampaign(this.campaignDetails.id);

      this.shareForm.controls.campaignName.setValue(this.campaignDetails.campaignName);
      this.shareForm.controls.mediaAgency.setValue(
        this.agencies.find((agency) => agency.code === this.campaignDetails.mediaAgencyCode)
      );
      this.onAgencyChange({ source: { selected: true, value: this.shareForm.controls.mediaAgency.value } });
      this.shareForm.controls.client.setValue(
        this.clients.find((client) => client.code === this.campaignDetails.clientCode)
      );
    } else {
      this.createFormVisible = true;
    }
  }

  loadSharesForCampaign(campaignId: string) {
    this.shareLink = undefined;
    this.loadingShares = true;
    this.successFullySubmitted = false;
    this.errorWhileSharing = false;
    this.createFormVisible = false;
    this.shareService.getSharesForCampaign(campaignId).then((shares) => {
      this.shares = shares.sort((a, b) => new Date(b.creationDate)?.getTime() - new Date(a.creationDate)?.getTime());
      this.loadingShares = false;
    });
  }

  subscibeToMapShareFeatureToggle() {
    const featureName = "DISABLE_MAP_SHARE";
    this.featureToggleService.isFeatureEnabled(featureName)?.subscribe((data) => {
      this.disableMapShare = data;
    });
  }

  showCampaignInputs() {
    return !this.campaignDetails;
  }

  allowDelete(link: Share) {
    return link?.from?.userEmail === this.userEmail;
  }
}
