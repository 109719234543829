import * as mapboxgl from "mapbox-gl";
import { Injectable } from "@angular/core";
import { MapEventObserver } from "src/app/core/svc/resource-svc/map/map.event.observer";
import { MapEventTypes } from "../models/MapEventTypes";

@Injectable({
  providedIn: "root",
})
export class MapEventHandler {
  private map: mapboxgl.Map;
  private mapEventObserver: MapEventObserver;
  private showPOIStatus = false;
  private readonly ZOOM_LEVEL_TO_DISPLAY_POI = 14;

  public setUp(map: mapboxgl.Map) {
    this.map = map;
    this.onZoom();
    this.onDrag();
  }

  constructor(mapEventObserver: MapEventObserver) {
    this.mapEventObserver = mapEventObserver;
  }

  private onZoom() {
    this.map.on("zoomend", () => {
      if (this.isZoomLevelMoreThanThreshold() && !this.showPOIStatus) {
        this.triggerZoomInPOIEvent();
      } else if (!this.isZoomLevelMoreThanThreshold() && this.showPOIStatus) {
        this.mapEventObserver.setFetchAssociatedPOIData(MapEventTypes.ZOOMOUT, false);
        this.showPOIStatus = false;
      }
    });
  }

  public triggerZoomInPOIEvent() {
    this.mapEventObserver.setFetchAssociatedPOIData(MapEventTypes.ZOOMIN, true);
    this.showPOIStatus = true;
  }

  private onDrag() {
    this.map.on("dragend", () => {
      if (this.isZoomLevelMoreThanThreshold()) {
        this.mapEventObserver.setFetchAssociatedPOIData(MapEventTypes.DRAG, true);
      }
    });
  }

  isZoomLevelMoreThanThreshold() {
    return this.map.getZoom() >= this.ZOOM_LEVEL_TO_DISPLAY_POI;
  }
}
