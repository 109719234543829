import * as mapboxgl from "mapbox-gl";
import { Injectable } from "@angular/core";
import { toggleLayer } from "./map-box-layers-utils";
import { FRAME_SOURCE_ID } from "./frame-layers-manager";

export const AUDIENCE_INDEX_CIRCLE_LAYER_ID = "audienceIndexLayer";
export const BELOW_AVERAGE_INDEX_CIRCLE_STROKE_COLOR = "#C43947";
export const AVERAGE_INDEX_CIRCLE_STROKE_COLOR = "#D87803";
export const ABOVE_AVERAGE_INDEX_CIRCLE_STROKE_COLOR = "#319D75";
export const BELOW_AVERAGE_INDEX_CIRCLE_FILL_COLOR = "#D5737C";
export const AVERAGE_INDEX_CIRCLE_FILL_COLOR = "#EABA80";
export const ABOVE_AVERAGE_INDEX_CIRCLE_FILL_COLOR = "#80C4AB";
export const AUDIENCE_INDEX_THRESHOLD_AVERAGE = 80;
export const AUDIENCE_INDEX_THRESHOLD_ABOVE_AVERAGE = 120;

@Injectable({
  providedIn: "root",
})
export class AudienceIndexLayerManager {
  private readonly SMALL_CIRCLE_LAYER_MAX_ZOOM = 14;
  private readonly SMALL_CIRCLE_RADIUS = 12;
  private readonly SMALL_CIRCLE_WIDTH = 4;

  private readonly BIG_CIRCLE_RADIUS = 28;
  private readonly BIG_CIRCLE_WIDTH = 6;

  public addLayers(map: mapboxgl.Map) {
    this.addCircleLayer(map);
  }

  private getCirclePaintPropertiesWithStroke() {
    return {
      "circle-opacity": 0.8,
      "circle-radius": [
        "step",
        ["zoom"],
        this.SMALL_CIRCLE_RADIUS,
        this.SMALL_CIRCLE_LAYER_MAX_ZOOM,
        this.BIG_CIRCLE_RADIUS,
      ],
      "circle-stroke-width": [
        "step",
        ["zoom"],
        this.SMALL_CIRCLE_WIDTH,
        this.SMALL_CIRCLE_LAYER_MAX_ZOOM,
        this.BIG_CIRCLE_WIDTH,
      ],
      "circle-stroke-color": this.getColorExpression(),
      "circle-stroke-opacity": 1,
      "circle-color": this.getFillColorExpression(),
    };
  }

  getLayerIds() {
    return [AUDIENCE_INDEX_CIRCLE_LAYER_ID];
  }

  toggleAudienceIndex(map: mapboxgl.Map) {
    this.getLayerIds().forEach((layer) => toggleLayer(layer, map));
  }

  private getColorExpression() {
    const rawValue = ["number", ["get", "audienceIndex"], 0];
    const aboveAverage = [">=", rawValue, AUDIENCE_INDEX_THRESHOLD_ABOVE_AVERAGE];
    const average = [">=", rawValue, AUDIENCE_INDEX_THRESHOLD_AVERAGE];

    return [
      "case",
      aboveAverage,
      ABOVE_AVERAGE_INDEX_CIRCLE_STROKE_COLOR,
      average,
      AVERAGE_INDEX_CIRCLE_STROKE_COLOR,
      BELOW_AVERAGE_INDEX_CIRCLE_STROKE_COLOR,
    ];
  }

  private getFillColorExpression() {
    const rawValue = ["number", ["get", "audienceIndex"], 0];
    return [
      "step",
      rawValue,
      BELOW_AVERAGE_INDEX_CIRCLE_FILL_COLOR,
      AUDIENCE_INDEX_THRESHOLD_AVERAGE,
      AVERAGE_INDEX_CIRCLE_FILL_COLOR,
      AUDIENCE_INDEX_THRESHOLD_ABOVE_AVERAGE,
      ABOVE_AVERAGE_INDEX_CIRCLE_FILL_COLOR,
    ];
  }

  addCircleLayer(map: mapboxgl.Map) {
    const source = FRAME_SOURCE_ID;
    map.addLayer({
      id: AUDIENCE_INDEX_CIRCLE_LAYER_ID,
      type: "circle",
      layout: { "circle-sort-key": ["get", "audienceIndex"] },
      source,
      paint: this.getCirclePaintPropertiesWithStroke(),
    });
  }

  filterNullAudienceIndex(map: mapboxgl.Map, additionalFilters?: any[]) {
    const baseFilterExpression = additionalFilters ? ["all", ...additionalFilters] : ["all"];
    this.getLayerIds().forEach((layer) => {
      map.setFilter(layer, [...baseFilterExpression, [">", ["number", ["get", "audienceIndex"], 0], 0]]);
    });
  }
}
