import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { RouterModule } from "@angular/router";
import { InputComponent } from "./input/input.component";
import { SelectComponent } from "./select/select.component";
import { MultiSelectComponent } from "./multi-select/multi-select.component";
import { MatIconModule } from "@angular/material/icon";
import { DaysOfWeekSelectorComponent } from "./days-of-week-selector/days-of-week-selector.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { CampaignBreadcrumbComponent } from "./campaign-breadcrumb/campaign-breadcrumb.component";
import {
  PlatoDatePipe,
  PlatoDatePipeUTC,
  PlatoEarliestTimezoneDatePipe,
  PlatoLatestTimezoneDatePipe,
  PlatoDateTimePipe,
} from "../common/plato-date-pipe";
import { CopyLinkComponent } from "./copy-link/copy-link.component";

@NgModule({
  declarations: [
    InputComponent,
    SelectComponent,
    MultiSelectComponent,
    CampaignBreadcrumbComponent,
    PlatoDatePipe,
    PlatoDateTimePipe,
    PlatoDatePipeUTC,
    PlatoEarliestTimezoneDatePipe,
    PlatoLatestTimezoneDatePipe,
    DaysOfWeekSelectorComponent,
    CopyLinkComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    RouterModule,
  ],
  exports: [
    InputComponent,
    SelectComponent,
    MultiSelectComponent,
    CampaignBreadcrumbComponent,
    PlatoDatePipe,
    PlatoDateTimePipe,
    PlatoDatePipeUTC,
    PlatoEarliestTimezoneDatePipe,
    PlatoLatestTimezoneDatePipe,
    DaysOfWeekSelectorComponent,
    CopyLinkComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ComponentsModule {}
