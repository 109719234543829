import { Injectable } from "@angular/core";
import { ResourceService } from "../resource-service.service";
import { ApiService } from "../../api/api.service";

@Injectable({
  providedIn: "root",
})
export class CurrencyService extends ResourceService<any> {
  constructor(apiCall: ApiService) {
    super(apiCall, "/inventory/currency");
  }
}
