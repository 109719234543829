import * as mapboxgl from "mapbox-gl";
import { Injectable } from "@angular/core";
import { ConfigurationService } from "src/app/core/svc/configuration-service";
import { Region } from "src/app/core/models/domain/RegionFeaturesConfig";
import { setPopup } from "./mapbox-utils";

const environment = new ConfigurationService().getConfig();

interface MapboxBoundaryMetadata {
  vectorSourceId: string;
  vectorSourceUrl: string;
  sourceLayerId: string;
  level: string;
  styleLayerId: string;
  borderStyleLayerId: string;
}
@Injectable({
  providedIn: "root",
})
export class MapboxBoundaryLayersManager {
  listMapboxBoundaryMetadata: MapboxBoundaryMetadata[];
  listOfUnitCodes: string[] = [];
  isVisible = false;

  constructor() {
    this.listMapboxBoundaryMetadata = this.setupMapBoxBoundaryMetadataAsPerRegion();
  }

  private setupMapBoxBoundaryMetadataAsPerRegion() {
    switch (environment.region) {
      case Region.US:
      case Region.CA:
        return [3, 4].map(this.createMapboxBoundaryMetadataForLevel);
      default:
        return [1, 2, 3, 4].map(this.createMapboxBoundaryMetadataForLevel);
    }
  }

  private createMapboxBoundaryMetadataForLevel(level: number) {
    return {
      vectorSourceId: `boundaries_postal_source_${level}`,
      vectorSourceUrl: `mapbox://mapbox.enterprise-boundaries-p${level}-v2`,
      sourceLayerId: `boundaries_postal_${level}`,
      level: `P${level}`,
      styleLayerId: `postal_boundaries_style_layer_${level}`,
      borderStyleLayerId: `postal_boundaries_border_style_layer_${level}`,
    };
  }

  public updateHighlightBoundariesFor(map: mapboxgl.Map, listOfUnitCodes: string[]) {
    this.listOfUnitCodes = listOfUnitCodes;
    if (this.isVisible) {
      this.showHighlightBoundariesFor(map);
    }
  }

  public showHighlightBoundariesFor(map: mapboxgl.Map) {
    this.isVisible = true;
    this.listMapboxBoundaryMetadata.forEach((metadata) => {
      map.setFilter(metadata.styleLayerId, [
        "in",
        "id",
        ...this.listOfUnitCodes.map(
          (unitCode) => `${environment.mapBoxCountryId}${metadata.level}${unitCode.replace(" ", "")}`
        ),
      ]);
    });

    this.listMapboxBoundaryMetadata.forEach((metadata) => {
      map.setFilter(metadata.borderStyleLayerId, [
        "in",
        "id",
        ...this.listOfUnitCodes.map(
          (unitCode) => `${environment.mapBoxCountryId}${metadata.level}${unitCode.replace(" ", "")}`
        ),
      ]);
    });
  }

  public resetHighlightedFilter(map: mapboxgl.Map) {
    this.isVisible = false;
    this.listMapboxBoundaryMetadata.forEach((metadata) => {
      map.setFilter(metadata.styleLayerId, ["in", "id", ...[]]);
    });

    this.listMapboxBoundaryMetadata.forEach((metadata) => {
      map.setFilter(metadata.borderStyleLayerId, ["in", "id", ...[]]);
    });
  }

  private addMapboxBoundaryVectorSource(map: mapboxgl.Map, metadata: MapboxBoundaryMetadata) {
    map.addSource(metadata.vectorSourceId, {
      type: "vector",
      url: metadata.vectorSourceUrl,
    });
  }

  private addMapboxBoundaryStyleLayer(map: mapboxgl.Map, metadata: MapboxBoundaryMetadata) {
    map.addLayer({
      id: metadata.styleLayerId,
      type: "fill",
      source: metadata.vectorSourceId,
      "source-layer": metadata.sourceLayerId,
      paint: {
        "fill-color": "#ffcc00",
        "fill-outline-color": "#1777A7",
        "fill-opacity": 0.25,
      },
      filter: ["in", "id", ...[]],
    });

    map.addLayer({
      id: metadata.borderStyleLayerId,
      type: "line",
      source: metadata.vectorSourceId,
      "source-layer": metadata.sourceLayerId,
      paint: {
        "line-color": "#1777A7",
        "line-width": 1,
      },
      filter: ["in", "id", ...[]],
    });
  }

  public setupMapboxBoundaryLayers(map: mapboxgl.Map) {
    this.listMapboxBoundaryMetadata.forEach((metadata) => {
      this.addMapboxBoundaryVectorSource(map, metadata);
      this.addMapboxBoundaryStyleLayer(map, metadata);
      setPopup(
        map,
        metadata.styleLayerId,
        (properties) => properties["id"]?.substring(4),
        "top-left",
        "region-mapbox-popup"
      );
    });
  }
}
