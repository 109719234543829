import { Component } from "@angular/core";
import { Region } from "src/app/core/models/domain/RegionFeaturesConfig";
import { ConfigurationService } from "src/app/core/svc/configuration-service";
import { UserService } from "src/app/core/svc/resource-svc/user/user.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-regions",
  templateUrl: "./regions.component.html",
})
export class RegionsComponent {
  regions: Array<Region>;
  selectedRegion: Region;
  private readonly DEFAULT_HOME_PAGE = "/";

  constructor(private configurationService: ConfigurationService, userService: UserService, private router: Router) {
    this.regions = userService.supportedRegions();
    this.selectedRegion = userService.getRegion();
  }

  changeRegion(region: Region) {
    this.configurationService.setCurrentRegion(region?.toString());
    this.router.navigate([this.DEFAULT_HOME_PAGE]);
  }
}
