import { Injectable } from "@angular/core";
import { ApiService } from "../../api/api.service";
import { ResourceService } from "../resource-service.service";
import { Campaign } from "src/app/core/models/domain/Campaign";
import { BookingRequest, CancelRequest } from "src/app/core/models/domain/BookingRequest";
import { timeout } from "rxjs/internal/operators/timeout";

@Injectable({
  providedIn: "root",
})
export class CampaignBookingService extends ResourceService<Campaign> {
  constructor(apiCall: ApiService) {
    super(apiCall, "/inventory/campaigns");
  }

  private _timeoutValue = 300000; //in milliseconds

  prepareForOption(campaignId: string, body: BookingRequest) {
    return this.apiCall
      .post(`/inventory/campaigns/${campaignId}/booking?type=prepare`, body, {
        "Content-type": "application/json",
      })
      .pipe(timeout(this._timeoutValue));
  }

  optionOrderLine(campaignId: string, body: BookingRequest) {
    return this.apiCall
      .post(`/inventory/campaigns/${campaignId}/booking?type=reserve`, body, {
        "Content-type": "application/json",
      })
      .pipe(timeout(this._timeoutValue));
  }

  bookOrderLine(campaignId: string, body: BookingRequest) {
    return this.apiCall
      .post(`/inventory/campaigns/${campaignId}/booking?type=book`, body, {
        "Content-type": "application/json",
      })
      .pipe(timeout(this._timeoutValue));
  }

  cancelOrderLine(campaignId: string, body: CancelRequest) {
    return this.apiCall
      .post(`/inventory/campaigns/${campaignId}/cancel`, body, {
        "Content-type": "application/json",
      })
      .pipe(timeout(this._timeoutValue));
  }

  refreshCampaignBookingInfo(campaignId: string, body: BookingRequest = {} as BookingRequest) {
    return this.apiCall
      .post(`/inventory/campaigns/${campaignId}/refresh-booking`, body, {
        "Content-type": "application/json",
      })
      .pipe(timeout(this._timeoutValue));
  }

  revokeServiceRequest(campaignId: string, body: BookingRequest) {
    return this.apiCall
      .post(`/inventory/campaigns/${campaignId}/booking?type=revoke`, body, {
        "Content-type": "application/json",
      })
      .pipe(timeout(this._timeoutValue));
  }

  requestPrice(campaignId: string, body: BookingRequest) {
    return this.apiCall
      .post(`/inventory/campaigns/${campaignId}/booking?type=request_price`, body, {
        "Content-type": "application/json",
      })
      .pipe(timeout(this._timeoutValue));
  }

  requestAvails(campaignId: string, body: BookingRequest) {
    return this.apiCall
      .post(`/inventory/campaigns/${campaignId}/booking?type=request_avails`, body, {
        "Content-type": "application/json",
      })
      .pipe(timeout(this._timeoutValue));
  }
}
