import * as mapboxgl from "mapbox-gl";
import { Injectable } from "@angular/core";
import { applyFilter, hideLayers, setPopup, showLayers } from "./mapbox-utils";

@Injectable({
  providedIn: "root",
})
export class DmaBoundaryLayersManager {
  private DMA_BOUNDARIES_TILESET_SOURCE = "ada_web_dma_boundary-5xlcfy";
  private TILESET_ID = "talonodmp.c3u449hu";
  private DMA_LAYER_ID = "DMA boundaries";
  private DMA_STROKE_LAYER_ID = this.DMA_LAYER_ID + "-stroke";
  private dmaLayers = [this.DMA_LAYER_ID, this.DMA_STROKE_LAYER_ID];

  public addAllDmaBoundariesUS(map: mapboxgl.Map) {
    map.addSource(this.DMA_BOUNDARIES_TILESET_SOURCE, {
      type: "vector",
      url: "mapbox://" + this.TILESET_ID,
    });
    map.addLayer({
      id: this.DMA_LAYER_ID,
      type: "fill",
      source: this.DMA_BOUNDARIES_TILESET_SOURCE,
      "source-layer": this.DMA_BOUNDARIES_TILESET_SOURCE,
      layout: {},
      paint: {
        "fill-outline-color": "#FF30B1",
        "fill-color": "#d4d4d4",
        "fill-opacity": 0.4,
      },
    });
    map.addLayer({
      id: this.DMA_STROKE_LAYER_ID,
      type: "line",
      source: this.DMA_BOUNDARIES_TILESET_SOURCE,
      "source-layer": this.DMA_BOUNDARIES_TILESET_SOURCE,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#FF30B1",
        "line-width": 2,
      },
    });
    setPopup(map, this.DMA_LAYER_ID, (properties) => properties["geoEntityLongId"], "bottom-left");
    hideLayers(map, this.dmaLayers);
  }

  public toggleAllDmaBoundariesUSvisibility(map: mapboxgl.Map) {
    const visibility = map.getLayoutProperty(this.DMA_LAYER_ID, "visibility", "none");
    if (visibility === "visible") {
      hideLayers(map, this.dmaLayers);
    } else {
      showLayers(map, this.dmaLayers);
    }
  }

  public updateSelectedDmas(map: mapboxgl.Map, selectedDmas: Array<string>) {
    const filter = ["in", "geoEntityLongId", ...selectedDmas];
    if (selectedDmas.length > 0) {
      applyFilter(map, filter, this.dmaLayers);
    } else {
      this.resetDMAFilters(map);
    }
  }

  public resetDMAFilters(map: mapboxgl.Map) {
    applyFilter(map, [], this.dmaLayers);
  }
}
