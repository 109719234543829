<div class="multi-select-container">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    panelClass="multi-select-options-panel"
    multiple
    [(ngModel)]="selectedItems"
    disableOptionCentering="true"
    (selectionChange)="onSelectionChange($event)"
  >
    <mat-option value="All" (click)="allClicked()">All</mat-option>
    <mat-option *ngFor="let item of items" [value]="item">
      {{ item }}
    </mat-option>
    <mat-select-trigger>
      <div class="item-count-contaier">
        <div class="item-count">{{ getTotalSelectedExceptAll() }}</div>
      </div>
    </mat-select-trigger>
  </mat-select>
</div>
