import { Injectable } from "@angular/core";
import { ResourceService } from "../resource-service.service";
import { Share as Share } from "src/app/core/models/domain/Share";
import { ApiService } from "../../api/api.service";
import { Observable } from "rxjs";
import { timeout } from "rxjs/internal/operators/timeout";

@Injectable({
  providedIn: "root",
})
export class ShareService extends ResourceService<Share> {
  constructor(apiCall: ApiService) {
    super(apiCall, "/inventory/shares");
  }

  private _timeoutValue_millis = 300000;

  share(share: Share): Observable<Share> {
    return this.apiCall
      .post(this.endpoint, share, {
        "Content-type": "application/json",
      })
      .pipe(timeout(this._timeoutValue_millis));
  }

  getShares(): Promise<Share[]> {
    return this.apiCall.get(this.endpoint).toPromise();
  }

  getSharesForCampaign(campaignId: string): Promise<Share[]> {
    return this.apiCall.get(`${this.endpoint}?campaign_id=${campaignId}`).toPromise();
  }

  deleteShare(sharingId: string): Promise<any> {
    return this.apiCall.delete(`${this.endpoint}/${sharingId}`).toPromise();
  }
}
