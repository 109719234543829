<div class="map-view-container">
  <div class="mapboxgl-map" id="map"></div>
  <div class="map-view-footer" *ngIf="poiBoundaryRenderingInprogress">
    <div class="loader">Loading</div>
  </div>
  <ng-content></ng-content>
  <app-prohibition-boundaries-toggle></app-prohibition-boundaries-toggle>

  <div class="map-view-share-footer">
    <app-map-share
      [geoLocationSearchCriterion]="geoLocationSearchCriterion"
      [frameIds]="shareFrameIds"
      [inclusionExclusionData]="inclusionExclusionData"
      [customPoiInclusionExclusionData]="customPoiInclusionExclusionData"
      [campaignDetails]="campaignDetails"
    >
    </app-map-share>
  </div>
  <div class="map-view-filter" *ngIf="showCombinedFilters">
    <plato-map-filter
      [filters]="supportedFilters"
      [captions]="mapCaptions"
      (filterAppliedEvent)="onFilterAppliedEvent($event)"
      [showSearchMenu]="false"
      [showAudienceFilter]="showAudienceFilter"
      [audience]="audienceFilterRange"
    >
    </plato-map-filter>
  </div>

  <div style="display: none">
    <plato-map-frame-details-popup
      id="frameDetailsRef"
      [frameDetails]="frameDetails"
      [lineDetails]="lineDetails"
      [popupConfig]="popupConfig"
      [region]="region"
      [captions]="mapCaptions"
    ></plato-map-frame-details-popup>
  </div>
  <div class="legends">
    <div class="frame-legend">
      <plato-map-legend
        [legendLabelAndColorMapping]="framesLegendColorCodeMapping"
        [title]="frameLegendTitle"
        [formatCaption]="captions.format"
        [shouldShowLegend]="shouldShowFrameLegend"
        [isFrameLegend]="shouldShowColoredCircleForFrameLegend"
        [legendsCallbackData]="mapLegendsCallbacks"
        [showLegendSelectionForFrames]="true"
      >
      </plato-map-legend>
    </div>
    <div class="poi-legend">
      <plato-map-legend
        [legendLabelAndColorMapping]="poiFormatColorCodeMapping"
        [title]="poiLegendTitle"
        [shouldShowLegend]="shouldShowPOILegend"
        [isFrameLegend]="shouldShowColoredCircleForPOILegend"
        [legendsCallbackData]="mapLegendsCallbacks"
        [showLegendSelectionForFrames]="false"
      >
      </plato-map-legend>
    </div>
  </div>
</div>
<div class="summary-bar" *ngIf="!summaryClosed">
  <app-map-summary
    [frames]="allFrames"
    [region]="region"
    [showingSelected]="showingSelected"
    [totalFrames]="total"
    [numberOfFramesFetched]="numberOfFramesFetched"
  ></app-map-summary>
</div>
