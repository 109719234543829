import { POIGeoBoundaryInfo } from "../model/POIGeoBoundaryInfo";
import { ProhibitionPOIIdentity } from "../model/ProhibitionPOIIdentity";
import { BoundaryApiService } from "./api/boundary-api-service";
import { CacheProvider } from "./cache/cache-provider";
import { Injectable } from "@angular/core";
import { logTimeDifference, LOG_MESSAGES, logMessage } from "../logger";

@Injectable({
  providedIn: "root",
})
export class DataProvider {
  constructor(private cacheProvider: CacheProvider, private boundaryApiService: BoundaryApiService) {}

  async fetchBoundaries(queryPOIIds: ProhibitionPOIIdentity[], baseUrl: string): Promise<POIGeoBoundaryInfo[]> {
    const boundariesFromCache = await this.fetchFromCache(queryPOIIds);
    const missingPOIIds = this.findMissingIds(boundariesFromCache, queryPOIIds);
    if (missingPOIIds.length > 0) {
      const boundariesFromAPI = await this.fetchDataFromAPI(missingPOIIds, baseUrl);
      this.cacheProvider.updateCache(boundariesFromAPI);
      return [...boundariesFromCache, ...boundariesFromAPI].filter((boundry) => boundry.geoBoundaryInfo.type != null);
    }
    return [...boundariesFromCache].filter((boundry) => boundry.geoBoundaryInfo.type != null);
  }

  private async fetchDataFromAPI(missingPOIIds: ProhibitionPOIIdentity[], baseUrl: string) {
    const dataFromApi = await this.boundaryApiService.fetchBoundaryData(missingPOIIds, baseUrl);
    return dataFromApi.POIsGeoBoundaryInfo;
  }

  private async fetchFromCache(queryPOIIds: ProhibitionPOIIdentity[]) {
    const fetchCacheStartTime = Date.now();
    const poiBoundariesFromCache = await this.cacheProvider.getBoundaries(queryPOIIds);
    logTimeDifference(fetchCacheStartTime, LOG_MESSAGES.TIME_TAKEN_TO_FETCH_FROM_CACHE);
    logMessage(poiBoundariesFromCache.length + "/" + queryPOIIds.length + " boundaries fetched from cache");
    return poiBoundariesFromCache;
  }

  private findMissingIds(poiBoundariesFromCache: POIGeoBoundaryInfo[], queryPOIIds: ProhibitionPOIIdentity[]) {
    const poiIds = poiBoundariesFromCache.map((poi) => poi.poiIdentity);
    return queryPOIIds.filter((id) => !this.existsInCache(poiIds, id));
  }

  private existsInCache(cacheIds: ProhibitionPOIIdentity[], id: ProhibitionPOIIdentity) {
    let isFound = false;
    cacheIds.forEach((inputId) => {
      if (id.referenceNumber === inputId.referenceNumber && id.sourceSystem === inputId.sourceSystem) {
        isFound = true;
      }
    });
    return isFound;
  }
}
