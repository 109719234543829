import { Injectable } from "@angular/core";
import Dexie from "dexie";
import {
  GEO_BOUNDARY_INFO_TABLE_NAME,
  POI_GEOBOUNDARY_INFO_DETAILS_DB_NAME,
  POI_GEOBOUNDARY_INFO_TABLE_NAME,
} from "./db-constants";

@Injectable({
  providedIn: "root",
})
export class DataPersistenceService {
  private dbHandler: Dexie;
  async establishConnection() {
    if (!("indexedDB" in window)) {
      throw new Error("IndexedDB is not available for this browser");
    }

    this.dbHandler = new Dexie(POI_GEOBOUNDARY_INFO_DETAILS_DB_NAME);
    this.dbHandler.version(1).stores({
      POIGeoboundaryInfo: "poiId,lastAccessedTimestamp",
      GeoBoundaryInfo: "&hashedEnhancedLocation",
    });
  }

  async deleteTables() {
    this.dbHandler.table(GEO_BOUNDARY_INFO_TABLE_NAME).clear();
    this.dbHandler.table(POI_GEOBOUNDARY_INFO_TABLE_NAME).clear();
  }

  async createOrUpdateData(tableName: string, data: any) {
    this.dbHandler.table(tableName).bulkPut(data);
  }

  async getDataByIds(tableName: string, keyPath: string, keys: string[]) {
    let res = [];
    await this.dbHandler
      .table(tableName)
      .where(keyPath)
      .anyOf(keys)
      .toArray((data) => (res = data));
    return res;
  }

  async removeConnection() {
    this.dbHandler.close();
  }
}
