<div *ngIf="!disableMapShare">
  <div class="share-text-area" *ngIf="sharePopUpOpened">
    <div class="share-map-view">
      <div class="header">
        <div><img src="../../../../assets/images/share.svg" class="img-16 share-icon-svg" /> Share Map View</div>
        <mat-icon class="close-icon" (click)="togglePopUpVisibility()">close</mat-icon>
      </div>
      <div>
        <div class="recent-links" *ngIf="!createFormVisible">
          <div class="title">Recent map share links</div>
          <div *ngFor="let link of shares">
            <div class="link">
              <div class="link-details">
                <span class="link-title">{{ link.description }}</span>
                <span class="link-subtitle">Created by {{ link.from?.userName }}</span>
              </div>
              <span class="link-created-at">{{ link.createdAt | platoDatePipeUTC }}</span>
              <app-copy-link [link]="createShareLink(link.sharingId)"></app-copy-link>
              <span class="delete-icon">
                <mat-icon *ngIf="allowDelete(link)" (click)="deleteLink(link.sharingId)" class="link-icon"
                  >delete</mat-icon
                >
                <mat-icon *ngIf="!allowDelete(link)" class="link-icon disabled">delete</mat-icon>
              </span>
            </div>
          </div>
        </div>
        <div class="footer">
          <button class="new-link primary-btn" *ngIf="!createFormVisible" (click)="showCreateShareForm()">
            Create New Link
          </button>
        </div>
      </div>

      <div class="share-map-view-form" *ngIf="createFormVisible && !(successFullySubmitted || errorWhileSharing)">
        <form [formGroup]="shareForm">
          <div class="mandatory-star form-group">
            <input
              type="text"
              class="form-control"
              placeholder=" "
              formControlName="description"
              [ngClass]="{ 'is-invalid': submitted && shareForm.controls.description.errors }"
            />
            <label for="inputCity">Description</label>
            <span class="error-msg" *ngIf="submitted && shareForm.controls.description.errors"
              >Please add a description</span
            >
          </div>
          <div *ngIf="showCampaignInputs()">
            <div class="mandatory-star form-group">
              <input
                type="text"
                class="form-control"
                placeholder=" "
                formControlName="campaignName"
                [ngClass]="{ 'is-invalid': submitted && shareForm.controls.campaignName.errors }"
              />
              <label for="inputCity">Campaign Name</label>
              <span class="error-msg" *ngIf="submitted && shareForm.controls.campaignName.errors"
                >Please select a campaign name</span
              >
            </div>
            <div class="mandatory-star form-group">
              <mat-form-field>
                <mat-select
                  placeholder="Agency"
                  name="food"
                  (selectionChange)="onAgencyChange($event)"
                  formControlName="mediaAgency"
                  [ngClass]="{ 'is-invalid': submitted && shareForm.controls.mediaAgency.errors }"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="agencyFilterCtrl"
                      [placeholderLabel]="captions.searchAgencyPlaceHolder"
                      [noEntriesFoundLabel]="'No Matching Agencies Found'"
                    >
                      <i ngxMatSelectSearchClear class="close-icon"
                        ><img src="../../../../assets/images/close.svg" class="img-16" alt=""
                      /></i>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let agency of agencies$ | async" [value]="agency">
                    {{ agency.name }}
                  </mat-option>
                </mat-select>
                <mat-label>Agency</mat-label>
              </mat-form-field>
              <span class="error-msg" *ngIf="submitted && shareForm.controls.mediaAgency.errors"
                >Please select a media agency name</span
              >
            </div>
            <div class="mandatory-star form-group">
              <mat-form-field>
                <mat-select
                  placeholder="Client"
                  formControlName="client"
                  [ngClass]="{ 'is-invalid': submitted && shareForm.controls.client.errors }"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="clientFilterCtrl"
                      [placeholderLabel]="captions.searchClientPlaceHolder"
                      [noEntriesFoundLabel]="'No Matching Clients Found'"
                    >
                      <i ngxMatSelectSearchClear class="close-icon"
                        ><img src="../../../../assets/images/close.svg" class="img-16" alt=""
                      /></i>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let client of clients$ | async" [value]="client">
                    {{ client.name }}
                  </mat-option>
                </mat-select>
                <mat-label>Client</mat-label>
              </mat-form-field>
              <span class="error-msg" *ngIf="submitted && shareForm.controls.client.errors"
                >Please select a client name</span
              >
            </div>
          </div>

          <mat-checkbox color="primary" [checked]="showPOICircle" (change)="setShowPOICircle($event.checked)">
            Show POI Circles
          </mat-checkbox>

          <div class="text-center">
            <button mat-raised-button class="get-link" color="primary" type="submit" (click)="share()">
              Save And Get Link
            </button>
          </div>
        </form>
      </div>

      <div class="share-map-view-success" *ngIf="successFullySubmitted">
        <img src="../../../../assets/images/verified-blue.svg" class="img-25" alt="" />
        <p>Map view saved to listing page</p>
        <p>Link copied to clipboard</p>
        <button class="primary-btn" (click)="copyToClipBoard()">Copy link again</button><br />
      </div>
      <div class="share-map-view-error" *ngIf="errorWhileSharing">
        <img src="../../../../assets/images/alert.svg" class="img-25" alt="" />
        <p>Oops! We cannot reach the server right now.</p>
        <p>Please retry Map Sharing a little later.</p>
      </div>
    </div>
  </div>
  <div class="share-icon" *ngIf="!disableShareButton">
    <a
      class="share-icon-pop-name"
      (click)="togglePopUpVisibility()"
      [matTooltip]="captions.shareResultTooltip"
      matTooltipClass="primary-tooltip"
      matTooltipPosition="left"
    >
      <img src="../../../../assets/images/share.svg" class="share-icon-svg" />
    </a>
  </div>
  <div class="share-icon" *ngIf="disableShareButton">
    <a class="share-icon-pop-disabled">
      <img src="../../../../assets/images/share.svg" class="share-icon-svg" />
    </a>
  </div>
</div>
