import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class APIService {
  constructor(private httpClient: HttpClient) {}

  post<T>(endpoint: string, object: T, headers?: any): Promise<any> {
    return this.makeRequest<T>("POST", endpoint, object, headers);
  }

  private makeRequest<T>(method: string, endpoint: string, body?: any, headers?: any): Promise<any> {
    return this.httpClient
      .request<T>(method, endpoint, {
        body: body,
        headers: headers,
      })
      .toPromise();
  }
}
