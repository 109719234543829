import { ProhibitionPOIIdentity } from "../../model/ProhibitionPOIIdentity";
import { Injectable } from "@angular/core";
import { DataPersistenceService } from "./data-persistence-service";
import { POI_GEOBOUNDARY_INFO_TABLE_NAME } from "./db-constants";
import { POIGeoboundaryInfo, GeoBoundaryInfo } from "../../model/schema";
import {
  KEY_PATH_NAME_FOR_POI_GEO_BOUNDARY_INFO,
  GEO_BOUNDARY_INFO_TABLE_NAME,
  KEY_PATH_NAME_FOR_GEO_BOUNDARY_INFO,
} from "./db-constants";
import { POIGeoBoundaryInfo } from "../../model/POIGeoBoundaryInfo";

@Injectable({
  providedIn: "root",
})
export class CacheProvider {
  constructor(private dataPersistentService: DataPersistenceService) {
    dataPersistentService.establishConnection();
  }
  async updateCache(dataFromApi: POIGeoBoundaryInfo[]) {
    const poiGeoBoundaryInfos: POIGeoboundaryInfo[] = [];
    const geoBoundaryInfos: GeoBoundaryInfo[] = [];

    dataFromApi.forEach((data) => {
      const poiGeoBoundaryInfo: POIGeoboundaryInfo = {
        hashedEnhancedLocation: data.hashOfEnhancedLocation,
        poiId: this.getStringBasedPOIId(data.poiIdentity),
        lastAccessedTimestamp: new Date().getTime(),
      };

      const geoBoundaryInfo: GeoBoundaryInfo = {
        hashedEnhancedLocation: data.hashOfEnhancedLocation,
        geoJson: data.geoBoundaryInfo,
      };

      poiGeoBoundaryInfos.push(poiGeoBoundaryInfo);
      geoBoundaryInfos.push(geoBoundaryInfo);
    });

    this.dataPersistentService.createOrUpdateData(POI_GEOBOUNDARY_INFO_TABLE_NAME, poiGeoBoundaryInfos);
    this.dataPersistentService.createOrUpdateData(GEO_BOUNDARY_INFO_TABLE_NAME, geoBoundaryInfos);
  }
  async getBoundaries(ids: ProhibitionPOIIdentity[]): Promise<POIGeoBoundaryInfo[]> {
    const poiIds = this.generateStringBasedPOIIds(ids);
    const poiGeoInfos: POIGeoboundaryInfo[] = await this.dataPersistentService.getDataByIds(
      POI_GEOBOUNDARY_INFO_TABLE_NAME,
      KEY_PATH_NAME_FOR_POI_GEO_BOUNDARY_INFO,
      poiIds
    );

    const hashedCoordinates = this.getHashedCoordinatesFromPOIGeoBoundaryInfo(poiGeoInfos);

    const geoBoundaryInfos: GeoBoundaryInfo[] = await this.dataPersistentService.getDataByIds(
      GEO_BOUNDARY_INFO_TABLE_NAME,
      KEY_PATH_NAME_FOR_GEO_BOUNDARY_INFO,
      hashedCoordinates
    );
    return this.generatePOIBoundaryInfo(poiGeoInfos, geoBoundaryInfos);
  }

  private generatePOIBoundaryInfo(poiGeoBoundaryInfos: POIGeoboundaryInfo[], geoBoundaryInfos: GeoBoundaryInfo[]) {
    const poiBoundaries: POIGeoBoundaryInfo[] = [];
    poiGeoBoundaryInfos.forEach((poiGeoBoundaryInfo) => {
      const hashedEnhancedLocation = poiGeoBoundaryInfo.hashedEnhancedLocation;
      const geoBoundaryInfoOfCurrentPOI: GeoBoundaryInfo = geoBoundaryInfos.find(
        (geoBoundaryInfo) => geoBoundaryInfo.hashedEnhancedLocation === hashedEnhancedLocation
      );
      const poiBoundary: POIGeoBoundaryInfo = {
        poiIdentity: this.getJSONBasedId(poiGeoBoundaryInfo.poiId),
        hashOfEnhancedLocation: hashedEnhancedLocation,
        geoBoundaryInfo: geoBoundaryInfoOfCurrentPOI.geoJson,
      };
      poiGeoBoundaryInfo.lastAccessedTimestamp = new Date().getTime();
      poiBoundaries.push(poiBoundary);
    });
    this.dataPersistentService.createOrUpdateData(POI_GEOBOUNDARY_INFO_TABLE_NAME, poiGeoBoundaryInfos);
    return poiBoundaries;
  }

  private getJSONBasedId(poiId: string): ProhibitionPOIIdentity {
    const data = poiId.split(":::");
    const poiIdentity: ProhibitionPOIIdentity = {
      referenceNumber: data[0],
      sourceSystem: data[1],
    };
    return poiIdentity;
  }

  private getHashedCoordinatesFromPOIGeoBoundaryInfo(poiGeoInfos: POIGeoboundaryInfo[]) {
    const hashedEnhancedLocations = poiGeoInfos.map((poiGeoBoundaryInfo) => poiGeoBoundaryInfo.hashedEnhancedLocation);
    return hashedEnhancedLocations;
  }
  private getStringBasedPOIId(id: ProhibitionPOIIdentity): string {
    return id.referenceNumber + ":::" + id.sourceSystem;
  }

  private generateStringBasedPOIIds(ids: ProhibitionPOIIdentity[]) {
    return ids.map((id) => this.getStringBasedPOIId(id));
  }
}
