import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CampaignTabs } from "../../../home/campaigns/edit-campaign/edit-campaign.component";

@Component({
  selector: "app-campaign-breadcrumb",
  templateUrl: "./campaign-breadcrumb.component.html",
  styleUrls: ["./campaign-breadcrumb.component.scss"],
})
export class CampaignBreadcrumbComponent {
  @Input() campaign: {
    id: string;
    campaignName: string;
    brandName: string;
  };
  @Input() title: string = "Campaign";
  @Input() showDates: boolean = false;
  @Input() showCampaignId: boolean = false;
  @Input() showBackToCampaign: boolean = false;

  @Output() backToCampaign = new EventEmitter();

  queryParamsForBackNavFromSearchToCampaign = { stepper: CampaignTabs.MEDIA_AND_COSTS };

  onBackClick() {
    this.backToCampaign.emit();
  }
}
