import * as mapboxgl from "mapbox-gl";
import { Injectable } from "@angular/core";
import { toggleLayer } from "./map-box-layers-utils";
import { FRAME_SOURCE_ID } from "./frame-layers-manager";
import {
  ABOVE_AVERAGE_INDEX_CIRCLE_FILL_COLOR,
  ABOVE_AVERAGE_INDEX_CIRCLE_STROKE_COLOR,
  AUDIENCE_INDEX_THRESHOLD_ABOVE_AVERAGE,
  AUDIENCE_INDEX_THRESHOLD_AVERAGE,
  AVERAGE_INDEX_CIRCLE_FILL_COLOR,
  AVERAGE_INDEX_CIRCLE_STROKE_COLOR,
  BELOW_AVERAGE_INDEX_CIRCLE_FILL_COLOR,
  BELOW_AVERAGE_INDEX_CIRCLE_STROKE_COLOR,
} from "./audience-index-layer-manager";

export const AUDIENCE_INDEX_CIRCLE_LAYER_ID = "audienceIndexHeatmapLayer";

@Injectable({
  providedIn: "root",
})
export class AudienceIndexHeatmapLayerManager {
  public addLayers(map: mapboxgl.Map) {
    this.addHeatmapLayers(map);
  }

  getLayerIds() {
    return [this.getBelowAverageLayerId(), this.getAverageLayerId(), this.getAboveAverageLayerId()];
  }

  private getAboveAverageLayerId() {
    return AUDIENCE_INDEX_CIRCLE_LAYER_ID + "_above_average";
  }

  private getAverageLayerId() {
    return AUDIENCE_INDEX_CIRCLE_LAYER_ID + "_average";
  }

  private getBelowAverageLayerId() {
    return AUDIENCE_INDEX_CIRCLE_LAYER_ID + "_below_average";
  }

  toggleAudienceIndex(map: mapboxgl.Map) {
    this.getLayerIds().forEach((layer) => toggleLayer(layer, map));
  }

  addHeatmapLayers(map: mapboxgl.Map) {
    const source = FRAME_SOURCE_ID;
    map.addLayer({
      id: this.getBelowAverageLayerId(),
      type: "heatmap",
      source,
      paint: this.getHeatmapPaintPropertiesWithStroke(
        BELOW_AVERAGE_INDEX_CIRCLE_FILL_COLOR,
        BELOW_AVERAGE_INDEX_CIRCLE_STROKE_COLOR
      ),
    });
    map.addLayer({
      id: this.getAverageLayerId(),
      type: "heatmap",
      source,
      paint: this.getHeatmapPaintPropertiesWithStroke(
        AVERAGE_INDEX_CIRCLE_FILL_COLOR,
        AVERAGE_INDEX_CIRCLE_STROKE_COLOR
      ),
    });
    map.addLayer({
      id: this.getAboveAverageLayerId(),
      type: "heatmap",
      source,
      paint: this.getHeatmapPaintPropertiesWithStroke(
        ABOVE_AVERAGE_INDEX_CIRCLE_FILL_COLOR,
        ABOVE_AVERAGE_INDEX_CIRCLE_STROKE_COLOR
      ),
    });
  }

  getHeatmapPaintPropertiesWithStroke(color1: string, color2: string) {
    return {
      "heatmap-weight": [
        "interpolate",
        ["linear"],
        ["number", ["get", "audienceIndex"], 0],
        0,
        0,
        AUDIENCE_INDEX_THRESHOLD_AVERAGE,
        0.5,
        AUDIENCE_INDEX_THRESHOLD_ABOVE_AVERAGE,
        1,
      ],
      "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 10, 5],
      "heatmap-color": [
        "interpolate",
        ["linear"],
        ["heatmap-density"],
        0,
        "rgba(33,102,172,0)",
        0.5,
        color1,
        1,
        color2,
      ],
      "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 2, 9, 20],
      "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 7, 1, 10, 0.7, 12, 0],
    };
  }

  filterNullAudienceIndex(map: mapboxgl.Map, additionalFilters?: any[]) {
    const baseFilterExpression = additionalFilters ? ["all", ...additionalFilters] : ["all"];
    map.setFilter(this.getBelowAverageLayerId(), [
      ...baseFilterExpression,
      [">", ["number", ["get", "audienceIndex"], 0], 0],
      ["<", ["number", ["get", "audienceIndex"], 0], AUDIENCE_INDEX_THRESHOLD_AVERAGE],
    ]);
    map.setFilter(this.getAverageLayerId(), [
      ...baseFilterExpression,
      [">=", ["number", ["get", "audienceIndex"], 0], AUDIENCE_INDEX_THRESHOLD_AVERAGE],
      ["<", ["number", ["get", "audienceIndex"], 0], AUDIENCE_INDEX_THRESHOLD_ABOVE_AVERAGE],
    ]);
    map.setFilter(this.getAboveAverageLayerId(), [
      ...baseFilterExpression,
      [">=", ["number", ["get", "audienceIndex"], 0], AUDIENCE_INDEX_THRESHOLD_ABOVE_AVERAGE],
    ]);
  }

  removeLayers(map: mapboxgl.Map) {
    map.removeLayer(this.getBelowAverageLayerId());
    map.removeLayer(this.getAverageLayerId());
    map.removeLayer(this.getAboveAverageLayerId());
  }
}
