import { getCurrencySymbol } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import * as mapboxgl from "mapbox-gl";
import { FrameLegendBy, FramePopupConfig, ImageDialogInputData, ImagePreviewComponent } from "plato-map-lib";
import { Observable } from "rxjs";
import { GeoLocationSearchCriterion } from "src/app/core/models/domain/GeoLocationSearchCriterion";
import { Region } from "src/app/core/models/domain/RegionFeaturesConfig";
import { PoiInclusionExclusion } from "src/app/core/models/domain/poi/PoiInclusionExclusion";
import { PointOfInterest } from "src/app/core/models/domain/poi/PointOfInterest";
import { ConfigurationService } from "src/app/core/svc/configuration-service";
import { FramePhotosService } from "src/app/core/svc/frame-photos.service";
import { FrameSelectionService } from "src/app/core/svc/frame-selection.service";
import { ImagePreviewService } from "src/app/core/svc/image-preview-service";
import { MessageBoxService } from "src/app/core/svc/message-box.service";
import { MapEventObserver } from "src/app/core/svc/resource-svc/map/map.event.observer";
import { FramePricingService } from "src/app/core/svc/resource-svc/pricing/frame-pricing-service";
import _ from "underscore";
import { Frame } from "../../models/domain/Frame";
import { DEFAULT_FRAME_PRICE } from "./map-view-constants";
import { MapFilter } from "./mapbox-controls/mapFilter";
import { MapBoxManager } from "./mapbox-manager";
import { MapEventTypes } from "./models/MapEventTypes";
import { MapShareComponent } from "../map-share/map-share.component";
import { LineDetails } from "plato-map-lib/lib/models/FrameDetails";
import { MapControlsConfig } from "./mapbox-controls/mapbox-controls-manager";
import { Campaign } from "../../models/domain/Campaign";

type Event = {
  [key: string]: any;
};

const environment = new ConfigurationService().getConfig();

@Component({
  selector: "app-map-view",
  templateUrl: "./map-view.component.html",
  styleUrls: ["./map-view.component.scss"],
})
export class MapViewComponent implements OnInit, OnChanges {
  @Output() mapViewShapesUpdateEvent = new EventEmitter<Event>();
  @Input() frames: Frame[];
  @Input() numberOfFramesFetched: number;
  @Input() inclusionExclusionData: PoiInclusionExclusion;
  @Input() loading: boolean;
  @Input() total: number;
  @Input() counting: boolean;
  @Input() active: boolean;
  @Input() maxMarkers;
  @Input() selectedFrames: Frame[];
  @Input() popupConfig: FramePopupConfig = {
    showSize: true,
    showPrice: false,
    showLineDetails: false,
    showPhotos: true,
    showMO: false,
    showImpacts: true,
    showFrameControls: false,
    showAudienceIndex: false,
    allowSelection: false,
  };
  @Input() geoLocationSearchCriterion: GeoLocationSearchCriterion;
  @Input() customPoiInclusionExclusionData: PoiInclusionExclusion;
  @Input() showFramePrice: boolean = false;
  @Input() showCombinedFilters: boolean = false;
  @Input() showFrameControls: boolean = false;
  @Input() mapControlsConfig: MapControlsConfig = {};
  @Input() campaignDetails: Campaign;
  @Input() mapCaptions = environment.gridConfig.mapCaptions;

  @Output() changeFrameProperties = new EventEmitter<object>();

  @ViewChild(MapShareComponent, { static: true }) mapShareComponent: MapShareComponent;

  clickedPoi?: PointOfInterest;
  dmaLabelValue: string;
  region: Region;
  showPrice: boolean;
  frameDetails: any;
  lineDetails: LineDetails;
  isSecuredInfo = false;
  noOfFramesInDma = 0;
  totalImpacts: any;
  poiBoundaryRenderingInprogress = false;
  captions = environment.gridConfig.captions;

  shareFrameIds = [];
  supportedFilters: MapFilter[] = [];
  DEFAULT_ORIENTATION_DEGREE = 0;

  allFrames: Frame[] = [];

  framesLegendColorCodeMapping: {};
  frameLegendTitle = this.captions.frame + " Legend";
  shouldShowFrameLegend: boolean;
  shouldShowColoredCircleForFrameLegend = true;

  poiFormatColorCodeMapping: {};
  poiLegendTitle = "POI Legend";
  shouldShowPOILegend: boolean;
  shouldShowColoredCircleForPOILegend = false;
  errorDialogRef: any;

  private mapCenterCoordinates: Array<number>;

  showingSelected;
  selectedFrameIds: string[] = [];
  frameIdOnPopUp: string;
  imagePreviewDialog: MatDialogRef<ImagePreviewComponent>;

  summaryClosed = true;
  dmaList: string[] = [];
  dmaCriteria = "";
  postalCodes: string[] = [];
  mapDefaultZoom: any;
  mapLegendsCallbacks: { actionCallbacks: { setFrameGroupingType(groupingTypeSelected: FrameLegendBy): void } };
  selectedFramesForSummary: any[];
  showAudienceFilter: boolean;
  audienceFilterRange;

  constructor(
    public dialog: MatDialog,
    private mapboxManager: MapBoxManager,
    private frameSelectionService: FrameSelectionService,
    private framePhotosService: FramePhotosService,
    public mapEventObserver: MapEventObserver,
    private framePricingService: FramePricingService,
    private messageBoxService: MessageBoxService,
    private imagePreviewService: ImagePreviewService
  ) {}

  setMapBoxManagerCallbacks() {
    this.mapboxManager.setCallbacks(
      (colorCodeMapping) => this.createAndDisplayFrameNameLegends(colorCodeMapping),
      (colorCodeMapping) => this.createAndDisplayPOILegends(colorCodeMapping),
      (frameDetails) => this.updatePopupData(frameDetails),
      (criteria) => this.searchWithDrawnShapes(criteria),
      () => this.resetSelectedFramesArray(),
      (supportedFilters) => this.setSupportedFilters(supportedFilters),
      () => this.toggleSummarySideBar()
    );
  }

  ngOnInit() {
    mapboxgl.config.ACCESS_TOKEN = environment.accessTokenMapBox;
    this.region = environment.region;
    this.showPrice = environment.mapConfig.showPrice;
    this.mapCenterCoordinates = environment.mapBoxLongLat;
    this.mapDefaultZoom = environment.mapDefaultZoom;
    if (environment.mapConfig.listenPOIRenderingStatus) {
      this.listenPOIRenderingStatus();
    }

    this.mapboxManager.initMap("map", this.mapCenterCoordinates, this.mapDefaultZoom, this.mapControlsConfig);
    this.setMapBoxManagerCallbacks();

    this.frameSelectionService.getSelectedFrameIds().subscribe((ids) => {
      this.selectedFrameIds = ids;
      if (this.active) {
        this.mapboxManager.applyHighlightedBorderToFrameLayers(this.selectedFrameIds);
      }
    });

    this.frameSelectionService.getShowingSelected().subscribe((state) => {
      this.showingSelected = state;
    });
    this.setMapLegendsToggleCallbacks();
  }

  setMapLegendsToggleCallbacks() {
    const that = this;
    this.mapLegendsCallbacks = {
      actionCallbacks: {
        setFrameGroupingType(groupingTypeSelected: FrameLegendBy): void {
          that.mapboxManager.applyLegendColorToFrameLayers(
            that.showingSelected ? that.selectedFrames : that.allFrames,
            that.selectedFrameIds,
            groupingTypeSelected
          );
        },
      },
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    // todo: move this part as responsibility of the service
    if (changes.geoLocationSearchCriterion && changes.geoLocationSearchCriterion.currentValue) {
      const geoLocationSearchCriterion = changes.geoLocationSearchCriterion.currentValue;
      this.mapboxManager.highlightBoundariesFor([
        ...geoLocationSearchCriterion.postalCodes,
        ...geoLocationSearchCriterion.postalSectors,
      ]);
    }

    this.mapboxManager.onMapDataRecieved(changes, this.selectedFrameIds);

    if (changes.active && changes.active.currentValue && this.selectedFrameIds.length > 0) {
      this.mapboxManager.applyHighlightedBorderToFrameLayers(this.selectedFrameIds);
    }
    if (changes.selectedFrames && changes.selectedFrames.currentValue) {
      this.populateSummaryOnSelection(changes.selectedFrames.currentValue);
      this.removeShareLinkFromMap();
    }
    if (changes.frames && changes.frames.currentValue && changes.frames.currentValue.length > 0) {
      this.allFrames = changes.frames.currentValue;
      this.postalCodes = this.getDistinctPostalCodes(this.allFrames);
      this.mapboxManager.highlightBoundariesFor(this.postalCodes);
      if (this.total !== 0 && changes.frames.currentValue.length === this.total) {
        this.mapboxManager.panMapViewToFitAllFrames();
      }
      const isAnyFrameHasAudienceIndexes = !_.isEmpty(_.filter(this.frames, (frame) => _.has(frame, "audienceIndex")));
      this.mapboxManager.showAudienceIndex(isAnyFrameHasAudienceIndexes);
      this.showAudienceFilter = isAnyFrameHasAudienceIndexes;
      this.audienceFilterRange = this.updateAudienceFilterRange();
    }
    this.setUpShareCoordinates();
  }

  private getDistinctPostalCodes(allFrames: Frame[]) {
    return [
      ...new Set([
        ...allFrames.map((frame) => frame?.geography?.postcode),
        ...allFrames.map((frame) => frame?.geography?.postcodeSector),
      ]),
    ].filter((postCode) => !this.isInvalidPostalCode(postCode));
  }

  private isInvalidPostalCode(code: string) {
    return code === null || code === "" || code === undefined;
  }

  expandFormatSummary(index: number) {
    const formatSummaryDetailsDiv = document.querySelectorAll(".format-summary-details-" + index.toString());
    const isVisible = (<HTMLElement>formatSummaryDetailsDiv.item(0)).style.display == "table-row";
    const summaryInfoImg = document.getElementById("summary-detail-expand-img-" + index.toString()) as HTMLImageElement;
    this.changeExpandIcon(isVisible, summaryInfoImg);

    formatSummaryDetailsDiv.forEach((element: HTMLElement) => {
      const isVisible = element.style.display == "table-row";
      if (!isVisible) {
        element.style.display = "table-row";
      } else {
        element.style.display = "none";
      }
    });
  }

  changeExpandIcon(isVisible: boolean, summaryInfoImg: HTMLImageElement) {
    if (!isVisible) {
      summaryInfoImg.src = "../../../../assets/images/minus-round-primary.svg";
    } else {
      summaryInfoImg.src = "../../../../assets/images/add-plus-blue.svg";
    }
  }

  expandMOSummary(index: number) {
    const moSummaryDetailsDiv = document.querySelectorAll(".mo-summary-details-" + index.toString());
    const summaryInfoImg = document.getElementById(
      "mo-summary-detail-expand-img-" + index.toString()
    ) as HTMLImageElement;
    const isVisible = (<HTMLElement>moSummaryDetailsDiv.item(0)).style.display == "table-row";
    this.changeExpandIcon(isVisible, summaryInfoImg);

    moSummaryDetailsDiv.forEach((element: HTMLElement) => {
      const isVisible = element.style.display == "table-row";
      if (!isVisible) {
        element.style.display = "table-row";
      } else {
        element.style.display = "none";
      }
    });
  }

  populateSummaryOnSelection(frames: any[]) {
    this.resetSummaryDetails();
    this.selectedFramesForSummary = frames;
  }

  listenPOIRenderingStatus() {
    this.mapEventObserver.poiRenderingStatus$.subscribe((status) => {
      this.poiBoundaryRenderingInprogress = status;
    });
  }

  openErrorDialog() {
    this.messageBoxService.showError(
      "Oops!",
      "Sorry, but your search returned too many results!",
      "Try adding more search criteria to narrow down results."
    );
  }

  toggleSummarySideBar() {
    this.summaryClosed = !this.summaryClosed;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
  }

  private updatePopupData(frameDetails?) {
    if (frameDetails) {
      if (frameDetails.properties.defaultImages) {
        frameDetails.properties.defaultImages = JSON.parse(frameDetails.properties.defaultImages);
      }
      if (frameDetails.properties.uploadedImages) {
        frameDetails.properties.uploadedImages = JSON.parse(frameDetails.properties.uploadedImages);
      }
      return this.fetchFramePrice(frameDetails);
    } else {
      this.addFrameIdToFrameDetailsForSelection();
      this.addListenersForFrameDetailsPopup();
    }
  }

  private fetchFramePrice(frameDetails) {
    this.frameDetails = frameDetails;
    this.lineDetails = JSON.parse(frameDetails.properties.lineDetails || "{}");

    if (!this.showFramePrice) {
      this.hideFrameIdForNonSpaceFrames(frameDetails);
      return new Observable((subscriber) => subscriber.next());
    }

    return new Observable((subscriber) =>
      this.framePricingService.getPriceForFrame([frameDetails.properties.frameId]).subscribe(
        (prices) => {
          const framePrice = prices.find((rule) => rule.frameId == frameDetails.properties.frameId);

          frameDetails.properties.price = framePrice
            ? getCurrencySymbol(framePrice["currency"], "wide", "en") + framePrice["price"].toLocaleString("en")
            : DEFAULT_FRAME_PRICE;

          this.frameDetails = frameDetails;
          this.hideFrameIdForNonSpaceFrames(frameDetails);
          subscriber.next();
        },
        (error) => {
          console.error("Failed to fetch price:", error);
          this.frameDetails = frameDetails;
          this.hideFrameIdForNonSpaceFrames(frameDetails);
          subscriber.next();
        }
      )
    );
  }

  private addListenersForFrameDetailsPopup() {
    this.addEventListenerForSelectFrame();
    this.addEventListenerForUploadPhotos();
    this.addEventListenerForPreviewImage();
  }

  private addEventListenerForSelectFrame() {
    const checkbox = document.getElementById("select-frame") as HTMLInputElement;
    if (this.selectedFrameIds.find((x) => x === this.frameDetails.properties.frameId)) {
      checkbox.checked = true;
    }
    // checkbox?.removeAllListeners();
    checkbox?.addEventListener("change", () => {
      this.onFrameSelection(this.frameDetails, checkbox.checked);
    });
  }

  private addEventListenerForUploadPhotos() {
    const uploadPhotos = document.getElementById("upload-photos") as HTMLInputElement;
    // uploadPhotos?.removeAllListeners();
    uploadPhotos?.addEventListener("change", (event: any) => {
      this.togglePrimaryOptions(true);
      const formData = new FormData();
      _.each(event.target.files, (file) => formData.append("files", file));
      formData.append("mediaOwnerName", this.frameDetails.properties.mediaOwnerName);
      formData.append("frameId", this.frameDetails.properties.frameId);
      this.framePhotosService.uploadPhotos(formData).subscribe(
        (fileUploadData) => this.handleFramePhotoUpdateChanges(formData),
        (error) => console.error("failed to upload photos ", error)
      );
    });
  }

  private addEventListenerForPreviewImage() {
    const previewImage = document.getElementById("preview-image") as HTMLInputElement;
    // previewImage?.removeAllListeners();
    previewImage?.addEventListener("click", () => {
      const imagePreviewInputData = this.imagePreviewService.getImageDialogInputData(
        this.frameDetails.properties,
        (data) => this.handleFramePhotoUpdateChanges(data)
      );
      this.openImagePreview(imagePreviewInputData);
    });
  }

  private openImagePreview(data: ImageDialogInputData) {
    this.imagePreviewDialog = this.dialog.open(ImagePreviewComponent, {
      width: "950px",
      data,
    });
  }

  hideFrameIdForNonSpaceFrames(frameDetails) {
    this.frameIdOnPopUp = frameDetails.properties.frameId;
    this.frameDetails.properties.frameId =
      frameDetails.properties.sourceSystem == "NON SPACE" ? undefined : frameDetails.properties.frameId;
  }

  addFrameIdToFrameDetailsForSelection() {
    this.frameDetails.properties.frameId = this.frameIdOnPopUp;
  }

  createAndDisplayFrameNameLegends(colorCodeMapping: {}) {
    this.removeFrameNameLegends();
    this.shouldShowFrameLegend = true;
    this.framesLegendColorCodeMapping = colorCodeMapping;
  }

  createAndDisplayPOILegends(POIColorCodeMapping: {}) {
    this.removePOILegends();
    this.shouldShowPOILegend = true;
    this.poiFormatColorCodeMapping = POIColorCodeMapping;
  }

  searchWithDrawnShapes(criteria: {}) {
    this.mapViewShapesUpdateEvent.emit(criteria);
  }

  setSupportedFilters(supportedFilters: MapFilter[]) {
    this.supportedFilters = [...supportedFilters];
  }

  onFrameSelection(frameDetails: any, selected: boolean) {
    if (selected) {
      this.frameSelectionService.setSelectedFrameIds(
        this.selectedFrameIds.concat(frameDetails.properties.frameId).map((a) => a.toString())
      );
    } else {
      this.frameSelectionService.setSelectedFrameIds(
        this.selectedFrameIds.filter((id) => id !== frameDetails.properties.frameId).map((a) => a.toString())
      );
    }
  }

  setUpShareCoordinates() {
    this.setUpFrameIdsToShare();
  }

  removeShareLinkFromMap() {
    this.mapShareComponent.removeShare();
  }

  setUpFrameIdsToShare() {
    this.shareFrameIds = [];
    if (this.allFrames && this.allFrames.length > 0) {
      if (this.selectedFrames && this.selectedFrames.length > 0) {
        this.selectedFrames.forEach((frame) => {
          this.shareFrameIds.push(frame.frameId);
        });
      } else {
        this.allFrames.forEach((frame) => {
          this.shareFrameIds.push(frame.frameId);
        });
      }
    }
  }

  resetSelectedFrameFilters() {
    this.mapboxManager.resetSelectedFrameFilters();
    this.mapEventObserver.setFetchAssociatedPOIData(MapEventTypes.RESET, false);
  }

  resetSelectedFramesArray() {
    this.selectedFrames = [];
    this.noOfFramesInDma = 0;
    this.selectedFramesForSummary = [];
  }

  resetSearch() {
    this.resetMapFrameSource();
    this.resetMapPOISource();
    this.resetDMABoundaries();
    this.resetCountyBoundaries();
    this.mapboxManager.resetDraw();
    this.mapboxManager.resetMapboxBoundaryFilter();
    this.resetMapShareComponent();
    this.frameSelectionService.setSelectedFrameIds([]);
    this.frameSelectionService.setShowingSelected(false);
    this.resetMapFilter();
    this.resetSummaryDetails();
  }

  resetSummaryDetails() {
    this.dmaList = [];
    this.selectedFramesForSummary = [];
    this.dmaCriteria = "";
    this.noOfFramesInDma = 0;
  }

  resetMapFilter() {
    this.supportedFilters = [];
  }

  resetMapShareComponent() {
    this.allFrames = [];
    this.mapShareComponent?.reset();
  }

  resetMapFrameSource() {
    this.removeFrameNameLegends();
    this.mapboxManager.resetMapFrameSource();
  }

  resetMapPOISource() {
    this.mapboxManager.resetMapPOISource();
    this.removePOILegends();
  }

  resetDMABoundaries() {
    this.mapboxManager.resetDMABoundaries();
  }

  resetCountyBoundaries() {
    this.mapboxManager.resetCountyBoundaries();
  }

  removeFrameNameLegends() {
    this.shouldShowFrameLegend = false;
  }

  removePOILegends() {
    this.shouldShowPOILegend = false;
  }

  onFilterAppliedEvent(allFilters) {
    if (this.selectedFrames && this.selectedFrames.length > 0) {
      this.mapboxManager.applyFilterOnSelectedFrames(this.selectedFrames, allFilters);
    } else {
      this.mapboxManager.applyFiltersOnAllFrames(allFilters);
    }
  }

  private handleFramePhotoUpdateChanges(formData: FormData) {
    const frameId = formData.get("frameId").toString();
    const mediaOwnerName = formData.get("mediaOwnerName")?.toString();
    this.frameDetails.properties.unitRationale = formData.get("unitRationale");
    this.framePhotosService.fetchAllPhotos(frameId, mediaOwnerName).subscribe(
      (allPhotos) => {
        if (allPhotos.length > 0) {
          this.changeUploadedFramePhotos(allPhotos);
          this.emitFrameChange();
          this.mapboxManager.rerenderPopup();
          this.togglePrimaryOptions(false);
          this.imagePreviewDialog?.close();
          const data = this.imagePreviewService.getImageDialogInputData(this.frameDetails.properties, (data) =>
            this.handleFramePhotoUpdateChanges(data)
          );
          this.openImagePreview(data);
        }
      },
      (error) => console.error("failed fetching newly uploaded photos....", error)
    );
  }

  private changeUploadedFramePhotos(newPhotos: any[]): void {
    const frameFeatureProperties = Object.assign({}, this.frameDetails.properties, {
      uploadedImages: this.imagePreviewService.castToPhotos(newPhotos),
    });
    this.frameDetails = Object.assign({}, this.frameDetails, {
      properties: frameFeatureProperties,
    });
  }

  private emitFrameChange() {
    this.changeFrameProperties.emit(this.frameDetails);
  }

  private togglePrimaryOptions(isDisabled: boolean) {
    const primaryOptions = document.querySelectorAll(".primary-image-option button, .upload-photos-elements");
    primaryOptions.forEach((currentOption: HTMLInputElement) => {
      currentOption.disabled = isDisabled;
      currentOption.style.color = isDisabled ? "grey" : "#3a8ab3";
      currentOption.style.cursor = isDisabled ? "progress" : "pointer";
    });
  }

  private updateAudienceFilterRange() {
    const framesWithAudienceIndex = _.filter(this.frames, (frame) => _.has(frame, "audienceIndex"));
    const audienceIndexes = _.map(framesWithAudienceIndex, (frame) => frame.audienceIndex);
    const sortedAudienceIndexes = _.sortBy(audienceIndexes);
    return {
      audienceIndexRange: {
        min: _.first(sortedAudienceIndexes),
        max: _.last(sortedAudienceIndexes),
      },
    };
  }
}
