import { Injectable } from "@angular/core";
import * as mapboxgl from "mapbox-gl";
import { BoundariesRequest } from "src/app/geoBoundaryProvider/model/BoundariesRequest";
import { MapBoundingBoxFeatureManager } from "./map-bounding-box-feature-manager";
@Injectable({
  providedIn: "root",
})
export class AssociatedPOILayersManager {
  private mapBoundingBoxFeatureManager: MapBoundingBoxFeatureManager;
  private readonly FRAME_ICON_LAYER_ID = "frameIcons";
  map: mapboxgl.Map;
  constructor(mapBoundingBoxFeatureManager: MapBoundingBoxFeatureManager) {
    this.mapBoundingBoxFeatureManager = mapBoundingBoxFeatureManager;
  }

  public setUp(map: mapboxgl.Map) {
    this.map = map;
  }

  public async getBoundaryRequest(bound, waitTimeBeforeReadFeature, noOfPOIs: number): Promise<BoundariesRequest> {
    let features;
    await this.getFeaturesFromBound(waitTimeBeforeReadFeature).then((data) => {
      features = data;
    });
    if (!features || features.length === 0) {
      console.log("AssociatedPOILayersManager:: No Features found in bounding box " + features);
      return;
    }
    console.log("AssociatedPOILayersManager:: Number of feature found in bounding box: " + features.length);
    console.log("AssociatedPOILayersManager:: Number of POI per frame to be displayed: " + noOfPOIs);
    const poiDetails = this.mapBoundingBoxFeatureManager.extractAssociatedPOIDetails(features, noOfPOIs);
    const boundariesRequest = {
      boundingBox: [bound._sw.lng, bound._sw.lat, bound._ne.lng, bound._ne.lat],
      poiDetails: poiDetails,
    };
    return boundariesRequest;
  }

  private async getFeaturesFromBound(waitTimeBeforeReadFeature): Promise<any> {
    let features;
    await this.mapBoundingBoxFeatureManager
      .getFeaturesInCurrentViewport(this.map, waitTimeBeforeReadFeature, this.FRAME_ICON_LAYER_ID)
      .then((data) => {
        features = data;
      });
    return features;
  }
}
