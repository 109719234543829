import { Component, Input } from "@angular/core";

@Component({
  selector: "app-copy-link",
  templateUrl: "./copy-link.component.html",
  styleUrls: ["./copy-link.component.scss"],
})
export class CopyLinkComponent {
  @Input() link: string;
  linkCopied: boolean = false;
  linkMessageTimeoutInMillis = 5000;

  copyLinkToClipboard() {
    const el = document.createElement("textarea");
    el.value = this.link;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.linkCopied = true;
    setTimeout(() => {
      this.linkCopied = false;
    }, this.linkMessageTimeoutInMillis);
  }
}
