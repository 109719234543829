import * as mapboxgl from "mapbox-gl";
import { MatDialog } from "@angular/material/dialog";
import { MatDialogConfig as MatDialogConfig } from "@angular/material/dialog";
import { Frame } from "src/app/core/models/domain/Frame";
import { AssociatedFramesListComponent } from "../../../../home/search/associated-frames-list/associated-frames-list.component";
import { AssociatedFrameDetails, POIDetails, POIPopupData } from "../models/POIPopupData";
import { Injectable } from "@angular/core";
import { ProhibitionPOIIdentity } from "src/app/geoBoundaryProvider/model/ProhibitionPOIIdentity";

@Injectable({
  providedIn: "root",
})
export class POIPopupDialogManager {
  private poiNamePopUp = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
    className: "poi-name-popup",
  });
  map: mapboxgl.Map;
  frames: Frame[];

  constructor(private poiAndAssociatedFramesDetailsDialog: MatDialog) {}

  public setUp(map: mapboxgl.Map) {
    this.map = map;
    this.onHoverPOI();
    this.onMouseLeaveFromPOI();
    this.onMouseClickOnPOI();
  }

  onHoverPOI() {
    this.map.on("mouseenter", "poiBoundary", (e) => {
      this.map.getCanvas().style.cursor = "pointer";
      const description: string = e.features[0].properties.desc;
      this.poiNamePopUp.setLngLat(e.lngLat).setText(description.toUpperCase()).addTo(this.map);
    });
  }

  onMouseLeaveFromPOI() {
    this.map.on("mouseleave", "poiBoundary", () => {
      this.map.getCanvas().style.cursor = "";
      this.poiNamePopUp.remove();
    });
  }

  onMouseClickOnPOI() {
    this.map.on("click", "poiBoundary", (e) => {
      this.map.getCanvas().style.cursor = "";
      this.poiNamePopUp.remove();
      const poiAndAssociatedFrames: POIPopupData = this.retrievePOIPopupData(e.features[0].properties.poiIdentity);
      this.poiAndAssociatedFramesDetailsDialog.open(
        AssociatedFramesListComponent,
        this.getDialogConfig(poiAndAssociatedFrames)
      );
    });
  }

  getDialogConfig(poiPopUpData: POIPopupData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "70%";
    dialogConfig.panelClass = "frames-dialog";
    dialogConfig.hasBackdrop = true;
    dialogConfig.data = {
      ...poiPopUpData,
    };
    return dialogConfig;
  }

  retrievePOIPopupData(poiIdentity: any): POIPopupData {
    poiIdentity = JSON.parse(poiIdentity);
    let poiDetailsToShowInPopup: POIDetails;
    const associatedFrameDetailsArray: AssociatedFrameDetails[] = this.frames
      .filter((frame) =>
        this.doesGivenPOIExistInAssociatedPOIDetails(frame.prohibition.associatedPOIDetails, poiIdentity)
      )
      .map((frame) => {
        const poiDetails = this.findPOIById(frame.prohibition.associatedPOIDetails, poiIdentity);
        poiDetailsToShowInPopup = this.getPOIDetails(poiDetails);
        const associatedFrameDetails: AssociatedFrameDetails = this.getRelevantDetailsOfFrameToShowInPopup(
          frame,
          poiDetails
        );
        return associatedFrameDetails;
      });

    return {
      poiDetails: poiDetailsToShowInPopup,
      frameDetails: associatedFrameDetailsArray,
    };
  }

  private getRelevantDetailsOfFrameToShowInPopup(frame: Frame, poiDetails: any): AssociatedFrameDetails {
    return {
      frameId: frame.frameId,
      distanceFromPOI: poiDetails.distance.value,
      prohibitionType: frame.prohibition.type,
      prohibitionStatus: frame.prohibition.status,
      address: frame.geography.address,
      lat: frame.geography.location.coordinates[1],
      long: frame.geography.location.coordinates[0],
    };
  }

  private getPOIDetails(poiDetails: any): POIDetails {
    return {
      name: poiDetails.businessName || poiDetails.name,
      classificationCode: poiDetails.classificationCode,
      categoryName: poiDetails.categoryName,
      subCategoryName: poiDetails.subCategoryName,
      address: poiDetails.address,
    };
  }

  private doesGivenPOIExistInAssociatedPOIDetails(associatedPOIDetails: any, poiIdentity: ProhibitionPOIIdentity) {
    return associatedPOIDetails.some((poi) => this.isEqualPOIIdentity(poi.poiIdentity, poiIdentity));
  }

  private findPOIById(poiDetails: any, inputPoiIdentity: ProhibitionPOIIdentity): any {
    return poiDetails.find((poi) => this.isEqualPOIIdentity(poi.poiIdentity, inputPoiIdentity));
  }

  private isEqualPOIIdentity(poiIdentity1: ProhibitionPOIIdentity, poiIdentity2: ProhibitionPOIIdentity) {
    return (
      poiIdentity1.referenceNumber === poiIdentity2.referenceNumber &&
      poiIdentity1.sourceSystem === poiIdentity2.sourceSystem
    );
  }

  public updateLatestFrames(frames: Frame[]) {
    this.frames = frames;
  }
}
