import { DataProvider } from "./data-provider/DataProvider";
import { BoundaryTransformer } from "./transformer/boundary-transformer";
import { RadiusConfig } from "./model/RadiusConfig";
import { BoundariesResponse } from "./model/BoundariesResponse";
import { BoundariesRequest } from "./model/BoundariesRequest";
import { Injectable } from "@angular/core";
import { logTimeDifference, LOG_MESSAGES } from "./logger";

@Injectable({
  providedIn: "root",
})
export class GeoBoundaryProvider {
  private radiusConfig: RadiusConfig;
  private baseUrl: string;

  constructor(private boundaryTransformer: BoundaryTransformer, private dataProvider: DataProvider) {}

  setConfigs(radiusConfig: RadiusConfig, baseUrl: string) {
    this.radiusConfig = radiusConfig;
    this.baseUrl = baseUrl;
  }

  async getGeoBoundaries(input: BoundariesRequest): Promise<BoundariesResponse> {
    const startTime = Date.now();

    const poiIds = input.poiDetails.map((poi) => poi.identity);
    const poisData = await this.dataProvider.fetchBoundaries(poiIds, this.baseUrl);
    const boundaries = this.boundaryTransformer.transform(poisData, input.poiDetails, this.radiusConfig);

    logTimeDifference(startTime, LOG_MESSAGES.TIME_TAKEN_TO_RESPOND_TO_CALLER);
    return {
      inputRequest: input,
      boundariesInfo: boundaries,
    } as BoundariesResponse;
  }
}
