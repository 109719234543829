"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var async_1 = require("../scheduler/async");
var TimeoutError_1 = require("../util/TimeoutError");
var timeoutWith_1 = require("./timeoutWith");
var throwError_1 = require("../observable/throwError");
function timeout(due, scheduler) {
    if (scheduler === void 0) { scheduler = async_1.async; }
    return timeoutWith_1.timeoutWith(due, throwError_1.throwError(new TimeoutError_1.TimeoutError()), scheduler);
}
exports.timeout = timeout;
