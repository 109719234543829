import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-days-of-week-selector",
  templateUrl: "./days-of-week-selector.component.html",
  styleUrls: ["./days-of-week-selector.component.scss"],
})
export class DaysOfWeekSelectorComponent implements OnInit, OnChanges {
  @Output() selectionChange = new EventEmitter<number[]>();
  @Input() selectedDays: number[] = [];
  @Input() disabledDays: number[] = [];

  days: string[] = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  checkedDays: boolean[] = new Array(7).fill(false);
  areAllDaysChecked: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.invalidateChecks();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedDays) {
      this.invalidateChecks();
    }
  }

  private invalidateChecks() {
    this.checkedDays = this.days.map((_, index) => this.selectedDays.includes(this.toDayOfWeek(index)));
    this.onSelectionChange(false);
  }

  toggleAll(): void {
    this.checkedDays.fill(this.areAllDaysChecked);
    this.disabledDays.forEach((day) => (this.checkedDays[this.toDayIndex(day)] = false));

    this.emitSelectionChange();
  }

  onSelectionChange(canEmit: boolean = true): void {
    this.areAllDaysChecked = this.checkedDays.every(
      (day, index) => day || this.disabledDays.includes(this.toDayOfWeek(index))
    );
    if (canEmit) {
      this.emitSelectionChange();
    }
  }

  emitSelectionChange(): void {
    const selectedIndices = this.checkedDays
      .map((selected, index) => (selected ? this.toDayOfWeek(index) : -1))
      .filter((dow) => dow !== -1);
    this.selectionChange.emit(selectedIndices);
  }

  protected toDayOfWeek(index: number) {
    return index + 1;
  }

  protected toDayIndex(dayOfWeek: number) {
    return dayOfWeek - 1;
  }
}
