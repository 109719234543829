import { Injectable } from "@angular/core";
import * as turf from "@turf/turf";
import { mapboxgl } from "mapbox-gl";
import { ProhibitionStatuses } from "../../../models/domain/prohibition/ProhibitionStatuses";
import { ProhibitionPOIData } from "../../../../geoBoundaryProvider/model/ProhibitionPOIData";
import { ProhibitionPOIIdentity } from "../../../../geoBoundaryProvider/model/ProhibitionPOIIdentity";

@Injectable({
  providedIn: "root",
})
export class MapBoundingBoxFeatureManager {
  private readonly PROHIBITION_STATUS = "prohibitionStatus";

  getFeaturesInCurrentViewport(map, time, targetLayer): Promise<any> {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    }).then(() => {
      console.log("MAP", map.queryRenderedFeatures({}));

      return map.queryRenderedFeatures({ layers: [targetLayer] });
    });
  }

  doesTwoBoundingBoxOverlaps(bound1, bound2): boolean {
    if (bound2[1] > bound1[3] || bound1[1] > bound2[3]) {
      return false;
    }
    if (bound2[2] < bound1[0] || bound1[2] < bound2[0]) {
      return false;
    }
    return true;
  }

  extractAssociatedPOIDetails(features: any, noOfMaxPOI, isJson = true): ProhibitionPOIData[] {
    const poiSet = new Set();
    const poiDetails = [];
    features
      .map((feature) => feature["properties"])
      .filter((prop) => this.verifyProhibitionStatus(prop))
      .forEach((prop) => {
        this.getAssociatedPOIs(isJson, prop)
          .map((poi) => this.getPOIDetails(poi))
          .filter((poiDetail) => !this.isDuplicateId(poiDetail.identity.referenceNumber, poiSet))
          .map((poiDetail) => {
            poiSet.add(poiDetail.identity.referenceNumber);
            return poiDetail;
          })
          .forEach((poiDetail, index) => (index < noOfMaxPOI ? poiDetails.push(poiDetail) : ""));
      });
    return poiDetails;
  }

  private getAssociatedPOIs(isJson: boolean, prop: any) {
    let associatedPOIs;
    if (isJson) {
      associatedPOIs = JSON.parse(prop["associatedPOIDetails"]);
    } else {
      associatedPOIs = prop["associatedPOIDetails"];
    }
    return associatedPOIs;
  }

  private verifyProhibitionStatus(prop): boolean {
    return (
      prop[this.PROHIBITION_STATUS] != undefined &&
      (prop[this.PROHIBITION_STATUS] === ProhibitionStatuses.REJECTED ||
        prop[this.PROHIBITION_STATUS] === ProhibitionStatuses.POTENTIALLY_REJECTED)
    );
  }

  private isDuplicateId(refNumber, poiSet): boolean {
    return poiSet.has(refNumber, poiSet);
  }

  private getPOIDetails(poi) {
    const boundaryPOI: ProhibitionPOIIdentity = {
      referenceNumber: poi["poiIdentity"]["referenceNumber"],
      sourceSystem: poi["poiIdentity"]["sourceSystem"],
    };
    return { identity: boundaryPOI, name: poi["businessName"] } as ProhibitionPOIData;
  }

  async getFramesInExpandedBBoxAsync(map: mapboxgl.Map, targetLayer: string, scaleFactor: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      resolve(this.getFramesInExpandedBBox(map, targetLayer, scaleFactor));
      reject(undefined);
    });
  }

  private getFramesInExpandedBBox(map: mapboxgl.Map, targetLayer: string, scaleFactor: number) {
    const coordinates = this.bBoxCoordinatesArray(map);
    const polygon = turf.polygon([coordinates]);
    const scaledPolygon = turf.transformScale(polygon, scaleFactor);
    const framesInLayer = map.getSource(targetLayer).serialize().data.features;
    return framesInLayer.filter((frame) =>
      turf.booleanPointInPolygon(turf.point(frame.geometry.coordinates), scaledPolygon)
    );
  }

  private bBoxCoordinatesArray(map: any) {
    const bounds = map.getBounds();
    const ne = bounds.getNorthEast().toArray();
    const nw = bounds.getNorthWest().toArray();
    const se = bounds.getSouthEast().toArray();
    const sw = bounds.getSouthWest().toArray();
    return [ne, se, sw, nw, ne];
  }
}
