import * as mapboxgl from "mapbox-gl";
import { Injectable } from "@angular/core";
import { ConfigurationService } from "src/app/core/svc/configuration-service";

const environment = new ConfigurationService().getConfig();

@Injectable({
  providedIn: "root",
})
export class MapBoxfactory {
  public create(container: string, centerCoordinates: number[], defaultZoom: number) {
    return new mapboxgl.Map({
      container,
      style: environment.mapBoxTilesUrl,
      center: centerCoordinates,
      zoom: defaultZoom,
      attributionControl: true,
      pitchWithRotate: false,
      dragRotate: false,
      keyboard: false,
    });
  }

  public createPopUp(closeButtonFlag: boolean, closeOnClickFlag: boolean, maxWidth?: string, className: string = "") {
    return new mapboxgl.Popup({
      closeButton: closeButtonFlag,
      closeOnClick: closeOnClickFlag,
      maxWidth: maxWidth ?? "240px",
      className,
    });
  }
}
