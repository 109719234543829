import { Injectable } from "@angular/core";
import { Subject, interval, Observable, observable } from "rxjs";
import { MapEventTypes } from "src/app/core/components/map-view/models/MapEventTypes";
@Injectable({ providedIn: "root" })
export class MapEventObserver {
  fetchAssociatedPOIData = new Subject<{ mapEventTypes: MapEventTypes; status: boolean }>();
  poiRenderingStatus$ = new Subject<boolean>();
  private timer;
  setFetchAssociatedPOIData(mapEventTypes: MapEventTypes, status: boolean) {
    let debounceTime = 0;
    if (mapEventTypes === MapEventTypes.DRAG) {
      debounceTime = 800;
    }
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.fetchAssociatedPOIData.next({ mapEventTypes: mapEventTypes, status: status });
    }, debounceTime);
  }

  getFetchAssociatedPOIData() {
    return this.fetchAssociatedPOIData;
  }
}
