import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { unique } from "../common/utils";

@Injectable({
  providedIn: "root",
})
export class FrameSelectionService {
  private selectedFrameIds = new Subject<string[]>();
  private showingSelected = new BehaviorSubject<boolean>(false);
  constructor() {}

  setSelectedFrameIds(selectedFrameIds: string[]) {
    this.selectedFrameIds.next(unique(selectedFrameIds));
  }

  setShowingSelected(state: boolean) {
    this.showingSelected.next(state);
  }

  getSelectedFrameIds(): Observable<string[]> {
    return this.selectedFrameIds.asObservable();
  }

  toggleShowingSelected() {
    this.showingSelected.next(!this.showingSelected.getValue());
  }

  getShowingSelected(): Observable<boolean> {
    return this.showingSelected.asObservable();
  }
}
