export const CLASSIC_FRAME_ICON_SVG_STRING =
  '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103.57 145.83"><defs><style>.cls-1{fill:#fff;}</style></defs><path d="M53,3.47A51.79,51.79,0,0,0,26.66,99.84C46.85,112.66,53,149.3,53,149.3h0s6.17-36.64,26.36-49.46A51.78,51.78,0,0,0,53,3.47Zm.06,88.42h0C33.71,91.9,18,75.52,18,55.33s15.7-36.57,35.07-36.57h0c19.37,0,35.08,16.37,35.08,36.57S72.45,91.89,53.08,91.89Z" transform="translate(-1.23 -3.47)" fill="#{color_code}"/><path class="cls-1" d="M53.08,18.75h0C33.71,18.76,18,35.13,18,55.33S33.71,91.9,53.08,91.9h0c19.37,0,35.08-16.38,35.08-36.57S72.45,18.75,53.08,18.75ZM53,66.87A11.55,11.55,0,1,1,64.56,55.32,11.54,11.54,0,0,1,53,66.87Z" transform="translate(-1.23 -3.47)" fill="#{color_code}"/><circle cx="53.02" cy="55.32" r="11.54" transform="translate(-12.53 10.19) rotate(-13.28)" fill="#{color_code}"/></svg>';

export const DIGITAL_FRAME_ICON_SVG_STRING =
  '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 145.78"><defs><style>.cls-1{fill:#fff;}</style></defs><path class="cls-1" d="M73.76,18.65h-53a6.57,6.57,0,0,0-6.65,6.49V86.36a6.57,6.57,0,0,0,6.65,6.49h53a6.57,6.57,0,0,0,6.65-6.49V25.14A6.57,6.57,0,0,0,73.76,18.65Z" transform="translate(0 -3.69)" fill="#{color_code}"/><path d="M88.5,3.69H7.5C3.36,3.69,0,6.56,0,10.09v90c0,3.53,3.36,6.4,7.5,6.4H18.92c5.1,0,9.79,2.41,12.17,6.25l0,0,17.19,36.52a.37.37,0,0,0,.68,0L65,113l0,0c2.32-4,7.09-6.52,12.31-6.52H88.5c4.14,0,7.5-2.87,7.5-6.4V10.09C96,6.56,92.64,3.69,88.5,3.69ZM80.41,86.36a6.5,6.5,0,0,1-6.51,6.49H22a6.5,6.5,0,0,1-6.51-6.49V25.14A6.5,6.5,0,0,1,22,18.65H73.9a6.5,6.5,0,0,1,6.51,6.49Z" transform="translate(0 -3.69)" fill="#{color_code}"/><path d="M64.21,52.38,35.68,36.15c-2.6-1.48-4.7-.29-4.68,2.63L31.15,71c0,2.93,2.14,4.12,4.74,2.65L64.2,57.72C66.8,56.24,66.81,53.86,64.21,52.38Z" transform="translate(0 -3.69)" fill="#{color_code}"/></svg>';

export const COLOR_CODES = [
  "#FF9D00",
  "#FF00B1",
  "#BF58FF",
  "#6638FF",
  "#0093FF",
  "#00AEB5",
  "#916400",
  "#E2B600",
  "#5A3700",
  "#B5007E",
  "#8981ED",
  "#0008C6",
  "#0070C3",
  "#00777C",
  "#FF86C7",
  "#7992C3",
  "#FF5A5A",
  "#000000",
  "#7E7E7E",
  "#C66708",
  "#36D3F7",
  "#4C840A",
  "#00358B",
  "#AC0C0C",
  "#AAA16D",
];

export const MARKER_ICON_HOLDER_DIV_STRING =
  '<div class="poi-message"><?xml version="1.0" encoding="utf-8"?>' +
  "<!-- Generator: Adobe Illustrator 23.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->" +
  '<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"' +
  '	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">' +
  '<style type="text/css">' +
  "	.st0{fill:#FFFFFF;stroke:#007097;stroke-width:12;stroke-miterlimit:10;}" +
  "</style>" +
  "<g>" +
  "	<g>" +
  '		<path class="st0" d="M255.4,450.1l56.9-121.7c1-1.5,2.7-2.4,4.5-2.4h167.9c8.6,0,15.6-7,15.6-15.6V119.6c0-8.6-7-15.6-15.6-15.6' +
  '			H26.1c-8.6,0-15.6,7-15.6,15.6v190.8c0,8.6,7,15.6,15.6,15.6H194c1.8,0,3.5,0.9,4.5,2.4L255.4,450.1"/>' +
  "	</g>" +
  "</g>" +
  "</svg>" +
  '<div><img class="poi-logo-v2" src="{{POI_URL}}" onerror="this.style.visibility=\'hidden\';this.parentNode.nextSibling.style.visibility=\'visible\';"></div>' +
  '<div style="visibility: hidden" class="poi-logo-v2-text"><p>{{POI_DISPLAY_NAME}}</p></div></div>';

export const LAYER_COLORS = {
  POI_INCLUSION_CIRCLE: "#99d7ff",
  POI_INCLUSION_CIRCLE_STROKE: "#3399FF",
  POI_EXCLUSION_CIRCLE: "#9451E9",
  POI_EXCLUSION_CIRCLE_STROKE: "#a57dfa",
  PROHIBITION_POI_COLOR: "#1577a7",
  POI_REJECTED_COLOR: "#e92113",
  POI_POTENTIALLY_REJECTED_COLOR: "#000000",
};

export const DEFAULT_FRAME_PRICE = "-";
