<div class="days-of-week-selection-root">
  <div class="days-of-week-selector">
    <div class="day-checkbox">
      <label>All</label>
      <mat-checkbox
        class="select-all-checkbox"
        color="primary"
        [(ngModel)]="areAllDaysChecked"
        (change)="toggleAll()"
      ></mat-checkbox>
    </div>
    <div class="day-checkbox" *ngFor="let day of days; let i = index">
      <label>{{ day }}</label>
      <mat-checkbox
        color="primary"
        [(ngModel)]="checkedDays[i]"
        (change)="onSelectionChange()"
        [disabled]="disabledDays.includes(toDayOfWeek(i))"
      ></mat-checkbox>
    </div>
  </div>
</div>
