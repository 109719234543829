export function logTimeDifference(start: number, message: string) {
  const timeDiffInMilliSecs = Date.now() - start;
  logMessage(message + timeDiffInMilliSecs);
}

export function logMessage(message: string) {
  console.log("GeoBoundaryProvider::" + message);
}

export function logMessageWithPrefix(prefix: string, message: string) {
  console.log(prefix + "::" + message);
}

export const LOG_MESSAGES = {
  TIME_TAKEN_TO_FETCH_FROM_CACHE: "Time taken to fetch the data from cache in milliseconds: ",
  TIME_TAKEN_TO_RESPOND_TO_CALLER: "Time taken to respond to caller in milliseconds: ",
  FRACTION_FETCHED_FROM_CACHE: "  boundaries fetched from cache",
};
