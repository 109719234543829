import * as mapboxgl from "mapbox-gl";
import * as MapboxDraw from "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw";
export class ExtendDrawBar implements mapboxgl.IControl {
  buttons;
  onAddOrig;
  onRemoveOrig;
  elContainer;
  map: mapboxgl.Map;
  draw: MapboxDraw;

  constructor(opt) {
    this.draw = opt.draw;
    this.buttons = opt.buttons || [];
    this.onAddOrig = opt.draw.onAdd;
    this.onRemoveOrig = opt.draw.onRemove;
  }
  onAdd(map) {
    this.map = map;
    this.elContainer = this.onAddOrig(map);
    this.buttons.forEach((b) => {
      this.addButton(b);
    });
    return this.elContainer;
  }
  onRemove(map) {
    this.buttons.forEach((b) => {
      this.removeButton(b);
    });
    this.onRemoveOrig(map);
  }
  addButton(opt) {
    const elButton = document.createElement("button");
    elButton.className = "mapbox-gl-draw_ctrl-draw-btn";
    elButton.title = opt.title;
    if (opt.classes instanceof Array) {
      opt.classes.forEach((c) => {
        elButton.classList.add(c);
      });
    }
    elButton.addEventListener(opt.on, opt.action);
    this.elContainer.insertBefore(elButton, this.elContainer.childNodes[this.elContainer.childNodes.length - 1]);
    opt.elButton = elButton;
  }
  removeButton(opt) {
    opt.elButton.removeEventListener(opt.on, opt.action);
    opt.elButton.remove();
  }
}
