import { Injectable } from "@angular/core";
import { ApiService } from "../../api/api.service";
import { Agency } from "../../../models/domain/Agency";
import { ResourceService } from "../resource-service.service";

@Injectable({
  providedIn: "root",
})
export class AgencyService extends ResourceService<Agency> {
  constructor(apiCall: ApiService) {
    super(apiCall, "/inventory/agencies");
  }
}
