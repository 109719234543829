import { Amendment } from "./amendments/Amendment";
import { NonMediaLineCost, NonMediaScheduleCost, ShareOfTimeData, ValueHeaderCost } from "./CampaignFrameSchedule";
import { CancellationCost } from "./CancellationCost";
import { FrameData } from "./FrameData";
import { GeographyAuditData } from "./GeographyAuditData";
import { ScheduleData } from "./ScheduleData";
import { CampaignKPIVersionPresenter } from "./CampaignKPIVersionPresenter";
import { Price } from "./price";
import { IFreeFormGeographyCriteria, IFreeFormPoiFilteringCriteria } from "./FreeFormContainer";
import { MediaInfo } from "./MediaInfo";
import { MediaSchedule } from "./MediaSchedule";
import { DistributionInfo } from "./DistributionInfo";
import { AudienceInfo } from "./AudienceInfo";
import { MediaConditions } from "./MediaConditions";
import { Note } from "./Note";
import { ProductsDialogData } from "src/app/home/campaigns/campaign-grid-v3/utils/ProductsDialogData";
import { SAC } from "../../../home/campaigns/edit-campaign/campaign-details/campaign-budget/sac";

export class CampaignLinePresenter {
  campaignId: number;
  campaignLineId: string;
  name?: string;
  channelType?: string;
  faceType?: string;
  format: string;
  environment: string;
  valueMedia? = false;
  quantity?: number;
  schedule: ScheduleData;
  shareOfTime?: ShareOfTimeData;
  condition?: string;
  geographyData?: GeographyAuditData;
  frameData?: FrameData[];
  mediaOwner: string;
  orderId?: string;
  orderLineId?: string;
  bookingStatus?: string;
  contractingStatus?: string;
  previousBookingStatus?: string;
  tradingMode: string;
  frameBuyUpdates?: Amendment;
  cancellationCost?: CancellationCost;
  campaignKPIVersion?: CampaignKPIVersionPresenter;
  cancellationPolicy?: string;
  customerReference?: string;
  placementReference?: string;
  supplierContractReference?: string;
  externalProductionCost?: Price;
  postingCharges?: Price;
  impacts?: number;
  reach?: string[];
  buyType?: string;
  rateType?: string;
  cycleType?: string;
  pubCode?: string;
  pubCodeMarket?: string;
  mediaOwnerNotes?: string;
  notesForClient?: string;
  finalSiteListInfo?: FinalSitelistInfo;
  clientApproval?: ClientApproval;
  campaignLinePricePresenter?: CampaignLinePricePresenter;
  mediaInfo?: MediaInfo;
  mediaSchedule?: MediaSchedule;
  days?: number;
  distributionInfo?: DistributionInfo;
  audienceInfo?: AudienceInfo;
  mediaCondition?: MediaConditions;
  lineNumber?: string;
  //--FFC attributes--
  inventoryType?: string;
  environmentCode?: string;
  budget?: number;
  geographyCriteria?: IFreeFormGeographyCriteria;
  poiFilteringCriteria?: IFreeFormPoiFilteringCriteria;
  tradingType?: "CPT" | "PANEL";
  cpt?: Price;
  targetAudiencePercentage?: number;
  targetAudienceImpacts?: number;
  primaryAudienceImpacts?: number;
  //--FFC attributes--
  containerId?: string;
  mediaCommission?: MediaCommission;
  exchangeMultiplier?: ExchangeMultiplier;
  buyCurrency?: string;
  noteDO?: Note[];
  isInFlight?: boolean;
  featureConfiguration?: CampaignLineFeatureConfiguration;
  isDeleted?: boolean;
  tradingActionDOS?: { tradingActions: TradingAction[] };
  moReferenceNumber?: string;
  lineDescription?: string;
  digitalLine?: boolean;
  syncDisabledToFinanceGateway?: boolean;
  printMediaDetails?: PrintMediaDetails;
  locked?: boolean;
  sourceMetadata: CampaignLineSourceMetadata;
}

export class CampaignLineSourceMetadata {
  source: CampaignLineSource;
  sourceReferences?: {
    planId?: string;
    strategyName?: string;
    originStrategyId?: string;
  };
}

export class PrintMediaDetails {
  adSize: string;
  circulation: number;
  ddsCode?: DDSCodes;
  geography: string;
  mediaOceanUpload: string;
  mediaSupplier: string;
  placement: string;
  printKeyCode: string;
  spaceDescription: string;
}

export class DDSCodes {
  print: string;
  publicationCode: string;
  publicationCodeMarket: string;
}

export class TradingAction {
  requestStatus: TradingRequestStatus;
  tradingStatus: TradingStatus;
}

export enum CampaignLineSource {
  MANUAL = "MANUAL",
  MEDIA_IMPORT = "MEDIA_IMPORT",
  OPTIMISER = "OPTIMISER",
}

export enum TradingRequestStatus {
  DRAFTED = "DRAFTED",
  COMPLETED = "COMPLETED",
}

export enum TradingStatus {
  PLANNED = "PLANNED",
  OPTIONED = "OPTIONED",
  BOOKED = "BOOKED",
  CANCELED = "CANCELED",
}

export class CampaignLineFeatureConfiguration {
  sacEnabled: boolean;
  prePaymentUpdate: boolean;
  showSACUpdateWarning: boolean;
  mediaCost: MediaCostFeatureConfiguration;
  trading: TradingFeatureConfiguration;
}

export class TradingFeatureConfiguration {
  option: boolean;
  book: boolean;
  revoke: boolean;
  cancel: boolean;
  clientContract: boolean;
}

export class MediaCostFeatureConfiguration {
  multiCurrency: boolean;
  plannedCostUpdate: boolean;
  mediaCostsUpdate: boolean;
  mediaCostsAmendment: boolean;
  showBuyCost: boolean;
  showSellCost: boolean;
}

export interface ExchangeMultiplier {
  fromCurrency?: string;
  toCurrency?: string;
  multiplier?: number;
}

export class MediaCommission {
  agencyCommission: number;
  sac: SAC;
}

export class CampaignLinePricePresenter {
  mediaOwnerPrice?: Price;
  plannedPrice?: Price;
  sellPrice?: Price;
  buyPrice?: Price;
  mediaCost?: {
    guidelinePrice?: Price;
    mediaOwnerPrice?: Price;
    plannedPrice?: Price;
    sellPrice?: Price;
    buyPrice?: Price;
    prePaymentPercentage?: number;
    discountPercentage?: number;
  };
  externalProductionCost?: NonMediaScheduleCost;
  nonMediaLineCostPresenters?: NonMediaLineCost[];
  valueHeaderCosts?: ValueHeaderCost[];
  buyMediaCommission?: MediaCommission;
}

export class CampaignLineHeaderValues {
  schedule: ScheduleData;
  shareOfTime: ShareOfTimeData;
  externalProductionCost: Price;
  postingCharges: Price;
  buyType?: string;
  rateType?: string;
  cycleType?: string;
  pubCode?: string;
  pubCodeMarket?: string;
  reach?: string;
  mediaOwnerNotes?: string;
  notesForClient?: string;
  impacts?: number;
  buyCpt?: Price;
  buyMediaCost?: Price;
  billingUnit?: string;
}

export class FinalSitelistInfo {
  id: string;
  type: string;
  status: string;
  frameIds?: string[];
  addedBy?: string;
  addedOn?: string;
}

export class ClientApproval {
  modifiedBy: string;
  modifiedOn: string;
  approvalState: string;
  modifiedOnTimeZone: string;
}

export const getLineFromFrameBuySchedules = (
  rows: any[],
  data: ProductsDialogData,
  lineHeaderValues: CampaignLineHeaderValues
) => {
  const campaignLinePresenter: CampaignLinePresenter = data.lineInfo;
  const campaignId: number = data.campaignMetaData.campaignId;
  const commonAttributes = rows[0];
  campaignLinePresenter.campaignId = campaignId;
  campaignLinePresenter.campaignLineId = commonAttributes.campaignLineId;
  campaignLinePresenter.channelType = commonAttributes.campaignScheduleType;
  campaignLinePresenter.format = commonAttributes.formatName;
  campaignLinePresenter.environment = commonAttributes.frameEnvironmentName;
  campaignLinePresenter.valueMedia = commonAttributes.valueMedia;
  campaignLinePresenter.frameData = getFrameData(rows);
  campaignLinePresenter.mediaOwner = commonAttributes.mediaOwnerName;
  campaignLinePresenter.orderId = commonAttributes.orderId;
  campaignLinePresenter.bookingStatus = commonAttributes.bookingStatus.toUpperCase();
  campaignLinePresenter.previousBookingStatus = commonAttributes.previousBookingStatus;
  campaignLinePresenter.tradingMode = commonAttributes.tradingMode;
  campaignLinePresenter.schedule = lineHeaderValues?.schedule;
  campaignLinePresenter.shareOfTime = lineHeaderValues?.shareOfTime;
  campaignLinePresenter.externalProductionCost = lineHeaderValues?.externalProductionCost;
  campaignLinePresenter.postingCharges = lineHeaderValues?.postingCharges;
  campaignLinePresenter.buyType = lineHeaderValues?.buyType;
  campaignLinePresenter.rateType = lineHeaderValues?.rateType;
  campaignLinePresenter.cycleType = lineHeaderValues?.cycleType;
  campaignLinePresenter.pubCode = lineHeaderValues?.pubCode;
  campaignLinePresenter.pubCodeMarket = lineHeaderValues?.pubCodeMarket;
  campaignLinePresenter.reach = lineHeaderValues?.reach ? [lineHeaderValues?.reach] : null;
  campaignLinePresenter.mediaOwnerNotes = lineHeaderValues?.mediaOwnerNotes;
  campaignLinePresenter.notesForClient = lineHeaderValues?.notesForClient;
  campaignLinePresenter.mediaSchedule = updateApplicableMediaScheduleData(
    data,
    lineHeaderValues,
    campaignLinePresenter
  );
  campaignLinePresenter.condition = data.lineInfo.condition?.description;
  campaignLinePresenter["billingUnit"] = lineHeaderValues?.billingUnit ? lineHeaderValues?.billingUnit : null;
  if (campaignLinePresenter["fromDate"]) delete campaignLinePresenter["fromDate"];
  if (campaignLinePresenter["toDate"]) delete campaignLinePresenter["toDate"];
  return campaignLinePresenter;
};

//lineHeaderValues: CampaignLineHeaderValues
function updateApplicableMediaScheduleData(
  data: ProductsDialogData,
  lineHeaderValues: CampaignLineHeaderValues,
  campaignLinePresenter: CampaignLinePresenter
): MediaSchedule {
  campaignLinePresenter.mediaSchedule = {
    fromDate: lineHeaderValues?.schedule?.from,
    fromTimeZone: lineHeaderValues?.schedule?.fromTimeZone,
    toDate: lineHeaderValues?.schedule?.to,
    toTimeZone: lineHeaderValues?.schedule?.toTimeZone,
    dayPartDescription: lineHeaderValues?.schedule?.daypartDescription,
    dayPartFrom: lineHeaderValues?.schedule?.daypartFrom,
    dayPartTo: lineHeaderValues?.schedule?.daypartTo,
    daysOfTheWeek: lineHeaderValues?.schedule?.daysOfTheWeek,
    shareOfTime: {
      sot: lineHeaderValues?.shareOfTime?.sot?.updatedValue,
      spot: lineHeaderValues?.shareOfTime?.spot?.updatedValue,
      loop: lineHeaderValues?.shareOfTime?.loop?.updatedValue,
    },
  };
  return campaignLinePresenter.mediaSchedule;
}

function getFrameData(rows: any[]): FrameData[] {
  const unmodifedFrames = rows.filter((row) => !row.isRemoved);
  const frameDataArr: FrameData[] = [];
  unmodifedFrames.forEach((row) => {
    const frameData: FrameData = new FrameData();
    frameData.frameId = row.frameId;
    frameData.panelName = row.panelName;
    frameData.town = row.frameTown;
    frameData.postcode = row.framePostcode;
    frameData.guidelinePrice = row.guidelinePrice.value;
    frameData.plannedPrice = row.plannedUnitPrice.value;
    frameData.sellPrice = row.sellUnitPrice.value;
    frameData.buyPrice = row.buyUnitPrice.value;
    frameData.moPrice = row.mediaOwnerPrice.value;
    frameData.availability = row.availability;
    frameData.mediaCost = {
      guidelinePrice: row.guidelinePrice,
      mediaOwnerPrice: row.mediaOwnerPrice,
      plannedPrice: row.plannedUnitPrice,
      sellPrice: row.sellUnitPrice,
      buyPrice: row.buyUnitPrice,
      discountPercentage: row.discountPercentage,
      feePercentage: row.feePercentage,
    };
    frameData.mediaCost.guidelinePrice.stringValue = getStringValueForCost(frameData.mediaCost.guidelinePrice);
    frameData.mediaCost.mediaOwnerPrice.stringValue = getStringValueForCost(frameData.mediaCost.mediaOwnerPrice);
    frameData.mediaCost.plannedPrice.stringValue = getStringValueForCost(frameData.mediaCost.plannedPrice);
    frameData.mediaCost.sellPrice.stringValue = getStringValueForCost(frameData.mediaCost.sellPrice);
    frameData.mediaCost.buyPrice.stringValue = getStringValueForCost(frameData.mediaCost.buyPrice);
    frameData.impacts = row.impacts;
    frameData.campaignAudienceImpacts = row.campaignAudienceImpacts;
    frameDataArr.push(frameData);
  });
  return frameDataArr;
}

const getStringValueForCost = (cost) => cost?.value?.toString();
