import { MapEventObserver } from "src/app/core/svc/resource-svc/map/map.event.observer";
import * as mapboxgl from "mapbox-gl";
import { Injectable } from "@angular/core";
import { LAYER_COLORS } from "../map-view-constants";

@Injectable({
  providedIn: "root",
})
export class AssociatedPOILayerDrawManager {
  private mapEventObserver: MapEventObserver;
  private readonly POI_BOUNDARY_ID = "poiBoundary";
  private readonly POI_REJECTED_BOUNDARY_ID = "poiRejectedBoundary";
  private readonly POI_POTENTIALLY_REJECTED_BOUNDARY_ID = "poiPotentiallyRejectedBoundary";

  private readonly POI_BOUNDARY_SOURCE = "poiBoundarySource";
  private readonly POI_REJECTED_BOUNDARY_SOURCE = "poiRejectedBoundarySource";
  private readonly POI_POTENTIALLY_REJECTED_BOUNDARY_SOURCE = "poiPotentiallyRejectedBoundarySource";

  private POI_ICON_LAYER_MIN_ZOOM = 0;
  map: mapboxgl.Map;

  public setUp(map: mapboxgl.Map) {
    this.map = map;
    this.setupLayerSources();
    this.createLayers();
  }

  renderAllLayers(features) {
    console.log("Drawing geoboundaries");
    const poiBoundaryPolygons = features.boundariesInfo.boundaries;
    const rejectedPolygons = features.boundariesInfo.level1Boundaries;
    const potentiallyRejected = features.boundariesInfo.level2Boundaries;
    this.setDataInLayers(poiBoundaryPolygons, rejectedPolygons, potentiallyRejected);
  }

  renderPOIBoundaryLayer(features) {
    this.setDataInPOIBoundaryLayer(features.boundariesInfo.boundaries);
  }

  setupLayerSources() {
    this.map.addSource(this.POI_BOUNDARY_SOURCE, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    this.map.addSource(this.POI_REJECTED_BOUNDARY_SOURCE, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    this.map.addSource(this.POI_POTENTIALLY_REJECTED_BOUNDARY_SOURCE, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
  }

  createLayers() {
    this.addPOIPotentiallyRejectedBoundaryLayer();
    this.addPOIRejectedBoundaryLayer();
    this.addPOIBoundaryLayer();
  }

  public addPOIBoundaryLayer() {
    this.map.addLayer({
      id: this.POI_BOUNDARY_ID,
      type: "line",
      source: this.POI_BOUNDARY_SOURCE,
      paint: {
        "line-color": LAYER_COLORS.PROHIBITION_POI_COLOR,
        "line-width": 3.5,
      },
      minzoom: this.POI_ICON_LAYER_MIN_ZOOM,
    });
  }

  public addPOIRejectedBoundaryLayer() {
    this.map.addLayer({
      id: this.POI_REJECTED_BOUNDARY_ID,
      type: "line",
      source: this.POI_REJECTED_BOUNDARY_SOURCE,
      paint: {
        "line-color": LAYER_COLORS.POI_REJECTED_COLOR,
        "line-width": 3.5,
      },
      minzoom: this.POI_ICON_LAYER_MIN_ZOOM,
    });
  }

  public addPOIPotentiallyRejectedBoundaryLayer() {
    this.map.addLayer({
      id: this.POI_POTENTIALLY_REJECTED_BOUNDARY_ID,
      type: "line",
      source: this.POI_POTENTIALLY_REJECTED_BOUNDARY_SOURCE,
      paint: {
        "line-color": LAYER_COLORS.POI_POTENTIALLY_REJECTED_COLOR,
        "line-width": 3.5,
        "line-dasharray": [3, 1.5],
      },
      minzoom: this.POI_ICON_LAYER_MIN_ZOOM,
    });
  }

  setDataInLayers(poi, rejected, potentiallyRejected) {
    this.setDataInPOIRejectedLayer(rejected);
    this.setDataInPotentiallyRejectedLayer(potentiallyRejected);
    this.setDataInPOIBoundaryLayer(poi);
  }

  private setDataInPOIBoundaryLayer(data) {
    // console.log('setDataInPOIBoundaryLayer '+ JSON.stringify(data));
    const geojson = this.updateGeoJson(data);
    this.map.getSource(this.POI_BOUNDARY_SOURCE).setData(geojson);
  }

  private setDataInPOIRejectedLayer(data) {
    //  console.log('setDataInPOIRejectedLayer '+ JSON.stringify(data));
    const geojson = this.updateGeoJson(data);
    this.map.getSource(this.POI_REJECTED_BOUNDARY_SOURCE).setData(geojson);
  }

  private setDataInPotentiallyRejectedLayer(data) {
    // console.log('setDataInPOIRejectedLayer '+ JSON.stringify(data));
    const geojson = this.updateGeoJson(data);
    this.map.getSource(this.POI_POTENTIALLY_REJECTED_BOUNDARY_SOURCE).setData(geojson);
  }

  clearDataInLayers() {
    console.log("Clearing POI geo boundary layers");
    this.clearDataInRejectionLayers();
    this.clearDataInPOIBoundaryLayer();
  }

  clearDataInRejectionLayers() {
    this.clearDataInPOIRejectedLayer();
    this.clearDataInPotentiallyRejectedLayer();
  }

  private clearDataInPOIRejectedLayer() {
    const geojson = this.getGeoJson();
    this.map.getSource(this.POI_REJECTED_BOUNDARY_SOURCE).setData(geojson);
  }

  private clearDataInPotentiallyRejectedLayer() {
    const geojson = this.getGeoJson();
    this.map.getSource(this.POI_POTENTIALLY_REJECTED_BOUNDARY_SOURCE).setData(geojson);
  }

  private clearDataInPOIBoundaryLayer() {
    const geojson = this.getGeoJson();
    this.map.getSource(this.POI_BOUNDARY_SOURCE).setData(geojson);
  }

  private getGeoJson() {
    const geojson = {
      type: "FeatureCollection",
      features: [],
    };
    return geojson;
  }

  private updateGeoJson(data) {
    const geojson = this.getGeoJson();
    if (data.type === "FeatureCollection") {
      for (let i = 0; i < data.features.length; i++) {
        geojson.features.push(data.features[i]);
      }
    } else {
      geojson.features.push(data);
    }
    return geojson;
  }
}
