import { Injectable } from "@angular/core";
import { ResourceService } from "../resource-service.service";
import { ApiService } from "../../api/api.service";
import { Client } from "../../../models/domain/Client";

@Injectable({
  providedIn: "root",
})
export class ClientService extends ResourceService<Client> {
  constructor(apiCall: ApiService) {
    super(apiCall, "/inventory/clients");
  }
}
