export class Polygon {
  points: Point[];

  constructor(points?: Point[]) {
    this.points = points;
  }
}

export class Point {
  x: number;
  y: number;

  constructor(lat: number, lng: number) {
    this.x = lat;
    this.y = lng;
  }
}

export class Box {
  point1: Point;
  point2: Point;

  constructor(point1: Point, point2: Point) {
    this.point1 = point1;
    this.point2 = point2;
  }
}

export class Circle {
  center: Point;
  radius: number;

  constructor(center?: Point, mRadius?: number) {
    this.center = center;
    this.radius = mRadius;
  }
}

export class Polyline {
  points: Point[];

  constructor(points?: Point[]) {
    this.points = points;
  }
}

export enum Shape {
  POLYGON = "polygon",
  MULTI_POLYGON = "multi_polygon",
  CIRCLE = "circle",
  RECTANGLE = "rectangle",
  POLYLINE = "polyline",
}
