export interface CampaignSearchPredicate {
  page?: string;
  size?: string;
  sort?: string;

  onlySelfCreated?: boolean;
  brandName?: string;
  clientName?: string;
  campaignName?: string;
  campaignStatus?: string;
  includeTeamCampaigns?: boolean;
  teamName?: string;
  startDate?: string;
  endDate?: string;

  search?: string;
}

export function toQueryParams(predicate: CampaignSearchPredicate): string {
  let res = "";
  if (predicate.onlySelfCreated != undefined) res += "&onlySelfCreated=" + predicate.onlySelfCreated.valueOf();
  if (predicate.brandName) res += "&brandName=" + predicate.brandName;
  if (predicate.clientName) res += "&clientName=" + predicate.clientName;
  if (predicate.campaignName) res += "&campaignName=" + predicate.campaignName;
  if (predicate.teamName) res += "&teamName=" + predicate.teamName;
  if (predicate.campaignStatus) res += "&campaignStatus=" + predicate.campaignStatus;
  if (predicate.startDate) res += "&startDate=" + predicate.startDate;
  if (predicate.search) res += "&search=" + predicate.search;
  if (predicate.endDate) res += "&endDate=" + predicate.endDate;
  if (predicate.includeTeamCampaigns != undefined)
    res += "&includeTeamCampaigns=" + predicate.includeTeamCampaigns.valueOf();
  if (predicate.page) res += "&page=" + predicate.page;
  if (predicate.size) res += "&size=" + predicate.size;
  if (predicate.sort) res += "&sort=" + predicate.sort;
  return res.length == 0 ? "" : "?" + res.substr(1, res.length - 1);
}
