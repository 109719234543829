import { BoundariesResponse } from "../../../../geoBoundaryProvider/model/BoundariesResponse";
import { ProhibitionBoundariesToggleService } from "../prohibition-boundaries-toggle/boundaries-toggle-service/prohibition-boundaries-toggle.service";
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as mapboxgl from "mapbox-gl";
import { MapEventObserver } from "src/app/core/svc/resource-svc/map/map.event.observer";
import { GeoBoundaryProvider } from "src/app/geoBoundaryProvider/geo-boundary-provider";
import { BoundariesRequest } from "src/app/geoBoundaryProvider/model/BoundariesRequest";
import { RadiusConfig } from "src/app/geoBoundaryProvider/model/RadiusConfig";
import { ConfigurationService } from "src/app/core/svc/configuration-service";
const environment = new ConfigurationService().getConfig();
import { MapEventTypes } from "../models/MapEventTypes";
import { ProhibitionPOIData } from "../../../../geoBoundaryProvider/model/ProhibitionPOIData";
import { AssociatedPOILayerDrawManager } from "./associated-poi-layer-draw-manager";
import { AssociatedPOILayersManager } from "./associated-poi-layer-manager";
import { MapBoundingBoxFeatureManager } from "./map-bounding-box-feature-manager";
import { MapEventHandler } from "./map-event-handler";
import { logMessageWithPrefix } from "src/app/geoBoundaryProvider/logger";
@Injectable({
  providedIn: "root",
})
export class AssociatedPOIRequestManager {
  private FRAME_SOURCE_ID = "frameSource";
  private readonly NEIGHBORING_SCALE_FACTOR = 4;
  private NO_OF_POI_PER_FRAME = 5;

  private map: mapboxgl.Map;
  private associatedPOILayerManager: AssociatedPOILayersManager;
  private geoBoundaryProvider: GeoBoundaryProvider;
  private drawComponent: AssociatedPOILayerDrawManager;
  private mapBoundingBoxFeatureManager: MapBoundingBoxFeatureManager;
  private mapEventHandler: MapEventHandler;
  private mapEventObserver: MapEventObserver;

  constructor(
    associatedPOILayerManager: AssociatedPOILayersManager,
    drawComponent: AssociatedPOILayerDrawManager,
    mapBoundingBoxFeatureManager: MapBoundingBoxFeatureManager,
    geoBoundaryProvider: GeoBoundaryProvider,
    mapEventObserver: MapEventObserver,
    mapEventHandler: MapEventHandler,
    private activatedRoute: ActivatedRoute,
    private prohibitionBoundariesToggleService: ProhibitionBoundariesToggleService
  ) {
    this.associatedPOILayerManager = associatedPOILayerManager;
    this.drawComponent = drawComponent;
    this.mapBoundingBoxFeatureManager = mapBoundingBoxFeatureManager;
    this.geoBoundaryProvider = geoBoundaryProvider;
    this.mapEventObserver = mapEventObserver;
    this.mapEventHandler = mapEventHandler;
  }

  public setUp(map: mapboxgl.Map) {
    this.map = map;
    this.associatedPOILayerManager.setUp(this.map);
    this.drawComponent.setUp(this.map);
    this.mapEventHandler.setUp(this.map);
    this.getPOIDetailsInBoundingBox();
    this.geoBoundaryProvider.setConfigs(
      {
        level1Radius: 0.2286,
        level2Radius: 0.308,
        unit: "kilometers",
      } as RadiusConfig,
      environment.apiUrl + "/poi-service"
    );
    this.initializeSubscriptions();
  }
  initializeSubscriptions() {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams.setNumberOfPOIs) {
        this.NO_OF_POI_PER_FRAME = queryParams.setNumberOfPOIs;
      }
    });

    this.prohibitionBoundariesToggleService.getShowRejectionBoundariesObservable().subscribe((toggle) => {
      if (toggle) {
        this.getBoundaries(0);
      } else {
        this.drawComponent.clearDataInRejectionLayers();
      }
    });
  }

  private getPOIDetailsInBoundingBox() {
    this.mapEventObserver.getFetchAssociatedPOIData().subscribe(async (updateFeature) => {
      console.log("AssociatedPOIRequestManager:: Event triggered: " + updateFeature.mapEventTypes);
      let waitTimeBeforeReadFeature = 1200;
      if (
        updateFeature.mapEventTypes === MapEventTypes.FILTER ||
        updateFeature.mapEventTypes === MapEventTypes.SHOW_SELECTED
      ) {
        this.drawComponent.clearDataInLayers();
        waitTimeBeforeReadFeature = 1500;
      }
      if (updateFeature.status) {
        await this.getBoundaries(waitTimeBeforeReadFeature);
      } else if (!updateFeature.status) {
        this.mapEventObserver.poiRenderingStatus$.next(false);
        this.drawComponent.clearDataInLayers();
        this.prohibitionBoundariesToggleService.disableBoundariesToggle();
      }
    });
  }

  private async getBoundaries(waitTimeBeforeReadFeature: number) {
    if (!this.mapEventHandler.isZoomLevelMoreThanThreshold()) {
      return;
    }
    this.mapEventObserver.poiRenderingStatus$.next(true);
    console.log("AssociatedPOIRequestManager:: Updating POI polygons in map");
    const boundariesRequest: BoundariesRequest = await this.associatedPOILayerManager.getBoundaryRequest(
      this.map.getBounds(),
      waitTimeBeforeReadFeature,
      this.NO_OF_POI_PER_FRAME
    );
    if (boundariesRequest && boundariesRequest.poiDetails) {
      console.log("AssociatedPOIRequestManager:: Number of associated POIs: " + boundariesRequest.poiDetails.length);
      this.fetchAndRenderBoundaries(boundariesRequest);
    } else {
      this.mapEventObserver.poiRenderingStatus$.next(false);
    }
  }

  private fetchAndRenderBoundaries(boundariesRequest: BoundariesRequest) {
    if (boundariesRequest.poiDetails.length > 0) {
      this.prohibitionBoundariesToggleService.enableBoundariesToggle();
      this.geoBoundaryProvider.getGeoBoundaries(boundariesRequest).then((features) => {
        if (this.shouldDrawPolygon(boundariesRequest.boundingBox)) {
          this.drawBoundaries(features);
        }
        this.mapEventObserver.poiRenderingStatus$.next(false);
        this.fetchBoundariesForNearbyPOIs(boundariesRequest.poiDetails);
      });
    } else {
      this.mapEventObserver.poiRenderingStatus$.next(false);
    }
  }

  private drawBoundaries(features: BoundariesResponse) {
    if (this.prohibitionBoundariesToggleService.shouldShowRejectionBoundaries()) {
      this.drawComponent.renderAllLayers(features);
    } else {
      this.drawComponent.renderPOIBoundaryLayer(features);
    }
  }

  private fetchBoundariesForNearbyPOIs(associatedPOIsForCurrentViewPort: ProhibitionPOIData[]) {
    this.mapBoundingBoxFeatureManager
      .getFramesInExpandedBBoxAsync(this.map, this.FRAME_SOURCE_ID, this.NEIGHBORING_SCALE_FACTOR)
      .then((framesInExpandedBBox) => {
        const framesInExpandedBBoxAssociatedPOIs = this.mapBoundingBoxFeatureManager.extractAssociatedPOIDetails(
          framesInExpandedBBox,
          this.NO_OF_POI_PER_FRAME,
          false
        );
        const neighboringAssociatedPOIs = framesInExpandedBBoxAssociatedPOIs.filter(
          (poi) => !this.doesContainElement(associatedPOIsForCurrentViewPort, poi)
        );
        if (neighboringAssociatedPOIs.length > 0) {
          logMessageWithPrefix(
            "AssociatedPOIRequestManager",
            "No of associatedPOIs from neighboring frames: " + neighboringAssociatedPOIs.length
          );
          this.geoBoundaryProvider.getGeoBoundaries({
            poiDetails: neighboringAssociatedPOIs,
          } as BoundariesRequest);
        }
      });
  }

  private doesContainElement(source: ProhibitionPOIData[], elem: ProhibitionPOIData): boolean {
    let doesContain = false;
    source.forEach((poi) => {
      if (
        poi.identity.referenceNumber === elem.identity.referenceNumber &&
        poi.identity.sourceSystem === elem.identity.sourceSystem
      ) {
        doesContain = true;
      }
    });
    return doesContain;
  }

  private shouldDrawPolygon(requestedBound): boolean {
    const bound = this.map.getBounds();
    const currentBound = [bound._sw_lng, bound._sw_lat, bound._ne_lng, bound._ne_lat];
    return this.mapBoundingBoxFeatureManager.doesTwoBoundingBoxOverlaps(requestedBound, currentBound);
  }
}
