import { CampaignScheduleAvailability } from "./CampaignScheduleAvailability";
import { ValueHeaderCost } from "./CampaignFrameSchedule";
import { Price } from "./price";

export class FrameData {
  frameId: string;
  mediaOwnerName?: string;
  mediaOwnerFrameReference?: string;
  panelName: string;
  town: string;
  postcode: string;
  tvRegion?: string;
  dimension?: string;
  impacts: number;
  campaignAudienceImpacts?: number;
  guidelinePrice?: number;
  plannedPrice?: number;
  sellPrice: number;
  buyPrice: number;
  moPrice?: number;
  mediaCost?: {
    guidelinePrice?: Price;
    mediaOwnerPrice?: Price;
    plannedPrice?: Price;
    sellPrice: Price;
    buyPrice: Price;
    discountPercentage: number;
    feePercentage: number;
  };
  availability: CampaignScheduleAvailability;
  valueHeaderCosts: ValueHeaderCost[];
  address?: string;
  formatName?: string;
  formatGroup?: string;
  environmentName?: string;
  faceType?: string;
  primaryRead?: string;
  direction?: string;
  illumination?: string;
  coordinates?: number[];
}
