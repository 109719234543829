import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";

const ALL = "All";

@Component({
  selector: "app-multi-select",
  templateUrl: "./multi-select.component.html",
  styleUrls: ["./multi-select.component.scss"],
})
export class MultiSelectComponent implements OnInit {
  @Input() label: string = "";
  @Input() items: string[] = [];
  @Output() selectionChange = new EventEmitter<string[]>();

  selectedItems: string[] = [ALL];
  previousSelectedItems: string[] = [];

  ngOnInit() {
    this.selectedItems = [ALL, ...this.items];
  }

  onSelectionChange(event: any) {
    this.selectedItems = event.value || [];
    const exceptAll = this.selectedItems.filter((item: string) => item !== ALL);

    if (exceptAll.length !== this.items.length) {
      this.selectedItems = exceptAll;
      this.selectionChange.emit(exceptAll);
    } else {
      this.selectedItems = [ALL, ...this.items];
      this.selectionChange.emit([ALL]);
    }
  }

  allClicked() {
    if (this.isAllSelected()) {
      this.deselectAll();
    } else {
      this.selectAll();
    }
  }

  deselectAll() {
    this.onSelectionChange({ value: [] });
  }

  selectAll() {
    this.onSelectionChange({ value: [ALL, ...this.items] });
  }

  isAllSelected(): boolean {
    return this.selectedItems.includes(ALL);
  }

  getTotalSelectedExceptAll(): number {
    return this.isAllSelected() ? this.selectedItems.length - 1 : this.selectedItems.length;
  }
}
