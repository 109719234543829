import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProhibitionBoundariesToggleService {
  private isBoundariesToggleOn = true;
  private showRejectionBoundaries = new Subject<boolean>();
  private showBoundariesToggle = new Subject<boolean>();

  onBoundariesToggle(checked: boolean) {
    this.isBoundariesToggleOn = checked;
    this.showRejectionBoundaries.next(checked);
  }

  shouldShowRejectionBoundaries(): boolean {
    return this.isBoundariesToggleOn;
  }

  enableBoundariesToggle() {
    this.showBoundariesToggle.next(true);
  }

  disableBoundariesToggle() {
    this.showBoundariesToggle.next(false);
  }

  getShowRejectionBoundariesObservable(): Observable<boolean> {
    return this.showRejectionBoundaries.asObservable();
  }

  getShowBoundariesToggleObservable(): Observable<boolean> {
    return this.showBoundariesToggle.asObservable();
  }
}
