import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class CommonStateService {
  private _sideBarNavigationEnabled = true;
  sideBarNavigationEnabledChange: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  set sideBarNavigationEnabled(enabled: boolean) {
    this._sideBarNavigationEnabled = enabled;
    this.sideBarNavigationEnabledChange.next(enabled);
  }
}
