import * as mapboxgl from "mapbox-gl";
import { Injectable } from "@angular/core";
import { applyFilter, hideLayers, setPopup, showLayers } from "./mapbox-utils";

@Injectable({
  providedIn: "root",
})
export class CountyBoundaryLayersManager {
  private COUNTY_BOUNDARIES_TILESET_SOURCE = "cb_2021_us_county_5m-5otr2h";
  private COUNTY_TILESET_ID = "talonodmp.0n0h65tz";
  private COUNTY_LAYER_ID = "County boundaries";
  private COUNTY_STROKE_LAYER_ID = this.COUNTY_LAYER_ID + "-stroke";
  private countryLayers = [this.COUNTY_LAYER_ID, this.COUNTY_STROKE_LAYER_ID];

  public addAllCountyBoundaries(map: mapboxgl.Map) {
    map.addSource(this.COUNTY_BOUNDARIES_TILESET_SOURCE, {
      type: "vector",
      url: "mapbox://" + this.COUNTY_TILESET_ID,
    });
    map.addLayer({
      id: this.COUNTY_LAYER_ID,
      type: "fill",
      source: this.COUNTY_BOUNDARIES_TILESET_SOURCE,
      "source-layer": this.COUNTY_BOUNDARIES_TILESET_SOURCE,
      layout: {},
      paint: {
        "fill-outline-color": "#FF30B1",
        "fill-color": "#d4d4d4",
        "fill-opacity": 0.4,
      },
    });

    map.addLayer({
      id: this.COUNTY_STROKE_LAYER_ID,
      type: "line",
      source: this.COUNTY_BOUNDARIES_TILESET_SOURCE,
      "source-layer": this.COUNTY_BOUNDARIES_TILESET_SOURCE,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#FF30B1",
        "line-width": 1,
      },
    });
    setPopup(map, this.COUNTY_LAYER_ID, (properties) => properties["NAMELSAD"], "bottom-right");
    hideLayers(map, this.countryLayers);
  }

  public toggleAllCountyBoundariesVisibility(map: mapboxgl.Map) {
    const visibility = map.getLayoutProperty(this.COUNTY_LAYER_ID, "visibility", "none");
    if (visibility === "visible") {
      hideLayers(map, this.countryLayers);
    } else {
      showLayers(map, this.countryLayers);
    }
  }

  public updateSelectedCounties(map: mapboxgl.Map, selectedCounties: Array<string>) {
    const countyNames: string[] = this.getCountyNames(selectedCounties);
    const stateCodes: string[] = this.getStateCodes(selectedCounties);
    const filter = ["all", ["in", "NAMELSAD", ...countyNames], ["in", "STUSPS", ...stateCodes]];
    if (selectedCounties.length > 0) {
      applyFilter(map, filter, this.countryLayers);
    } else {
      this.resetCountyFilters(map);
    }
  }

  getCountyNames(selectedCounties: string[]): string[] {
    return selectedCounties.filter((x) => x != undefined && x != null).map((x) => x.split(",")[0].trim());
  }

  getStateCodes(selectedCounties: string[]): string[] {
    return selectedCounties
      .filter((x) => x != undefined && x != null && x.split(",").length > 1)
      .map((x) => x.split(",")[1].trim());
  }

  public resetCountyFilters(map: mapboxgl.Map) {
    applyFilter(map, [], this.countryLayers);
  }
}
