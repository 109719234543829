import * as mapboxgl from "mapbox-gl";

export class CustomControlsBar implements mapboxgl.IControl {
  buttons;
  onAddOrig;
  onRemoveOrig;
  elContainer;
  map: mapboxgl.Map;

  constructor(opt) {
    this.buttons = opt.buttons || [];
  }
  onAdd(map) {
    this.map = map;
    this.elContainer = document.createElement("div");
    this.elContainer.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
    this.buttons.forEach((b) => {
      this.addButton(b);
    });
    return this.elContainer;
  }
  onRemove(map) {
    this.buttons.forEach((b) => {
      this.removeButton(b);
    });
    this.onRemoveOrig(map);
  }
  addButton(opt) {
    const elButton = document.createElement("button");
    elButton.className = "mapbox-gl-draw_ctrl-draw-btn";
    elButton.title = opt.title;
    if (opt.classes instanceof Array) {
      opt.classes.forEach((c) => {
        elButton.classList.add(c);
      });
    }
    elButton.addEventListener(opt.on, opt.action);
    this.elContainer.insertBefore(elButton, this.elContainer.childNodes[this.elContainer.childNodes.length - 1]);
    opt.elButton = elButton;
    elButton.id = opt.id;
    if (opt.visible === false) {
      elButton.style.display = "none";
    }
    opt.onControlAdded && opt.onControlAdded(elButton);
  }
  removeButton(opt) {
    opt.elButton.removeEventListener(opt.on, opt.action);
    opt.elButton.remove();
  }

  show(id: string) {
    const control = this.buttons.find((b) => b.id === id);
    if (control) {
      control.elButton.style.display = "block";
    }
  }

  hide(id: string) {
    const control = this.buttons.find((b) => b.id === id);
    if (control) {
      control.elButton.style.display = "none";
    }
  }
}
