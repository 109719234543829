import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { ApiService } from "../../core/svc/api/api.service";
import { Photo } from "../models/domain/Frame";
import { InventoryApiService } from "./api/InventoryApiService";

@Injectable({
  providedIn: "root",
})
export class FramePhotosService {
  constructor(private inventoryApiService: InventoryApiService) {}

  uploadPhotos(formData: FormData): Observable<any> {
    const frameId = formData.get("frameId");
    return this.inventoryApiService.post(`/frames/${frameId}/photos`, formData);
  }

  fetchAllPhotos(frameId: string, mediaOwnerName: string): Observable<any[]> {
    return this.inventoryApiService.get(`/frames/${frameId}/photoUrls?mediaOwnerName=${mediaOwnerName}`);
  }

  markPhotoAsPrimary(formData: FormData): Observable<any> {
    const frameId = formData.get("frameId");
    const mediaOwnerName = formData.get("mediaOwnerName");
    const imageName = formData.get("imageName");
    return this.inventoryApiService.patch(
      `/frames/${frameId}/photos/${imageName}/primary?mediaOwnerName=${mediaOwnerName}`,
      { isPrimaryPhoto: true } as Photo
    );
  }

  deletePhotos(formData: FormData): Observable<any> {
    const frameId = formData.get("frameId");
    const mediaOwnerName = formData.get("mediaOwnerName");
    const imageName = formData.get("imageName");
    return this.inventoryApiService.delete(
      `/frames/${frameId}/photos?fileNames=${imageName}&mediaOwnerName=${mediaOwnerName}`
    );
  }

  updateUnitRationale(frameId: string, sourceSystem: string, unitRationale: string): Observable<any> {
    return this.inventoryApiService.patch(`/frames/${frameId}/unit-rationale`, { sourceSystem, unitRationale });
  }
}
