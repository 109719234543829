import * as mapboxgl from "mapbox-gl";

export function hideLayers(map: mapboxgl.Map, layers: string[]) {
  layers.forEach((layer) => {
    map.setLayoutProperty(layer, "visibility", "none");
  });
}

export function showLayers(map: mapboxgl.Map, layers: string[]) {
  layers.forEach((layer) => {
    map.setLayoutProperty(layer, "visibility", "visible");
  });
}

export function applyFilter(map: mapboxgl.Map, filter: any[] = [], layers: string[]) {
  layers.forEach((layer) => {
    if (map.getLayer(layer)) {
      map.setFilter(layer, filter.length > 0 ? filter : null);
    }
  });
}

export function setPopup(
  map: mapboxgl.Map,
  layerId: string,
  propertyAccessor?: (properties) => string,
  anchor: string = "bottom-left",
  className: string = "custom-mapbox-popup",
  offset = 0
) {
  const popup = new mapboxgl.Popup({
    anchor,
    closeButton: false,
    closeOnClick: false,
    offset,
    className,
  });

  map.on("mousemove", layerId, (e) => {
    const description = propertyAccessor(e.features[0].properties);
    popup.trackPointer().setHTML(description).addTo(map);
  });

  map.on("mouseleave", layerId, () => {
    popup.remove();
  });
}

export function pollSource(map: mapboxgl.Map, sourceId: string, timeout: number = 30000): Promise<void> {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (map.isSourceLoaded(sourceId)) {
        console.debug("Source found: ", sourceId);
        clearInterval(interval);
        resolve();
      } else {
        console.debug("Source not found: ", sourceId);
      }
    }, 100);

    setTimeout(() => {
      clearInterval(interval);
      reject("Polling timed out for source");
    }, timeout);
  });
}
