<div class="breadcrumb-container" *ngIf="campaign">
  <ul class="list-inline pull-left">
    <li class="title-container">
      <span class="title">{{ title }}</span>
      <mat-icon>keyboard_arrow_right</mat-icon>
    </li>
    <li>
      <a [routerLink]="['/campaigns/edit/' + campaign?.id]">
        {{ campaign?.campaignName }}
      </a>
    </li>
    <li class="separator"></li>
    <li>{{ campaign?.brandName }}</li>
    <li class="separator" *ngIf="showDates"></li>
    <li *ngIf="showDates">
      {{ campaign?.startDate | platoEarliestTimezoneDatePipe }} -
      {{ campaign?.endDate | platoLatestTimezoneDatePipe }}
    </li>
    <li class="separator" *ngIf="showCampaignId"></li>
    <li *ngIf="showCampaignId">{{ campaign?.id }}</li>
  </ul>
  <ul class="list-inline pull-left" *ngIf="showBackToCampaign">
    <li class="title-container">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </li>
    <li>
      <a
        class="back-to-campaign"
        [routerLink]="['/campaigns/edit/' + campaign?.id]"
        (click)="onBackClick()"
        [queryParams]="queryParamsForBackNavFromSearchToCampaign"
      >
        Back to campaign
      </a>
    </li>
  </ul>
</div>
