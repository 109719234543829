[
  "#801441",
  "#E56825",
  "#F5EC29",
  "#A68F99",
  "#A99C34",
  "#FBCB42",
  "#FDF9C8",
  "#A96791",
  "#D58F76",
  "#936AA7",
  "#F7ADB4",
  "#BD423B",
  "#EE5F5E",
  "#B42475",
  "#FBBA51",
  "#E77A7B",
  "#BF4627",
  "#F58320",
  "#A96690",
  "#D52543",
  "#8B1727",
  "#FADB05",
  "#17474A",
  "#B54658",
  "#C52026",
  "#EEEEEE",
  "#F26E4C",
  "#9B5636",
  "#EF494E",
  "#F05F22",
  "#23252A",
  "#ACA46D",
  "#C3A84F",
  "#FBB70F",
  "#681621",
  "#9A4786",
  "#753822",
  "#EF7954",
  "#B02822",
  "#936AA8",
  "#6A1B44",
  "#E5BD1E",
  "#EC619B",
  "#E04076",
  "#62843C",
  "#223B26",
  "#F5F8E6",
  "#B36827",
  "#DBE3DB",
  "#99A4A3",
  "#535B40",
  "#614224",
  "#8B7743",
  "#33517C",
  "#A09F9C",
  "#7E5D40",
  "#3D925D",
  "#242258",
  "#775A28",
  "#E2EAE0",
  "#57968E",
  "#526D9E",
  "#6E9DBE",
  "#1B7B47",
  "#4E99A2",
  "#815740",
  "#247A87",
  "#545C5C",
  "#838786",
  "#0B3C58",
  "#48836C",
  "#C1C7BD",
  "#574838",
  "#89C1C0",
  "#3C7B3B",
  "#CBCCBC",
  "#4E402B",
  "#449667",
  "#84959D",
  "#A0752B",
  "#146157",
  "#9C9B7C",
  "#6F4E28",
  "#165590",
  "#425E4F",
  "#374849",
  "#124B6D",
  "#6A3D24",
  "#8A9598",
  "#DEE0DB",
  "#A8ADB1",
  "#885D2C",
  "#24357A",
  "#8A9182",
  "#1A2C58",
  "#AD3B4B"
]
