import { Box, Circle, Point, Polygon, Polyline, Shape } from "../../../models/domain/Shapes";
import { ExtendDrawBar } from "./extended-draw-bar";
import { MapBoxControlFormat } from "../models/mapbox-control-format";
import * as mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw";
import { DirectMode, DragCircleMode, SimpleSelectMode } from "mapbox-gl-draw-circle";
import MapboxTraffic from "@mapbox/mapbox-gl-traffic";
import { Injectable } from "@angular/core";
import { removeTitleForClassElements } from "../VanillaJavascriptBinder";
import { CustomControlsBar } from "./custom-controls-bar";
import { ConfigurationService } from "src/app/core/svc/configuration-service";
import { map } from "underscore";

const environment = new ConfigurationService().getConfig();

type ControlCallback = () => void;

@Injectable({
  providedIn: "root",
})
export class MapBoxControlsManager {
  controls: MapBoxControlFormat[];
  private searchWithDrawnShapesFunction: (
    shapes: Record<string, Array<Circle | Polygon | Polyline | Point | Box>>
  ) => void;

  togglePOICircles: ControlCallback;
  toggleAudienceIndex: ControlCallback;
  toggleSummary: ControlCallback;
  toggleDmaVisibility: ControlCallback;
  poiCircleVisible: boolean = true;
  audienceIndexVisible: boolean;
  summaryClosed = true;
  togglePOIControl: HTMLButtonElement;
  toggleAudienceIndexControl: HTMLButtonElement;
  toggleSummaryControl: HTMLButtonElement;
  toggleDmaVisibilityControl: HTMLButtonElement;
  toggleCountyVisibilityControl: HTMLButtonElement;
  toggleFramesLabelControl: HTMLButtonElement;
  togglePostalCodeVisibilityControl: HTMLButtonElement;
  toggleCountyVisibility: ControlCallback;
  togglePostalCodeVisibility: () => boolean;
  toggleFramesLabel: ControlCallback;
  dmaVisibility: boolean;
  countyVisibility: boolean;
  showFramesLabel: boolean;
  draw: MapboxDraw;
  controlsBar: CustomControlsBar;

  mapViewControlBasedOnRegion = environment.mapViewControl;

  constructor() {}

  initControls(mapControlsConfig: MapControlsConfig) {
    this.controls = [];
    this.buildControlsList(mapControlsConfig);
    this.poiCircleVisible = true;
    this.dmaVisibility = false;
    this.countyVisibility = false;
    this.showFramesLabel = false;
  }

  public buildControls(
    map: mapboxgl.Map,
    searchWithDrawnShapesFunction: ControlCallback,
    togglePOICircles: ControlCallback,
    toggleAudienceIndex: ControlCallback,
    toggleDmaVisibility: ControlCallback,
    toggleCountyVisibility: ControlCallback,
    togglePostalCodeVisibility: () => boolean,
    toggleSummary: ControlCallback,
    toggleFramesLabel: ControlCallback,
    mapControlsConfig: MapControlsConfig
  ) {
    this.initControls(mapControlsConfig);
    this.controls.forEach((e) => {
      e.position ? map.addControl(e.control, e.position) : map.addControl(e.control);
    });
    this.searchWithDrawnShapesFunction = searchWithDrawnShapesFunction;
    this.togglePOICircles = togglePOICircles;
    this.toggleAudienceIndex = toggleAudienceIndex;
    this.toggleDmaVisibility = toggleDmaVisibility;
    this.toggleCountyVisibility = toggleCountyVisibility;
    this.togglePostalCodeVisibility = togglePostalCodeVisibility;
    this.toggleSummary = toggleSummary;
    this.toggleFramesLabel = toggleFramesLabel;
    this.setUpDrawCallBacks(map);
    removeTitleForClassElements(["mapbox-gl-draw_ctrl-draw-btn", "mapboxgl-ctrl-icon"]);
  }

  public deleteAllDrawings() {
    this.draw.deleteAll();
  }

  private buildControlsList(mapControlsConfig: MapControlsConfig) {
    this.controls.push(
      new MapBoxControlFormat(
        new mapboxgl.NavigationControl({
          showCompass: false,
          showZoom: true,
        })
      )
    );
    this.controls.push(new MapBoxControlFormat(new MapboxTraffic()));
    this.controls.push(
      new MapBoxControlFormat(
        new mapboxgl.ScaleControl({
          maxWidth: 80,
          unit: "imperial",
        }),
        "bottom-left"
      )
    );
    this.controls.push(
      new MapBoxControlFormat(
        new mapboxgl.ScaleControl({
          maxWidth: 80,
          unit: "metric",
        }),
        "bottom-left"
      )
    );
    mapControlsConfig?.showCustomControls && this.addCustomControlsOnMap(mapControlsConfig);
    mapControlsConfig?.showDrawControls && this.addDrawControlOnMap();
  }

  public showAudienceIndexControl(value: boolean) {
    if (this.toggleAudienceIndexControl) {
      value
        ? this.controlsBar.show(this.toggleAudienceIndexControl.id)
        : this.controlsBar.hide(this.toggleAudienceIndexControl.id);
    }
  }

  private enableDisableAudienceIndexControl() {
    if (this.toggleAudienceIndexControl) {
      this.toggleAudienceIndexControl.className = this.getClassNameForAudienceIndexControl();
    }
  }

  private getClassNameForAudienceIndexControl() {
    return this.audienceIndexVisible ? "hide-audience-index" : "show-audience-index";
  }

  private addCustomControlsOnMap(mapControlsConfig: MapControlsConfig) {
    const controlButtons = [];
    controlButtons.push({
      id: "postalCodeControl",
      onControlAdded: (control: HTMLButtonElement) => (this.togglePostalCodeVisibilityControl = control),
      on: "click",
      action: () => this.onTogglePostalCodeVisibility(),
      classes: ["hide-postalCode-boundaries"],
    });

    mapControlsConfig?.showAudienceHeatmapControl &&
      controlButtons.push({
        id: "audienceIndexControl",
        onControlAdded: (control: HTMLButtonElement) => (this.toggleAudienceIndexControl = control),
        on: "click",
        visible: false,
        action: () => this.onToggleAudienceIndex(),
        classes: [this.getClassNameForAudienceIndexControl()],
      });

    controlButtons.push({
      id: "poiControl",
      onControlAdded: (control: HTMLButtonElement) => (this.togglePOIControl = control),
      on: "click",
      action: () => this.onTogglePOICircles(),
      classes: ["show-poi-circle"],
    });

    mapControlsConfig?.showSummaryControl &&
      controlButtons.push({
        id: "summaryControl",
        onControlAdded: (control: HTMLButtonElement) => (this.toggleSummaryControl = control),
        on: "click",
        action: () => this.onToggleSummaryPanel(),
        classes: ["summary-closed"],
      });

    const customControlsUK = new CustomControlsBar({
      buttons: controlButtons,
    });

    const customControlsUS = new CustomControlsBar({
      buttons: [
        ...customControlsUK.buttons,
        {
          id: "framesLabelControl",
          onControlAdded: (control: HTMLButtonElement) => (this.toggleDmaVisibilityControl = control),
          on: "click",
          action: () => this.onToggleDMABoundaries(),
          classes: ["hide-dma-boundaries"],
        },
        {
          id: "countyLabelControl",
          onControlAdded: (control: HTMLButtonElement) => (this.toggleCountyVisibilityControl = control),
          on: "click",
          action: () => this.onToggleCountyBoundaries(),
          classes: ["hide-county-boundaries"],
        },
        {
          id: "framesLabelControl",
          onControlAdded: (control: HTMLButtonElement) => (this.toggleFramesLabelControl = control),
          on: "click",
          action: () => {
            this.onToggleFramesLabels();
          },
          classes: ["hide-frames-label"],
        },
      ],
    });
    this.controlsBar = this.mapViewControlBasedOnRegion ? customControlsUK : customControlsUS;
    this.controls.push(new MapBoxControlFormat(this.controlsBar, "top-right"));
  }

  onToggleAudienceIndex() {
    this.audienceIndexVisible = !this.audienceIndexVisible;
    this.enableDisableAudienceIndexControl();
    this.toggleAudienceIndex();
  }

  onTogglePOICircles() {
    this.poiCircleVisible = !this.poiCircleVisible;
    if (this.togglePOIControl) {
      this.togglePOIControl.className = this.poiCircleVisible ? "show-poi-circle" : "hide-poi-circle";
    }
    this.togglePOICircles();
  }

  onToggleSummaryPanel() {
    this.summaryClosed = !this.summaryClosed;
    if (this.toggleSummaryControl) {
      this.toggleSummaryControl.className = this.summaryClosed ? "summary-closed" : "summary-open";
    }
    this.toggleSummary();
  }

  onTogglePostalCodeVisibility() {
    const isVisible = this.togglePostalCodeVisibility();
    if (this.togglePostalCodeVisibilityControl) {
      this.togglePostalCodeVisibilityControl.className = isVisible
        ? "show-postalCode-boundaries"
        : "hide-postalCode-boundaries";
    }
  }

  onToggleDMABoundaries() {
    if (this.toggleDmaVisibilityControl) {
      this.toggleDmaVisibilityControl.className = this.dmaVisibility ? "hide-dma-boundaries" : "show-dma-boundaries";
    }
    this.dmaVisibility = !this.dmaVisibility;
    this.toggleDmaVisibility();
  }

  onToggleCountyBoundaries() {
    if (this.toggleCountyVisibilityControl) {
      this.toggleCountyVisibilityControl.className = this.countyVisibility
        ? "hide-county-boundaries"
        : "show-county-boundaries";
    }
    this.countyVisibility = !this.countyVisibility;
    this.toggleCountyVisibility();
  }

  onToggleFramesLabels() {
    if (this.toggleFramesLabelControl) {
      this.toggleFramesLabelControl.className = this.showFramesLabel ? "show-frames-label" : "hide-frames-label";
    }
    this.showFramesLabel = !this.showFramesLabel;
    this.toggleFramesLabel();
  }

  private addDrawControlOnMap() {
    this.draw = new MapboxDraw({
      userProperties: true,
      modes: {
        ...MapboxDraw.modes,
        drag_circle: DragCircleMode,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode,
      },
      controls: {
        point: false,
        combine_features: false,
        uncombine_features: false,
      },
    });
    const changeCircleMode = () => this.draw.changeMode("drag_circle", { initialRadiusInKm: 2 });

    const drawBar = new ExtendDrawBar({
      draw: this.draw,
      buttons: [
        {
          on: "click",
          action: changeCircleMode,
          classes: ["mapbox-gl-draw_circle"],
          title: "Circle tool (c)",
        },
      ],
    });

    this.controls.push(new MapBoxControlFormat(drawBar, "top-right"));
  }

  private setUpDrawCallBacks(map: mapboxgl.Map) {
    map.on("draw.create", () => this.searchWithDrawnShape());
    map.on("draw.delete", () => this.searchWithDrawnShape());
    map.on("draw.update", () => this.searchWithDrawnShape());
  }

  private searchWithDrawnShape() {
    const allCircles = [];
    const allPolygons = [];
    const allPolylines = [];
    const allShapes: Record<string, Array<Circle | Polygon | Polyline | Point | Box>> = {};

    this.draw.getAll().features.forEach((feature) => {
      if (feature.geometry.type === "Polygon") {
        if (feature.properties.isCircle) {
          allCircles.push(
            new Circle(
              new Point(feature.properties.center[0], feature.properties.center[1]),
              feature.properties.radiusInKm * 1000.0 + 0.000001
            )
          );
        } else {
          allPolygons.push(
            new Polygon(feature.geometry.coordinates[0].map((coords) => new Point(coords[0], coords[1])))
          );
        }
      }
      if (feature.geometry.type === "LineString") {
        allPolylines.push(new Polyline(feature.geometry.coordinates.map((coords) => new Point(coords[0], coords[1]))));
      }
    });

    allShapes[Shape.CIRCLE] = allCircles;
    allShapes[Shape.POLYGON] = allPolygons;
    allShapes[Shape.POLYLINE] = allPolylines;
    this.searchWithDrawnShapesFunction(allShapes);
  }
}
export interface MapControlsConfig {
  showDrawControls?: boolean;
  showCustomControls?: boolean;

  showAudienceHeatmapControl?: boolean;
  showSummaryControl?: boolean;
}
