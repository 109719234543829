import { HttpClient } from "@angular/common/http";
import { ApiService } from "./api.service";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class InventoryApiService extends ApiService {
  constructor(httpClient: HttpClient) {
    super(httpClient, "/inventory");
  }
}
