import * as mapboxgl from "mapbox-gl";

export function toggleLayer(layer: string, map: mapboxgl.Map) {
  const visibility = map.getLayoutProperty(layer, "visibility");

  if (visibility !== "none") {
    map.setLayoutProperty(layer, "visibility", "none");
  } else {
    map.setLayoutProperty(layer, "visibility", "visible");
  }
}
