import { ProhibitionPOIIdentity } from "../../model/ProhibitionPOIIdentity";
import { APIService } from "./api-service";
import { Injectable } from "@angular/core";
import { POIGeoBoundaryInfoPresenter } from "../../model/POIGeoBoundaryInfoPresenter";

@Injectable({
  providedIn: "root",
})
export class BoundaryApiService {
  private boundariesEndPoint = "/poi/geoBoundaryInfo";
  constructor(private apiService: APIService) {}

  async fetchBoundaryData(ids: ProhibitionPOIIdentity[], baseUrl: string): Promise<POIGeoBoundaryInfoPresenter> {
    const poiIds = { poiIdentities: ids };
    return this.apiService.post(baseUrl + this.boundariesEndPoint, poiIds);
  }
}
