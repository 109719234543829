import { ChangeDetectorRef, Component, HostListener, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, Subject, Subscription, timer } from "rxjs";
import { mergeMap, switchMap, take, tap } from "rxjs/operators";
import { Campaign, CampaignHeaders, CampaignTypes } from "src/app/core/models/domain/Campaign";
import { CampaignFrameSchedule, TradingMode } from "src/app/core/models/domain/CampaignFrameSchedule";
import { CampaignState } from "src/app/core/models/domain/State";
import { ComponentCanDeactivate } from "src/app/core/unsaved-changes.guard";
import { DEFAULT_CURRENCY } from "../../../core/common/constants";
import { DatatableComponent } from "../../../core/components/datatable/datatable.component";
import { AuthorizationService } from "../../../core/svc/authorization.service";
import { CommonStateService } from "../../../core/svc/common-state.service";
import { CampaignsService } from "../../../core/svc/resource-svc/campaigns.service";
import { CurrencyService } from "src/app/core/svc/resource-svc/currency/currency.service";
import { CampaignBookingService } from "src/app/core/svc/resource-svc/booking/campaigns.booking.service";
import { MediaAndCostsV3Component } from "../media-and-costs-v3/media-and-costs-v3.component";
import { MediaAndCostsPlexusComponent } from "../media-and-costs-plexus/media-and-costs-plexus.component";
import { ROUTE } from "src/app/core/common/constants/const";
import { CampaignKPIVersionPresenter } from "../../../core/models/domain/CampaignKPIVersionPresenter";
import { CampaignDeliveryComponent } from "./campaign-delivery/campaign-delivery.component";
import { CampaignDocumentsComponent } from "../documents/campaign-documents.component";
import { DateService } from "src/app/core/svc/resource-svc/date/date.service";
import { PlatoTimeZoneTypes } from "src/app/core/models/domain/PlatoTimeZoneTypes";
import { CampaignDetailsComponent } from "./campaign-details/campaign-details.component";
import { Currency } from "src/app/core/models/domain/Currency";
import { features } from "../../../../configuration/us/columns";
import { ContractsComponent } from "../contracts/contracts.component";
import { UserService } from "../../../core/svc/resource-svc/user/user.service";
import { ConfigurationService } from "src/app/core/svc/configuration-service";
import { FeatureToggleService } from "../../../core/svc/resource-svc/featureToggle/featureToggle.service";
import { LockStatus } from "./campaign-lock/campaign-lock.component";
import { MessageBoxService } from "src/app/core/svc/message-box.service";

class PlanningTabState {
  isVisible: boolean = false;
  _featureEnabled: boolean = false;
  _hasBetaUserRole: boolean = false;
  _isInternationalCampaign: boolean = false;

  set featureEnabled(value: boolean) {
    this._featureEnabled = value;
    this.setVisibility();
  }

  set hasBetaUserRole(value: boolean) {
    this._hasBetaUserRole = value;
    this.setVisibility();
  }

  public changeForInternationalCampaign() {
    this._isInternationalCampaign = true;
    this.setVisibility();
  }

  private setVisibility() {
    this.isVisible = !this._isInternationalCampaign && this._featureEnabled && this._hasBetaUserRole;
  }
}

@Component({
  selector: "app-edit-campaign",
  templateUrl: "./edit-campaign.component.html",
  styleUrls: ["./edit-campaign.component.scss"],
})
export class EditCampaignComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild("stepper", { static: true }) stepper: MatStepper;
  @ViewChild("campaignDetails", { static: true })
  campaignDetailsComponent: CampaignDetailsComponent;
  @ViewChild("mediaAndCostsV3Component")
  mediaAndCostsV3Component: MediaAndCostsV3Component;
  @ViewChild("mediaAndCostsPlexusComponent")
  mediaAndCostsPlexusComponent: MediaAndCostsPlexusComponent;
  @ViewChild("campaignDeliveryComponent", { static: true })
  campaignDeliveryComponent: CampaignDeliveryComponent;
  @ViewChild("campaignDocuments", { static: true })
  campaignDocumentsComponent: CampaignDocumentsComponent;
  @ViewChild("appDatatable", { static: true }) appDatatable: DatatableComponent;
  @ViewChild("packDatatable", { static: true })
  packDatatable: DatatableComponent;
  @ViewChild("contractsList") contractsList: ContractsComponent;
  @ViewChild("notificationConfirmationTemplate", { static: true }) confirmation: TemplateRef<any>;

  mediaAndCostsV3: MediaAndCostsV3Component;
  mediaAndCostsPlexus: MediaAndCostsPlexusComponent;
  contractsListComponent: ContractsComponent;
  notesStatusForm: UntypedFormGroup;
  submitted = false;
  id: number;
  storedCampaign: Campaign;
  isPlexusCampaign = false;
  version: number;
  campaignHeaders: CampaignHeaders;
  currencyList: Currency[] = [];
  scrollTopVal = 0;
  frameRows: Array<any> = [];
  nextStates: CampaignState[] = [];
  openScheduleId: string;
  highLightSchedule: CampaignFrameSchedule;
  loading = false;
  CURRENCY = DEFAULT_CURRENCY;
  subscriptions: Subscription[] = [];
  roles: string[] = [];
  token: string;
  availabilityStatuses: CampaignFrameSchedule[] = [];
  schedulesWithTradingMode: TradingMode[] = [];
  schedulesWithLineLevelNonMediaCosts: CampaignFrameSchedule[] = [];

  environment;
  enableContracts = false;
  isRfpEnabled = false;
  planningTabState = new PlanningTabState();
  enableTradingModeAndAvails = false;

  private AVAILABILITY_POLL_INTERVAL = 15000;
  private MAXIMUM_AVAILABILITY_POLL_COUNT = 2;
  private _refresh$ = new Subject<void>();

  campaignLockStatus: LockStatus = "NONE";
  confirmationDialogRef: MatDialogRef<any>;

  constructor(
    private ref: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private campaignService: CampaignsService,
    private currencyService: CurrencyService,
    public snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private dateService: DateService,
    private authorizationService: AuthorizationService,
    private userService: UserService,
    private commonStateService: CommonStateService,
    private campaignBookingService: CampaignBookingService,
    configurationService: ConfigurationService,
    featureToggleService: FeatureToggleService,
    public messageBoxService: MessageBoxService
  ) {
    this.environment = configurationService.getConfig();
    this.enableContracts = this.environment.gridConfig.featuresEnabled[features.contracting];
    featureToggleService.isFeatureEnabled("ENABLE_RFP").subscribe((isEnabled) => {
      this.isRfpEnabled = isEnabled;
    });
    featureToggleService.isFeatureEnabled("ENABLE_OPTIMISED_PLANNING").subscribe((isEnabled) => {
      this.planningTabState.featureEnabled = isEnabled;
    });
    this.enableTradingModeAndAvails = this.environment.gridConfig.featuresEnabled[features.tradingModeAndAvails];

    this.notesStatusForm = this.formBuilder.group({
      campaignStatus: [""],
      campaignNotes: [""],
    });
  }

  ngOnInit() {
    this.commonStateService.sideBarNavigationEnabled = true;
    this.authorizationService.authorities$.subscribe((roles) => {
      this.roles = roles;
      this.planningTabState.hasBetaUserRole = this.userService.hasOptimisedPlanningBetaUserRole();
    });
    this.authorizationService.accessToken$.subscribe((val) => (this.token = val));
    this.activatedRoute.params.subscribe((params) => {
      this.id = params.id ? parseInt(params.id) : undefined;
      if (this.id) {
        this.campaignService.getCampaignHeadersById(this.id.toString()).then((campaignHeaders) => {
          this._showCampaignHeadersData(campaignHeaders);
        });

        this.campaignService
          .getById(this.id.toString())
          .toPromise()
          .then((campaign) => {
            this.markIfPlexusCampaign(campaign.type);
            this.updateComponentRefs();
            this._showCampaignData(campaign);
          });
      } else {
        this.activatedRoute.queryParams.subscribe((queryParam) => {
          this.markIfPlexusCampaign(queryParam.type);
          this.updateComponentRefs();
          this.campaignDetailsComponent.showCampaignHeadersData(null);
          this.campaignDetailsComponent.showCampaignData(null);
        });
      }
      if (this.enableTradingModeAndAvails) {
        this.subscriptions.push(this.setupTradingModeAndAvailabilityQueries());
      }
    });
    let selectedIndex: number;
    this.activatedRoute.queryParamMap.forEach((params) => {
      const selectedTab = params.get("stepper") || CampaignTabs.DETAILS;
      selectedIndex = this.getStepperIndexFor(selectedTab);
      this.stepper.selectedIndex = selectedIndex;
      this.openScheduleId = params.get("scheduleId") || undefined;
    });
    combineLatest(this._refresh$.asObservable()).subscribe(() => this.onCampaignTabsClicked({ selectedIndex }));
    this.currencyService.getAll().subscribe((val) => {
      this.currencyList = val;
    });
  }

  private markIfPlexusCampaign(campaignType: string) {
    this.isPlexusCampaign = this.checkIfPlexusCampaign(campaignType);
    if (this.isPlexusCampaign) {
      this.planningTabState.changeForInternationalCampaign();
    }
  }

  updateComponentRefs() {
    this.ref.detectChanges();
    this.mediaAndCostsV3 = this.mediaAndCostsV3Component;
    this.mediaAndCostsPlexus = this.mediaAndCostsPlexusComponent;
    this.contractsListComponent = this.contractsList;
  }

  checkIfPlexusCampaign(campaignType: string) {
    return campaignType == CampaignTypes.INTERNATIONAL;
  }

  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !Object.keys(this.campaignDetailsComponent.campaign.controls).some((controlKey) => {
      if (controlKey === "generalDetails" && this.storedCampaign) {
        const formGroup = (this.campaignDetailsComponent.campaign.controls["generalDetails"] as UntypedFormGroup)
          .controls;
        return Object.keys(formGroup).some((key) => {
          if (key === "campaignDateRange") {
            const dateRange = this.geUTCDate(this.storedCampaign);
            return !(
              formGroup[key].value.length &&
              formGroup[key].value[0].getTime() === dateRange[0].getTime() &&
              formGroup[key].value[1].getTime() === dateRange[1].getTime()
            );
          } else {
            return formGroup[key].dirty;
          }
        });
      } else {
        return this.campaignDetailsComponent.campaign.controls[controlKey].dirty;
      }
    });
  }

  scrollChanged(scrollTop: number) {
    this.scrollTopVal = scrollTop;
  }

  onCampaignTabsClicked(event: { selectedIndex: number }) {
    if (event.selectedIndex !== this.getStepperIndexFor(CampaignTabs.MEDIA_AND_COSTS)) {
      return;
    }
    this.refreshBookingInfo();
  }

  updateStoredCampaign(campaign: Campaign): void {
    if (campaign) {
      this.storedCampaign = campaign;
    }
  }

  updateAvailability(frameSchedule: CampaignFrameSchedule) {
    const scheduleRow = this.frameRows.find((row) => row.scheduleId == frameSchedule.scheduleId);
    if (scheduleRow == undefined || scheduleRow.availability == undefined) return;
    scheduleRow.availability = {
      status: "NO_DATA",
      asOn: undefined,
      ...frameSchedule.availability,
    };
    if (this.appDatatable == undefined) return;
    this.appDatatable.updateRow("scheduleId", frameSchedule.scheduleId, "availability", frameSchedule.availability);
  }

  setupTradingModeAndAvailabilityQueries() {
    let isAllAvailsFetched = false;
    const _tradingMode$ = new BehaviorSubject<any>([]);
    const tradingMode$ = _tradingMode$.asObservable();
    this.fetchTradingMode(_tradingMode$);
    return tradingMode$
      .pipe(
        mergeMap((response) => {
          if (response && response.length > 0) {
            console.log("Attempting req availability post trading response: ", response);
            const _createAvailabilityRequest$ = this.campaignService.requestAvailabilityUpdate(this.id);
            return _createAvailabilityRequest$.pipe(
              switchMap(() =>
                timer(0, this.AVAILABILITY_POLL_INTERVAL).pipe(
                  switchMap(() => {
                    if (!isAllAvailsFetched) {
                      this.availabilityStatuses = [];
                      return this.campaignService.fetchAvailability(this.id);
                    }
                    return EMPTY;
                  })
                )
              )
            );
          } else {
            console.log("Awaiting trading response!");
            return [];
          }
        })
      )
      .pipe(take(this.MAXIMUM_AVAILABILITY_POLL_COUNT))
      .subscribe(
        (schedules) => {
          this.availabilityStatuses = schedules;
          isAllAvailsFetched = schedules.every((v) => v.hasOwnProperty("availability"));
          schedules.forEach((schedule) => this.updateAvailability(schedule));
        },
        (error) => {
          console.error(error);
          this.availabilityStatuses = [];
        }
      );
  }

  public static getRouteVersionFromKPIVersionsPresenter(kpiStrategiesPresenters: any[]): CampaignKPIVersionPresenter[] {
    const campaignKPIVersions: Array<CampaignKPIVersionPresenter> = [];

    const routeKPIStrategiesPresenters = kpiStrategiesPresenters.filter(
      (KPIStrategiesPresenter) => KPIStrategiesPresenter.source === ROUTE
    );
    if (routeKPIStrategiesPresenters.length > 0) {
      const routeKPIStrategiesPresenter = routeKPIStrategiesPresenters[0];
      const kpiVersionPresenters = routeKPIStrategiesPresenter.kpiVersionPresenters;
      for (const kpiVersionPresenter of kpiVersionPresenters) {
        const campaignKPIVersion: CampaignKPIVersionPresenter = new CampaignKPIVersionPresenter();
        campaignKPIVersion.source = ROUTE;
        campaignKPIVersion.apiVersion = kpiVersionPresenter.apiVersion;
        campaignKPIVersion.apiReleaseRevision = kpiVersionPresenter.apiReleaseRevision;
        campaignKPIVersion.algorithmVersion = kpiVersionPresenter.algorithmVersion;
        campaignKPIVersion.isDefault = kpiVersionPresenter.default;
        campaignKPIVersions.push(campaignKPIVersion);
      }
    }
    return campaignKPIVersions;
  }

  fetchTradingMode(_tradingMode$: BehaviorSubject<any>) {
    if (!this.id) return;
    console.log("fetching trading mode from inventory");
    const tradingModeSubscription = this.campaignService.requestUpdatedTradingMode(this.id).subscribe(
      (tradingModeSchedules) => {
        this.schedulesWithTradingMode = tradingModeSchedules;
        _tradingMode$.next(tradingModeSchedules);
      },
      (error) => {
        console.error(error);
        this.schedulesWithTradingMode = [];
        _tradingMode$.next(this.schedulesWithTradingMode);
      }
    );
    this.subscriptions.push(tradingModeSubscription);
  }

  refreshCampaignGrid() {
    if (this.isPlexusCampaign) {
      this.mediaAndCostsPlexus?.refreshGrids();
    } else {
      this.mediaAndCostsV3?.refreshGrids();
    }
  }

  refreshBookingInfo(): void {
    if (!this.id) {
      return;
    }
    this.loading = true;
    const refreshGridSubscription = this.refreshCampaignBookingInfo().subscribe(
      () => {
        this.refreshCampaignGrid();
        this.loading = false;
      },
      () => {
        console.log("Unable to update booking status for inventory");
        this.loading = false;
      }
    );
    this.subscriptions.push(refreshGridSubscription);
  }

  refreshCampaignBookingInfo() {
    return this.campaignBookingService.refreshCampaignBookingInfo(this.id.toString()).pipe(
      tap(
        (response) => {
          if (typeof response.status !== "undefined" && response.status && response.status === "Error") {
            console.log(response.errorMsg);
          }
        },
        (error) => this.handleServerError(error)
      )
    );
  }

  refreshCampaign() {
    this.refreshCampaignGrid();
  }

  private _showCampaignHeadersData(campaignHeaders: CampaignHeaders) {
    if (campaignHeaders) {
      this.campaignHeaders = campaignHeaders;
      this.version = campaignHeaders.version;
    }
  }

  private _showCampaignData(campaign: Campaign) {
    if (campaign) {
      this.storedCampaign = campaign;
      this.campaignService.getNextTransitions(campaign.id.toString()).subscribe((data) => {
        this.nextStates = data;
        this.notesStatusForm.controls.campaignStatus.setValue(campaign.campaignStatus);
        this.notesStatusForm.controls.campaignNotes.setValue(campaign.campaignNotes);
      });
      if (this.openScheduleId) {
        this.highLightSchedule = campaign.campaignInventorySchedules
          .concat(campaign.campaignPackSchedules)
          .concat(campaign.campaignContainerSchedules)
          .concat(campaign.campaignFreeFormContainerSchedules)
          .filter((val) => val.scheduleId === this.openScheduleId)[0];
      }
      this.campaignDetailsComponent.showCampaignData(campaign);
      this.campaignDocumentsComponent.showCampaignData(campaign);
      this.campaignLockStatus = campaign.isLocked ? "LOCKED" : "UNLOCKED";
      this._refresh$.next();
    }
  }

  updateCampaign(id: number) {
    this.campaignService
      .getById(id.toString())
      .toPromise()
      .then((campaign) => {
        this._showCampaignData(campaign);
      });

    this.campaignService.getCampaignHeadersById(id.toString()).then((campaignHeaders) => {
      this._showCampaignHeadersData(campaignHeaders);
    });
  }

  private geUTCDate(campaign) {
    const startDate = this.dateService.getDateConvertedBacktoLocalFromUTC(
      campaign.startDate,
      PlatoTimeZoneTypes.EARLIEST_TIME_ZONE
    );
    const endDate = this.dateService.getDateConvertedBacktoLocalFromUTC(
      campaign.endDate,
      PlatoTimeZoneTypes.LATEST_TIME_ZONE
    );
    return [startDate, endDate];
  }

  private handleServerError(error): void {
    if (error) {
      console.log(error);
    }
  }

  refreshContractsList = (): void => {
    this.contractsListComponent.fetchAllContracts();
  };

  onFinanceSyncClicked() {
    this.openFinanceSyncConfirmationDialog();
  }

  onToggleCampaignVisibility(event) {
    const makeVisible = event.checked || false;
    this.campaignService
      .toggleCampaignVisibilityToClients(this.id)
      .then(() => {
        this.storedCampaign.isReadyForClients = makeVisible;
      })
      .catch(() => {
        this.messageBoxService.showError("Campaign Update Error", "Could not update the campaign visibility.");
        this.storedCampaign.isReadyForClients = !makeVisible;
      });
  }

  onConfirmFinanceSync() {
    this.campaignService.syncCampaignToFinance(this.id.toString()).subscribe(
      () =>
        this.messageBoxService.showSuccessMessage(
          "Successful Sync to Finance Gateway",
          "Successful Sync to NetSuite, please wait 15 minutes before checking in NetSuite."
        ),
      (err) => {
        const actualErrorMessage = err.error.subErrors[0].message;
        const defaultErrorMessage = "Failed to sync to NetSuite please inform Platform Support";
        const errorMsg = actualErrorMessage ? actualErrorMessage : defaultErrorMessage;
        this.messageBoxService.showError("Failed to sync to NetSuite", errorMsg);
      }
    );
  }

  allowFinanceSync() {
    return this.userService.hasFinanceRole();
  }

  showDashboardControl() {
    return this.environment.enableClientDashboardControls;
  }

  private openFinanceSyncConfirmationDialog() {
    this.messageBoxService.showConfirmationMessage(
      "Are you sure you want to sync campaign with Netsuite?",
      (confirm) => {
        if (confirm) {
          this.onConfirmFinanceSync();
        }
      }
    );
  }

  showRFPTab() {
    return this.environment?.campaignConfigs?.showRFPTab;
  }

  getStepperIndexFor(tab: string): number {
    let rfpOffset = 0;
    let planningOffset = 0;
    if (this.isRfpEnabled && this.showRFPTab) {
      rfpOffset = 1;
    }
    if (this.planningTabState.isVisible) {
      planningOffset = 1;
    }

    switch (tab) {
      case CampaignTabs.DETAILS:
        return 0;
      case CampaignTabs.PLANNING:
        return planningOffset;
      case CampaignTabs.RFP:
        return planningOffset + rfpOffset;
      case CampaignTabs.MEDIA_AND_COSTS:
        return planningOffset + rfpOffset + 1;
      case CampaignTabs.CONTRACTS:
        return planningOffset + rfpOffset + 2;
      case CampaignTabs.DELIVERY:
        return planningOffset + rfpOffset + 3;
      default:
        return 0;
    }
  }

  onNotifyClick() {
    this.confirmationDialogRef = this.dialog.open(this.confirmation, {
      width: "500px",
    });
  }

  notifyForReview() {
    this.campaignService.notifyForReview(this.id).subscribe(
      () => {
        this.messageBoxService.showSuccessMessage("Success", "Notified Investment");
      },
      () => {
        this.messageBoxService.showError("Notify Investment", "Failed to notify investment.");
      }
    );
  }
}

export const CampaignTabs = {
  DETAILS: "DETAILS",
  PLANNING: "PLANNING",
  RFP: "RFP",
  MEDIA_AND_COSTS: "MEDIA_AND_COSTS",
  CONTRACTS: "CONTRACTS",
  DELIVERY: "DELIVERY",
};
